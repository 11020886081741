<template>
    <v-container class="w-container-users v-overflow editCespiti"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">





                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e; display: flex;">


                    <div>
                        {{ titolo }}
                    </div>

                    <div style="margin-left: 20px">
                        {{ nome_risorsa }}
                    </div>



                </div>

            </v-col>

        </v-row>


        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>



            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item id="itemIdAssetType"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-8px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipo Cespiti </ion-label>

                            <ion-select id="idassettype" name="idassettype" class='idassettype' cancel-Text="Chiudi"
                                done-Text="" :value="cespiti.idasset_type" interface="action-sheet" disabled>

                                <ion-select-option v-for="item in tipo_cespiti" :key="item.idassettype"
                                    :value='item.idassettype'> {{ item.Description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.description" id="description" label="Descrizione" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.serial_number" id="n_matricola" label="Serial Number" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.op_system" id="op_system" label="Sistema Operativo" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-textarea v-model="cespiti.software" id="software" label="Software">
                    </v-textarea>

                </v-col>


            </v-row>



            <v-row>

                <v-col cols="12" md="12" xs="12">


                    <v-text-field v-model="cespiti.notebook_memoria" id="notebook_memoria" label="Memoria"
                        maxlength="50">
                    </v-text-field>


                </v-col>


            </v-row>

            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="cespiti.notebook_disco" id="notebook_disco" label="Disco" maxlength="50">
                    </v-text-field>

                </v-col>


            </v-row>



        </v-form>

       

      
        <div class="contpopupsignatureviewfile" style="display:none">

            <template>
                <modal name="popupSignatureViewFile" :clickToClose="false" :width="600" :height="322">

                    <v-row style="margin-bottom: 0px;">

                        <v-col cols="12" md="12" class="titleSignature">
                            FIRMA PER ACCETTAZIONE
                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px;">

                        <v-col cols="12" md="12" style="text-align: center;">

                            <canvas id="firmaCanvas" width="550" height="200"></canvas>

                        </v-col>

                    </v-row>



                    <v-row style="padding-top: 0px!important;">

                        <v-col cols="12" md="12" style="padding-top: 0px!important;">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-delete-search" contain src="@/assets/icon_delete_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetSignatureViewFile" />


                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSignatureViewFile" />





                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupviewfilepdf" style="display:none">

            <template>
                <modal name="popupViewFilePdf" id="pViewFilePdf" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Vedi Allegato

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
justify-items: center;
justify-content: center;
align-items: center;">

                            <div style="width: 94%;height: 50vh;overflow-y: auto;overflow-x: auto;">

                                <div id="container-view-pdf"></div>

                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelViewFilePdf" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apicespiti from "../utils/cespiti/apicespiti";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export default ({
    mounted() {


        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;


        this.setupButtons();

        $(window).resize(function () {


            pointerVue.centerPopupViewFilePdf();


        });


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Cespiti";

            setTimeout(() => {


                this.syncAsset();


            }, 100);

        }


        this.loadLogo();


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;



                default:
                    break;
            }
        });



    },


    data: () => ({

        nome_risorsa: "",

        pdfUrl: "",

        visMemNB: false,
        visDiscoNB: false,

        risNomeSede: "",

        attachRemove: false,

        sheetRisorse: false,

        fidoAnt: null,
        fidoCassa: null,

        visDescrizione: false,
        visNMatricola: false,
        visOP: false,
        visSw: false,

        visNSim: false,
        visTelNum: false,

        visTarga: false,
        visVeicolo: false,

        visTipoDisco: false,

        visPollici: false,



        sheetGruppi: false,

        searchGruppi: "",

        tipo_cespiti: [],

        risorse: [],

        banca: {},

        cespiti: {
            idasset_type: "",
            description: "",
            serial_number: "",
            op_system: "",
            software: "",
            notebook_memoria: "",
            notebook_disco: ""
        },

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ],

        tipi_dischi: [{
            "id": "RPM",
            "value": "RPM"
        },
        {
            "id": "SSD",
            "value": "SSD"
        }
        ]

    }),

    methods: {


        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew2(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompanyNew2: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;




                    });
            } catch (error) {
                console.log("err LoadLogoCompanyNew", error);
            }

            console.log(response);



        },



        btnResetSignatureViewFile: function () {

            window.signaturePad.clear();

        },

        saveSignatureViewFile: async function () {

            var that = this;

            that.$modal.hide('popupSignatureViewFile');

            var v_token = window.$cookies.get("token");

            var signature = window.signaturePad.toDataURL();

            console.log("SIGNATURE: ", signature);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.saveSignatureCespiti(
                that.$route.params.id,
                signature,
                v_token
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;

                console.log("res from saveSignatureCespiti", res);

                setTimeout(() => {

                    that.viewAllegato();

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        btnConfirmSignatureViewFile: function () {

            var errore = "";

            if (window.signaturePad.isEmpty()) {
                errore = "Firma richiesta";
            }

            if (errore == "") {

                console.log("ok");

                this.saveSignatureViewFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        viewAllegato: async function () {

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.downAttach(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downAttach", res);

                setTimeout(() => {


                    $(".contpopupviewfilepdf").show();

                    that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = res.data.UrlVal;

                    setTimeout(() => {


                        that.loadPdf();

                        that.centerPopupViewFilePdf();


                    }, 200);



                }, 100);







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },


        centerPopupViewFilePdf: function () {



            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },


        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },

        btnCancelViewFilePdf: function () {

            var that = this;

            that.$modal.hide('popupViewFilePdf');

            setTimeout(() => {

                that.dialogMsg = false;
                that.setupButtons();

                router.push({
                    path: "/cespiti"
                });

            }, 200);

        },




        manageClickRisorse: async function (risorsa) {

            console.log("RIS SEL: ", risorsa);

            this.sheetRisorse = false;

            this.cespiti.idresource = risorsa.resourceid;

            this.syncOfficeRes(risorsa.resourceid);

        },




        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.banca.companyid = azienda.companyid;

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30,

                },
                    // {
                    //     text: "Indietro",
                    //     icon: "mdi-arrow-left",
                    //     image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    //     link: "/tipologiapagamento",
                    //     id: "btn_back",
                    //     disabled: false,
                    //     title: "Indietro",
                    //     width: 35

                    // },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("CESPITI: ", that.cespiti);
            

            var errore = "";

            if (that.cespiti.idasset_type == "") {
                errore = "Specificare il tipo";
            }

            if (errore == "") {
                if (that.cespiti.description == "") {
                    errore = "Specificare la descrizione";
                }
            }

            if (errore == "") {
                if (that.cespiti.description == null) {
                    errore = "Specificare la descrizione";
                }
            }

            if (errore == "") {
                if (that.cespiti.serial_number == "") {
                    errore = "Specificare il serial number";
                }
            }

            if (errore == "") {
                if (that.cespiti.serial_number == null) {
                    errore = "Specificare il serial number";
                }
            }

            if (errore == "") {
                if (that.cespiti.op_system == "") {
                    errore = "Specificare il sistema operativo";
                }
            }

            if (errore == "") {
                if (that.cespiti.op_system == null) {
                    errore = "Specificare il sistema operativo";
                }
            }

            if (errore == "") {
                if (that.cespiti.notebook_memoria == "") {
                    errore = "Specificare la memoria";
                }
            }

            if (errore == "") {
                if (that.cespiti.notebook_memoria == null) {
                    errore = "Specificare la memoria";
                }
            }

            if (errore == "") {
                if (that.cespiti.notebook_disco == "") {
                    errore = "Specificare il disco";
                }
            }

            if (errore == "") {
                if (that.cespiti.notebook_disco == null) {
                    errore = "Specificare il disco";
                }
            }

            if (errore == "") {



                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },



        saveData: async function () {



            var that = this;

            that.cespiti.idasset_type = $("#idassettype").val();

            console.log("CESPITI: ", that.cespiti);

            var v_token = window.$cookies.get("token");

            var response = null;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apicespiti.updateCespiti(
                that.cespiti,
                v_token,
                null,
                false,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateCespiti", res);

                if (res.data.Result == "OK") {


                    setTimeout(() => {

                        $(".contpopupsignatureviewfile").show();

                        that.$modal.show('popupSignatureViewFile');

                        setTimeout(() => {

                            const canvas = document.querySelector("canvas");

                            window.signaturePad = new SignaturePad(canvas);

                        }, 200);

                    }, 200);





                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },


        saveDataOld: async function () {



            var that = this;

            that.cespiti.idasset_type = $("#idassettype").val();

            console.log("CESPITI: ", that.cespiti);

            var v_token = window.$cookies.get("token");

            var response = null;

            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicespiti.insertCespiti(
                    that.cespiti,
                    v_token,
                    null
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertCespiti", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/cespiti"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {



                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apicespiti.updateCespiti(
                    that.cespiti,
                    v_token,
                    null,
                    false,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateCespiti", res);

                    if (res.data.Result == "OK") {


                        if (res.data.HasFirma == 0) {

                            setTimeout(() => {

                                $(".contpopupsignatureviewfile").show();

                                that.$modal.show('popupSignatureViewFile');

                                setTimeout(() => {

                                    const canvas = document.querySelector("canvas");

                                    window.signaturePad = new SignaturePad(canvas);

                                }, 200);

                            }, 200);




                        }

                        if (res.data.HasFirma == 1) {

                            that.$swal({
                                icon: "success",
                                text: "Dati salvati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                router.push({
                                    path: "/cespiti"
                                });

                            }, 200);

                        }





                    }
                    else {


                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }








        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncInitInsert: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;



                // this.aziende = res.data.aziende;

                // this.gruppi = res.data.invoice_mail_group;

                // this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncOfficeRes: async function (id_resource) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.getOfficeRes(
                v_token,
                id_resource
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getOfficeRes", res);

                this.risNomeSede = res.data.DescOffice;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncAsset: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var id_cmp_sel = window.$cookies.get("cid");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initEdit(
                that.$route.params.id,
                v_token,
                id_cmp_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEdit", res);

                this.nome_risorsa = "Risorsa: " + res.data.fullname;

                this.tipo_cespiti = res.data.asset_type;

                this.cespiti = res.data.asset;

                this.cespiti.idasset_type = res.data.asset.idassettype;

                $("#itemIdAssetType").addClass("item-has-value");


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
#firmaCanvas {
    border: 1px solid black;
}


.titleSignature {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0px;
}

.subtitleSignature {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    margin-top: 0px;
}



canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemRisorsa {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetRisorse {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-users {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editCespiti {
    overflow-x: hidden;
}

.editCespiti .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editCespiti .v-input {
    font-size: 1.2em !important;
}

.editCespiti .v-select {
    font-size: 1.2em !important;
}

.editCespiti .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
