var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-container-days-pwd",staticStyle:{"padding-top":"59px"},attrs:{"id":"conf_days_change_pwd"}},[_c('v-row',[_c('v-col',{staticClass:"mainCont",staticStyle:{"min-width":"100vw","position":"fixed","left":"0","padding-left":"0px"}},[_c('div',{staticClass:"mainTitle",staticStyle:{"background-color":"white","color":"black","font-weight":"bold","padding":"4px","font-size":"10px","min-width":"100vw","border-color":"#f5cf2e"}},[_vm._v(_vm._s(_vm.titolo))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted"},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}},[_vm._v("Giorni")]),_c('vue-autonumeric',{attrs:{"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0',
                                    modifyValueOnWheel: false,
                                }},model:{value:(_vm.emt_prod_settings.pwd_num_days_for_change),callback:function ($$v) {_vm.$set(_vm.emt_prod_settings, "pwd_num_days_for_change", $$v)},expression:"emt_prod_settings.pwd_num_days_for_change"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }