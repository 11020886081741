<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_products_configuration">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start'
                                                    style="width:10px!important;height:10px!important">
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Prodotto</th>
                                        <th class='all'>Utente / Gruppo</th>
                                        <th class='all'>Tipo</th>
                                        <th class='all' style="text-align:center">Home</th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="6">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetChangeStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetChangeStatus = !sheetChangeStatus">

                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                            max-width="24" max-height="24" style="margin-right:13px" transition="scale-transition" />
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.title" @click="changeStatusAdmin(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetStatus">
                <v-sheet class="text-center" height="455px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">

                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                            max-width="24" max-height="24" style="margin-right:13px" transition="scale-transition" />
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatusFilter" :key="tile.title" @click="changeStatus(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                                        max-width="24" max-height="24" style="margin-right:13px"
                                        transition="scale-transition" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-icon aria-hidden="false">mdi-domain</v-icon>

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" label="Da" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataA" v-model="dataA" label="A" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/icon_delete_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnResetSearch" />


                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopuppermissions" style="display:none">

            <template>
                <modal name="popupPermissions" :clickToClose="false" :width="362" :height="344">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Abilitazione Permessi

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-list style="overflow-y:auto;max-height: 233px;">

                                <v-list-tile v-for="el in permessi" :key="el.id">

                                    <div v-if="el.val_section.length > 0" class="contSecCheck"
                                        style="display:flex!important;height:30px">

                                        <div>
                                            <v-checkbox v-model="idSecSel" multiple class="cl-checkbox selsec"
                                                :value="el.val_id_section" />
                                        </div>

                                        <div style="font-weight: bold;width:228px">{{ el.val_section }}</div>

                                        <div style="font-weight: bold;">Read Only</div>

                                    </div>

                                    <div class="contCheck" style="display:flex!important;height:30px">

                                        <div>
                                            <v-checkbox v-model="idPermSel" multiple class="cl-checkbox"
                                                :value="el.id" />
                                        </div>

                                        <div v-if="el.id == 'manage_users'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/users_menu3.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'edit_timesheet'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/timesheet3.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'edit_holidays_permits'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/ferie2.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'edit_disease'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/malattia4.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'edit_profilo'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/profilo3.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_cespiti'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/cespiti_emt.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_slider'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/slider_emt2.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_perf_imp'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/performance_settings.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_perf_risorse'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/performance_ts.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'invio_presenze'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/invio_presenze2.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_azi_in_agila_sec'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/aziende.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_azi_agila'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/aziende.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_commercialisti'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/commercialisti-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_controlli'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/controlli-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_controlli_standard'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/controlli-standard-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_descr_mod_fatt'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/descr-mod-fatturazione-emt.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_mod_fatt'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/mod-fatturazione-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_pratiche'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/pratiche-emt.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_referenti'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/referenti_emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_scadenze'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/scadenze-emt.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_scadenze_standard'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/scadenze-standard-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_sedi_operative'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/sedi-operative-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_sett_att'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/settori-attività-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_stati_pag'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/stato-pagamenti-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_stato_pratiche'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/stato-pratiche-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_tipo_controllo'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/tipi_di_controlli_emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_tipo_progetto'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/tipo-di-progetto-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'conf_prod'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/configuration_product_emt.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_gruppi'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/groups.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'logo_firma'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/imposta-logo-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'piano_orario'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/piano_orario2.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'utenti_azienda'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/assoc_user_company.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'clienti_fornitori'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/ts_cust_supp.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'aziende_emt'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/aziende.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'aziende_basic'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/aziende.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'calendario_timesheet'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/ts_calendar2.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'creazione_globale_timesheet'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/creaz_globale_ts.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'referenti_emt'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/referenti_emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'manage_risorse'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/risorse2.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'timesheet_fornitori'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/timesheet3.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'verifica_timesheet'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/verifica_ts.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'mng_not_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/notifiche-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'logo_firma_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/imposta-logo-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'mod_invio_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/modalita-invio-alertmsg.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'azi_emt_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/aziende-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'azi_agila_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/aziende-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'refs_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/referenti-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'users_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/utenti-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile2-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'msg_base_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/messaggio-base-alertmsg.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'msg_ris_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/messaggio-risorse-alertmsg.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'sto_base_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/storico-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'msg_refs_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/messaggio-referenti-alertmsg.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'sto_refs_alert'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/storico-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_ebadge'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider-ebadge.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profile_ebadge'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile-ebadge.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'qr_code_ebadge'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/qr-code-ebadge.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_easyer'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider-easyer.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'logo_firma_easyer'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/imposta-logo-easyer.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'spese_easyer'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/spese-easyer.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'appr_easyer'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/amministrazione-easyer.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_easyer'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profilo-easyer.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'users_easyer'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/users-easyer.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'verifica_ebadge'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/timesheet-ebadge-emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'prj_fin'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/progetti_prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'prj_master'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/progetti_master_prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'ore_task'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/ore_task_prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'attivita'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/attivita_prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'assoc_ris_task'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/assoc_ris_task_prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'prat_agila'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/pratiche-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'ordini_clienti'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/icona-ordine-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'ordini_fornitori'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/icona-offerta-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'livello_gm'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gross-margin-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'check_scadenze'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gross-margin-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'cdc'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/cdc_prjweb.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'tipo_cdc'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/tipo_cdc_prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'gruppi_mail_prjweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppo-mail-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'invio_mail_prjweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/configurazione-invio-mail-prjweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'profilo_prjweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile-prjweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'utenti_prjweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/users-prjweb.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'fatt_ant_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatture_anticipate_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'fatt_aut_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatt_autom_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'fatt_cli_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatture_clienti_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'fatt_cli_agila_invoicewweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatture_clienti_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'fatt_forn_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatture_fornitori_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'perf_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/prestaz_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'banche_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/banche_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'cli_forn_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/aziende_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'cond_pag_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/cond_pag_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'iva_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/iva_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'logo_firma_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/imposta-logo-invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'notif_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/settings_menu_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'um_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/unita_di_mis_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'valute_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/valuta_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'grp_mail_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppo_mail_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'inv_mail_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/configurazione_invio_mail_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'abil_fatt_vis_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/abil_fatt_vis_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'fatt_comm_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatture_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profile_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile2_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'utenti_invoiceweb'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/users_menu2_invoiceweb.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'grp_mail_alertduedate'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppo-mail-alertduedate.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'conf_invio_mail_alertduedate'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/configura-mail-alertduedate.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_alertduedate'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider-alertduedate.png" max-width="20" max-height="20"
                                                traloginnsition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'tipologie_alertduedate'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/tipologie-alertduedate.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'pagamenti_alertduedate'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/pagamenti-alertduedate.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_alertduedate'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profilo-alertduedate.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'forn_fatt'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/fatture_fornitori_invoiceweb.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'grp_mail_todolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppo_mail_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'conf_invio_mail_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/conf_mail_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'argomenti_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/argomenti_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'attivita_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/attività_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'pianificazione_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/pianificazione_menu_2dolist.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'categorie_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/categorie_hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'sottocategorie_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/sottocategorie_hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'conf_mail_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/enable_mail_hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'gruppo_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/gruppi_hdtol.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'auto_assign_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/supporto_auto_hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'ticket_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/supporto_hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile_hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'remind_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/reminder_menu_2dolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'logo_firma_hdtool'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/imposta-logo-hdtool.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'notes_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/notes-2dolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'grp_not_2dolist'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppo_mail_todolist.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'notif_docsign'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/settings_menu_docsign.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_docsign'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/menu_slider_docsign.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_docsign'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profile_docsign.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'utenti_docsign'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/users_docsign.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'oggetti_docsign'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/signature_docsign.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'doc_docsign'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/document_docsign.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>



                                        <div v-if="el.id == 'reminder_timesheet'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/reminder_timesheet_emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'reminder_ferie_permessi'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/reminder_ferie_emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'calendario_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/calendario_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'corsi_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/corsi_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'moduli_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/moduli_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'dip_collab_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/dipendenti_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'partecipanti_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/partecipanti_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'tipi_cespiti_emt'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/tipo_cespiti_emt.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'doc_cond_alertmsg'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/doc-cond-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'msg_base_storico'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/storico-alertmsg.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider_jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'gruppi_mail_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppo_mail_jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'invio_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/configurazione invio mail jobmule.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'aziende_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/aziende-jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'categorie_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_categorie.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'giorn_lav_tipo_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_giornata_di_lavoro.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'provacy_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/privacy-careers-non-portale.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'settore_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_settore.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'skills_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_skills.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'subcat_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_sottocategorie.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'studi_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_studi_minimi.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'tipo_contr_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_tipo_contr.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profilo_jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'comuni_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_comuni.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'province_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_province.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'regioni_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_regioni.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'stati_iter_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_colloquio.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'annunci_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_annunci.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'candidati_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_candidati.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'cv_ric_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_cv_ricevuti.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'refer_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_referenti.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'ricerca_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_ricerca.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'logo_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/imposta-logo-jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'imgs_sofndo_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider_jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'azienda_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/aziende-jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'rss_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/rss-jobmule.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'power_by_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/created-by-jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'privacy_portale_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/privacy-jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'term_of_use_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/term-of-use-jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'cv_personali'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_cv_ricevuti.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'cat_skills_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/jobmule_skill_category.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'gruppi_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/groups_jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'partecipanti2_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/partecipanti_smartform2.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'utenti_azi_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/utenti_aziende_smartform.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'revisore_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/revisore_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'costi_azi_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/costo_azi_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'ore_lul_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/partecipanti_smartform2.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'newsletter_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/newsletter_jobmule.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'invio_newsletter_jobmule'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/configurazione_invio_mail_jobmule.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>




                                        <div v-if="el.id == 'config_orari_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/confg_orari_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'configurazione_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/config_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'eccezzioni_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/eccezzioni_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'mail_gruppi_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/gruppi_mail_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'medici_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/medici_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'parenti_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/parenti_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'slider_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/slider_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'conf_prenot_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/conferma_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'elenco_prenot_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/elenco_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'prenota_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/prenota_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'statistiche_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/statistiche_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'tipologie_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/tipologie_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profilo_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'utenti_smartbook'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/utenti_smartbook.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'documenti_smartform'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/documenti_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'timesheet_clienti'">
                                            <v-img alt="Vuetify Logo" class="  " contain src="@/assets/timesheet3.png"
                                                max-width="20" max-height="20" transition="scale-transition" />
                                        </div>



                                        <div v-if="el.id == 'log_notif_sm'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/log_notification_sendmagic.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'log_sms_sm'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/log_sms_sendmagic.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'send_notif_sm'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/send_notification_sendmagic.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'send_sms_sm'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/send_sms_sendmagic.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'profilo_sm'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/profilo3_sendmagic.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>


                                        <div v-if="el.id == 'corsi_online_corsi'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/corsi_online_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'corsi_online_flusso'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/flusso_corsi_online_smartform.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'corsi_online_conf_domande'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/corsi_online_domande_smartform.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'corsi_online_conf_risposte'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/corsi_online_risposte_smartform.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'corsi_online_conf_pesi_risp'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/corsi_online_risposte_pesi_smartform.png" max-width="20"
                                                max-height="20" transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'formazione_calendario'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/calendario_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'formazione_corsi'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/corsi_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>

                                        <div v-if="el.id == 'formazione_documenti'">
                                            <v-img alt="Vuetify Logo" class="  " contain
                                                src="@/assets/documenti_smartform.png" max-width="20" max-height="20"
                                                transition="scale-transition" />
                                        </div>











                                        <div style="margin-left: 14px;width:199px">{{ el.name }}</div>

                                        <div>
                                            <v-checkbox v-model="idPermROSel" multiple class="cl-checkbox"
                                                :value="el.id_ro" :id="el.id_ro" />
                                        </div>





                                    </div>

                                </v-list-tile>


                            </v-list>


                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-permissions" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelPermissions" />

                                <v-img alt="" class="btn-confirm-permissions" contain src="@/assets/icon_save_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Salva" @click="btnConfirmPermissions" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetProducts">
                <v-sheet class="text-center" height="385px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetProducts = !sheetProducts">

                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                            max-width="24" max-height="24" style="margin-right:13px" transition="scale-transition" />
                    </v-btn>
                    <div class="py-3">
                        Seleziona Prodotto
                    </div>
                    <v-list-item v-for="tile in prodotti" :key="tile.productid" @click="changeProd(tile.productid)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.description
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>



    </div>
</template>

<script>

import apiconfigurationproduct from "../utils/configurationproduct/apiconfigurationproduct";

import Vue from "vue";
import VModal from 'vue-js-modal';

import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].showAziendaSelezionata = false;

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });


        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        window.ddlProducts = null;
        window.ddlUsers = null;
        window.ddlGroups = null;

        var pointerVue = this;

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
            //window.curStatus = "to Approve";

            window.curStatus = "Not Closed";

        }
        else {
            window.curStatus = "Open";

        }


        window.curSrcText = "";
        window.curProdId = "";



        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );


        $(document).ready(function () {


            $('body').on('click', '.selsec', function () {

                console.log(("EVENTO CLICK SELSEC"));

                var v_val = $(this).find("input").val();

                var v_chk = $(this).find("input").prop("checked");

                // console.log("VAL: ", v_val);
                // console.log("CHK: ", v_chk);
                // console.log("ID CHK SEL: ", pointerVue.idPermSel);

                //console.log("ID SEC SEL: ", pointerVue.idSecSel);

                if (v_val == 1) {

                    var tmp_array = [];

                    for (var x = 0; x <= pointerVue.idPermSel.length - 1; x++) {

                        if (pointerVue.idPermSel[x] != "manage_slider") {
                            tmp_array.push(pointerVue.idPermSel[x]);
                        }

                    }

                    console.log("TMP ARRAY: ", tmp_array);

                    if (v_chk == true) {

                        tmp_array.push("manage_slider");

                    }

                    pointerVue.idPermSel = tmp_array;

                }


                if (v_val == 2) {

                    var tmp_array2 = [];

                    for (var x2 = 0; x2 <= pointerVue.idPermSel.length - 1; x2++) {

                        if ((pointerVue.idPermSel[x2] != "manage_perf_imp") && (pointerVue.idPermSel[x2] != "manage_perf_risorse")) {
                            tmp_array2.push(pointerVue.idPermSel[x2]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array2.push("manage_perf_imp");
                        tmp_array2.push("manage_perf_risorse");


                    }

                    pointerVue.idPermSel = tmp_array2;

                }

                if (v_val == 3) {

                    var tmp_array3 = [];

                    for (var x3 = 0; x3 <= pointerVue.idPermSel.length - 1; x3++) {

                        if (pointerVue.idPermSel[x3] != "invio_presenze") {
                            tmp_array3.push(pointerVue.idPermSel[x3]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array3.push("invio_presenze");


                    }

                    pointerVue.idPermSel = tmp_array3;

                }

                if (v_val == 4) {

                    var tmp_array4 = [];

                    for (var x4 = 0; x4 <= pointerVue.idPermSel.length - 1; x4++) {

                        if ((pointerVue.idPermSel[x4] != "manage_azi_in_agila_sec") &&
                            (pointerVue.idPermSel[x4] != "manage_azi_agila") &&
                            (pointerVue.idPermSel[x4] != "manage_commercialisti") &&
                            (pointerVue.idPermSel[x4] != "manage_controlli") &&
                            (pointerVue.idPermSel[x4] != "manage_controlli_standard") &&
                            (pointerVue.idPermSel[x4] != "manage_descr_mod_fatt") &&
                            (pointerVue.idPermSel[x4] != "manage_mod_fatt") &&
                            (pointerVue.idPermSel[x4] != "manage_pratiche") &&
                            (pointerVue.idPermSel[x4] != "manage_referenti") &&
                            (pointerVue.idPermSel[x4] != "manage_scadenze") &&
                            (pointerVue.idPermSel[x4] != "manage_scadenze_standard") &&
                            (pointerVue.idPermSel[x4] != "manage_sedi_operative") &&
                            (pointerVue.idPermSel[x4] != "manage_sett_att") &&
                            (pointerVue.idPermSel[x4] != "manage_stati_pag") &&
                            (pointerVue.idPermSel[x4] != "manage_stato_pratiche") &&
                            (pointerVue.idPermSel[x4] != "manage_tipo_controllo") &&
                            (pointerVue.idPermSel[x4] != "manage_tipo_progetto")) {
                            tmp_array4.push(pointerVue.idPermSel[x4]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array4.push("manage_azi_in_agila_sec");
                        tmp_array4.push("manage_azi_agila");
                        tmp_array4.push("manage_commercialisti");
                        tmp_array4.push("manage_controlli");
                        tmp_array4.push("manage_controlli_standard");
                        tmp_array4.push("manage_descr_mod_fatt");
                        tmp_array4.push("manage_mod_fatt");
                        tmp_array4.push("manage_pratiche");
                        tmp_array4.push("manage_referenti");
                        tmp_array4.push("manage_scadenze");
                        tmp_array4.push("manage_scadenze_standard");
                        tmp_array4.push("manage_sedi_operative");
                        tmp_array4.push("manage_sett_att");
                        tmp_array4.push("manage_stati_pag");
                        tmp_array4.push("manage_stato_pratiche");
                        tmp_array4.push("manage_tipo_controllo");
                        tmp_array4.push("manage_tipo_progetto");

                    }

                    pointerVue.idPermSel = tmp_array4;

                }

                if (v_val == 5) {

                    var tmp_array5 = [];

                    for (var x5 = 0; x5 <= pointerVue.idPermSel.length - 1; x5++) {

                        if ((pointerVue.idPermSel[x5] != "conf_prod") &&
                            (pointerVue.idPermSel[x5] != "manage_gruppi") &&
                            (pointerVue.idPermSel[x5] != "logo_firma") &&
                            (pointerVue.idPermSel[x5] != "piano_orario") &&
                            (pointerVue.idPermSel[x5] != "utenti_azienda") &&
                            (pointerVue.idPermSel[x5] != "reminder_timesheet")) {
                            tmp_array5.push(pointerVue.idPermSel[x5]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array5.push("conf_prod");
                        tmp_array5.push("manage_gruppi");
                        tmp_array5.push("logo_firma");
                        tmp_array5.push("piano_orario");
                        tmp_array5.push("utenti_azienda");
                        tmp_array5.push("reminder_timesheet");
                        tmp_array5.push("reminder_ferie_permessi");

                    }

                    pointerVue.idPermSel = tmp_array5;

                }

                if (v_val == 6) {

                    var tmp_array6 = [];

                    for (var x6 = 0; x6 <= pointerVue.idPermSel.length - 1; x6++) {

                        if (pointerVue.idPermSel[x6] != "clienti_fornitori") {
                            tmp_array6.push(pointerVue.idPermSel[x6]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array6.push("clienti_fornitori");


                    }

                    pointerVue.idPermSel = tmp_array6;

                }

                if (v_val == 7) {

                    var tmp_array7 = [];

                    for (var x7 = 0; x7 <= pointerVue.idPermSel.length - 1; x7++) {

                        if ((pointerVue.idPermSel[x7] != "manage_users") &&
                            (pointerVue.idPermSel[x7] != "edit_profilo")) {
                            tmp_array7.push(pointerVue.idPermSel[x7]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array7.push("manage_users");
                        tmp_array7.push("edit_profilo");


                    }

                    pointerVue.idPermSel = tmp_array7;

                }


                if (v_val == 8) {

                    var tmp_array8 = [];

                    for (var x8 = 0; x8 <= pointerVue.idPermSel.length - 1; x8++) {

                        if ((pointerVue.idPermSel[x8] != "edit_timesheet") &&
                            (pointerVue.idPermSel[x8] != "aziende_emt") &&
                            (pointerVue.idPermSel[x8] != "aziende_basic") &&
                            (pointerVue.idPermSel[x8] != "calendario_timesheet") &&
                            (pointerVue.idPermSel[x8] != "creazione_globale_timesheet") &&
                            (pointerVue.idPermSel[x8] != "referenti_emt") &&
                            (pointerVue.idPermSel[x8] != "manage_risorse") &&
                            (pointerVue.idPermSel[x8] != "verifica_timesheet") &&
                            (pointerVue.idPermSel[x8] != "verifica_ebadge") &&
                            (pointerVue.idPermSel[x8] != "timesheet_clienti")) {
                            tmp_array8.push(pointerVue.idPermSel[x8]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array8.push("edit_timesheet");
                        tmp_array8.push("edit_holidays_permits");
                        tmp_array8.push("edit_disease");
                        tmp_array8.push("aziende_emt");
                        tmp_array8.push("aziende_basic");
                        tmp_array8.push("calendario_timesheet");
                        tmp_array8.push("creazione_globale_timesheet");
                        tmp_array8.push("referenti_emt");
                        tmp_array8.push("manage_risorse");
                        tmp_array8.push("verifica_timesheet");
                        tmp_array8.push("timesheet_clienti");

                    }

                    pointerVue.idPermSel = tmp_array8;

                }



                if (v_val == 9) {

                    var tmp_array9 = [];

                    for (var x9 = 0; x9 <= pointerVue.idPermSel.length - 1; x9++) {

                        if ((pointerVue.idPermSel[x9] != "mng_not_alert") &&
                            (pointerVue.idPermSel[x9] != "logo_firma_alert") &&
                            (pointerVue.idPermSel[x9] != "mod_invio_alert")) {
                            tmp_array9.push(pointerVue.idPermSel[x9]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array9.push("mng_not_alert");
                        tmp_array9.push("logo_firma_alert");
                        tmp_array9.push("mod_invio_alert");


                    }

                    pointerVue.idPermSel = tmp_array9;

                }


                if (v_val == 10) {

                    var tmp_array10 = [];

                    for (var x10 = 0; x10 <= pointerVue.idPermSel.length - 1; x10++) {

                        if ((pointerVue.idPermSel[x10] != "azi_emt_alert") &&
                            (pointerVue.idPermSel[x10] != "azi_agila_alert") &&
                            (pointerVue.idPermSel[x10] != "refs_alert")) {
                            tmp_array10.push(pointerVue.idPermSel[x10]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array10.push("azi_emt_alert");
                        tmp_array10.push("azi_agila_alert");
                        tmp_array10.push("refs_alert");


                    }

                    pointerVue.idPermSel = tmp_array10;

                }



                if (v_val == 11) {

                    var tmp_array11 = [];

                    for (var x11 = 0; x11 <= pointerVue.idPermSel.length - 1; x11++) {

                        if ((pointerVue.idPermSel[x11] != "users_alert") &&
                            (pointerVue.idPermSel[x11] != "profilo_alert")) {
                            tmp_array11.push(pointerVue.idPermSel[x11]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array11.push("users_alert");
                        tmp_array11.push("profilo_alert");


                    }

                    pointerVue.idPermSel = tmp_array11;

                }

                if (v_val == 12) {

                    var tmp_array12 = [];

                    for (var x12 = 0; x12 <= pointerVue.idPermSel.length - 1; x12++) {

                        if ((pointerVue.idPermSel[x12] != "sto_base_alert") &&
                            (pointerVue.idPermSel[x12] != "msg_ris_alert")) {
                            tmp_array12.push(pointerVue.idPermSel[x12]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array12.push("sto_base_alert");
                        tmp_array12.push("msg_ris_alert");


                    }

                    pointerVue.idPermSel = tmp_array12;

                }


                if (v_val == 13) {

                    var tmp_array13 = [];

                    for (var x13 = 0; x13 <= pointerVue.idPermSel.length - 1; x13++) {

                        if ((pointerVue.idPermSel[x13] != "msg_refs_alert") &&
                            (pointerVue.idPermSel[x13] != "sto_refs_alert")) {
                            tmp_array13.push(pointerVue.idPermSel[x13]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array13.push("msg_refs_alert");
                        tmp_array13.push("sto_refs_alert");


                    }

                    pointerVue.idPermSel = tmp_array13;

                }



                if (v_val == 14) {

                    var tmp_array14 = [];

                    for (var x14 = 0; x14 <= pointerVue.idPermSel.length - 1; x14++) {

                        if (pointerVue.idPermSel[x14] != "slider_ebadge") {
                            tmp_array14.push(pointerVue.idPermSel[x14]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array14.push("slider_ebadge");


                    }

                    pointerVue.idPermSel = tmp_array14;

                }


                if (v_val == 15) {

                    var tmp_array15 = [];

                    for (var x15 = 0; x15 <= pointerVue.idPermSel.length - 1; x15++) {

                        if (pointerVue.idPermSel[x15] != "profile_ebadge") {
                            tmp_array15.push(pointerVue.idPermSel[x15]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array15.push("profile_ebadge");


                    }

                    pointerVue.idPermSel = tmp_array15;

                }


                if (v_val == 16) {

                    var tmp_array16 = [];

                    for (var x16 = 0; x16 <= pointerVue.idPermSel.length - 1; x16++) {

                        if (pointerVue.idPermSel[x16] != "qr_code_ebadge") {
                            tmp_array16.push(pointerVue.idPermSel[x16]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array16.push("qr_code_ebadge");


                    }

                    pointerVue.idPermSel = tmp_array16;

                }

                if (v_val == 17) {

                    var tmp_array17 = [];

                    for (var x17 = 0; x17 <= pointerVue.idPermSel.length - 1; x17++) {

                        if (pointerVue.idPermSel[x17] != "slider_easyer") {
                            tmp_array17.push(pointerVue.idPermSel[x17]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array17.push("slider_easyer");


                    }

                    pointerVue.idPermSel = tmp_array17;

                }


                if (v_val == 18) {

                    var tmp_array18 = [];

                    for (var x18 = 0; x18 <= pointerVue.idPermSel.length - 1; x18++) {

                        if (pointerVue.idPermSel[x18] != "logo_firma_easyer") {
                            tmp_array18.push(pointerVue.idPermSel[x18]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array18.push("logo_firma_easyer");


                    }

                    pointerVue.idPermSel = tmp_array18;

                }


                if (v_val == 19) {

                    var tmp_array19 = [];

                    for (var x19 = 0; x19 <= pointerVue.idPermSel.length - 1; x19++) {

                        if ((pointerVue.idPermSel[x19] != "spese_easyer") &&
                            (pointerVue.idPermSel[x19] != "appr_easyer")) {
                            tmp_array19.push(pointerVue.idPermSel[x19]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array19.push("spese_easyer");
                        tmp_array19.push("appr_easyer");


                    }

                    pointerVue.idPermSel = tmp_array19;

                }



                if (v_val == 20) {

                    var tmp_array20 = [];

                    for (var x20 = 0; x20 <= pointerVue.idPermSel.length - 1; x20++) {

                        if ((pointerVue.idPermSel[x20] != "profilo_easyer") &&
                            (pointerVue.idPermSel[x20] != "users_easyer")) {
                            tmp_array20.push(pointerVue.idPermSel[x20]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array20.push("profilo_easyer");
                        tmp_array20.push("users_easyer");


                    }

                    pointerVue.idPermSel = tmp_array20;

                }



                if (v_val == 21) {

                    var tmp_array21 = [];

                    for (var x21 = 0; x21 <= pointerVue.idPermSel.length - 1; x21++) {

                        if ((pointerVue.idPermSel[x21] != "prj_fin") &&
                            (pointerVue.idPermSel[x21] != "prj_master") &&
                            (pointerVue.idPermSel[x21] != "ore_task") &&
                            (pointerVue.idPermSel[x21] != "attivita") &&
                            (pointerVue.idPermSel[x21] != "assoc_ris_task")) {
                            tmp_array21.push(pointerVue.idPermSel[x21]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array21.push("prj_fin");
                        tmp_array21.push("prj_master");
                        tmp_array21.push("ore_task");
                        tmp_array21.push("attivita");
                        tmp_array21.push("assoc_ris_task");

                    }

                    pointerVue.idPermSel = tmp_array21;

                }


                if (v_val == 22) {

                    var tmp_array22 = [];

                    for (var x22 = 0; x22 <= pointerVue.idPermSel.length - 1; x22++) {

                        if ((pointerVue.idPermSel[x22] != "prat_agila") &&
                            (pointerVue.idPermSel[x22] != "ordini_clienti") &&
                            (pointerVue.idPermSel[x22] != "ordini_fornitori")) {
                            tmp_array22.push(pointerVue.idPermSel[x22]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array22.push("prat_agila");
                        tmp_array22.push("ordini_clienti");
                        tmp_array22.push("ordini_fornitori");


                    }

                    pointerVue.idPermSel = tmp_array22;

                }

                if (v_val == 23) {

                    var tmp_array23 = [];

                    for (var x23 = 0; x23 <= pointerVue.idPermSel.length - 1; x23++) {

                        if ((pointerVue.idPermSel[x23] != "livello_gm") &&
                            (pointerVue.idPermSel[x23] != "check_scadenze")) {
                            tmp_array23.push(pointerVue.idPermSel[x23]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array23.push("livello_gm");
                        tmp_array23.push("check_scadenze");



                    }

                    pointerVue.idPermSel = tmp_array23;

                }

                if (v_val == 24) {

                    var tmp_array24 = [];

                    for (var x24 = 0; x24 <= pointerVue.idPermSel.length - 1; x24++) {

                        if ((pointerVue.idPermSel[x24] != "cdc") &&
                            (pointerVue.idPermSel[x24] != "tipo_cdc")) {
                            tmp_array24.push(pointerVue.idPermSel[x24]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array24.push("cdc");
                        tmp_array24.push("tipo_cdc");



                    }

                    pointerVue.idPermSel = tmp_array24;

                }


                if (v_val == 25) {

                    var tmp_array25 = [];

                    for (var x25 = 0; x25 <= pointerVue.idPermSel.length - 1; x25++) {

                        if ((pointerVue.idPermSel[x25] != "gruppi_mail_prjweb") &&
                            (pointerVue.idPermSel[x25] != "invio_mail_prjweb")) {
                            tmp_array25.push(pointerVue.idPermSel[x25]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array25.push("gruppi_mail_prjweb");
                        tmp_array25.push("invio_mail_prjweb");



                    }

                    pointerVue.idPermSel = tmp_array25;

                }

                if (v_val == 26) {

                    var tmp_array26 = [];

                    for (var x26 = 0; x26 <= pointerVue.idPermSel.length - 1; x26++) {

                        if ((pointerVue.idPermSel[x26] != "profilo_prjweb") &&
                            (pointerVue.idPermSel[x26] != "utenti_prjweb")) {
                            tmp_array26.push(pointerVue.idPermSel[x26]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array26.push("profilo_prjweb");
                        tmp_array26.push("utenti_prjweb");



                    }

                    pointerVue.idPermSel = tmp_array26;

                }


                if (v_val == 27) {

                    var tmp_array27 = [];

                    for (var x27 = 0; x27 <= pointerVue.idPermSel.length - 1; x27++) {

                        if ((pointerVue.idPermSel[x27] != "slider_invoiceweb")) {
                            tmp_array27.push(pointerVue.idPermSel[x27]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array27.push("slider_invoiceweb");



                    }

                    pointerVue.idPermSel = tmp_array27;

                }

                if (v_val == 28) {

                    var tmp_array28 = [];

                    for (var x28 = 0; x28 <= pointerVue.idPermSel.length - 1; x28++) {

                        if ((pointerVue.idPermSel[x28] != "fatt_ant_invoiceweb") &&
                            (pointerVue.idPermSel[x28] != "fatt_aut_invoiceweb") &&
                            (pointerVue.idPermSel[x28] != "fatt_cli_invoiceweb") &&
                            (pointerVue.idPermSel[x28] != "fatt_cli_agila_invoicewweb") &&
                            (pointerVue.idPermSel[x28] != "fatt_forn_invoiceweb") &&
                            (pointerVue.idPermSel[x28] != "perf_invoiceweb")) {
                            tmp_array28.push(pointerVue.idPermSel[x28]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array28.push("fatt_ant_invoiceweb");
                        tmp_array28.push("fatt_aut_invoiceweb");
                        tmp_array28.push("fatt_cli_invoiceweb");
                        tmp_array28.push("fatt_cli_agila_invoicewweb");
                        tmp_array28.push("fatt_forn_invoiceweb");
                        tmp_array28.push("perf_invoiceweb");



                    }

                    pointerVue.idPermSel = tmp_array28;

                }






                if (v_val == 29) {

                    var tmp_array29 = [];

                    for (var x29 = 0; x29 <= pointerVue.idPermSel.length - 1; x29++) {

                        if ((pointerVue.idPermSel[x29] != "banche_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "cli_forn_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "cond_pag_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "iva_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "notif_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "um_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "valute_invoiceweb") &&
                            (pointerVue.idPermSel[x29] != "logo_firma_invoiceweb")) {
                            tmp_array29.push(pointerVue.idPermSel[x29]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array29.push("banche_invoiceweb");
                        tmp_array29.push("cli_forn_invoiceweb");
                        tmp_array29.push("cond_pag_invoiceweb");
                        tmp_array29.push("iva_invoiceweb");
                        tmp_array29.push("notif_invoiceweb");
                        tmp_array29.push("um_invoiceweb");
                        tmp_array29.push("valute_invoiceweb");
                        tmp_array29.push("logo_firma_invoiceweb");



                    }

                    pointerVue.idPermSel = tmp_array29;

                }




                if (v_val == 30) {

                    var tmp_array30 = [];

                    for (var x30 = 0; x30 <= pointerVue.idPermSel.length - 1; x30++) {

                        if ((pointerVue.idPermSel[x30] != "grp_mail_invoiceweb") &&
                            (pointerVue.idPermSel[x30] != "inv_mail_invoiceweb")) {
                            tmp_array30.push(pointerVue.idPermSel[x30]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array30.push("grp_mail_invoiceweb");
                        tmp_array30.push("inv_mail_invoiceweb");




                    }

                    pointerVue.idPermSel = tmp_array30;

                }




                if (v_val == 31) {

                    var tmp_array31 = [];

                    for (var x31 = 0; x31 <= pointerVue.idPermSel.length - 1; x31++) {

                        if ((pointerVue.idPermSel[x31] != "abil_fatt_vis_invoiceweb") &&
                            (pointerVue.idPermSel[x31] != "fatt_comm_invoiceweb")) {
                            tmp_array31.push(pointerVue.idPermSel[x31]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array31.push("abil_fatt_vis_invoiceweb");
                        tmp_array31.push("fatt_comm_invoiceweb");




                    }

                    pointerVue.idPermSel = tmp_array31;

                }

                if (v_val == 32) {

                    var tmp_array32 = [];

                    for (var x32 = 0; x32 <= pointerVue.idPermSel.length - 1; x32++) {

                        if ((pointerVue.idPermSel[x32] != "profile_invoiceweb") &&
                            (pointerVue.idPermSel[x32] != "utenti_invoiceweb")) {
                            tmp_array32.push(pointerVue.idPermSel[x32]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array32.push("profile_invoiceweb");
                        tmp_array32.push("utenti_invoiceweb");

                    }

                    pointerVue.idPermSel = tmp_array32;

                }

                if (v_val == 33) {

                    var tmp_array33 = [];

                    for (var x33 = 0; x33 <= pointerVue.idPermSel.length - 1; x33++) {

                        if ((pointerVue.idPermSel[x33] != "grp_mail_alertduedate") &&
                            (pointerVue.idPermSel[x33] != "conf_invio_mail_alertduedate") &&
                            (pointerVue.idPermSel[x33] != "slider_alertduedate")) {
                            tmp_array33.push(pointerVue.idPermSel[x33]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array33.push("grp_mail_alertduedate");
                        tmp_array33.push("conf_invio_mail_alertduedate");
                        tmp_array33.push("slider_alertduedate");

                    }

                    pointerVue.idPermSel = tmp_array33;

                }


                if (v_val == 34) {

                    var tmp_array34 = [];

                    for (var x34 = 0; x34 <= pointerVue.idPermSel.length - 1; x34++) {

                        if ((pointerVue.idPermSel[x34] != "tipologie_alertduedate") &&
                            (pointerVue.idPermSel[x34] != "pagamenti_alertduedate")) {
                            tmp_array34.push(pointerVue.idPermSel[x34]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array34.push("tipologie_alertduedate");
                        tmp_array34.push("pagamenti_alertduedate");

                    }

                    pointerVue.idPermSel = tmp_array34;

                }


                if (v_val == 35) {

                    var tmp_array35 = [];

                    for (var x35 = 0; x35 <= pointerVue.idPermSel.length - 1; x35++) {

                        if ((pointerVue.idPermSel[x35] != "profilo_alertduedate")) {
                            tmp_array35.push(pointerVue.idPermSel[x35]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array35.push("profilo_alertduedate");

                    }

                    pointerVue.idPermSel = tmp_array35;

                }



                if (v_val == 31.1) {

                    var tmp_array311 = [];

                    for (var x311 = 0; x311 <= pointerVue.idPermSel.length - 1; x311++) {

                        if ((pointerVue.idPermSel[x311] != "forn_fatt")) {
                            tmp_array311.push(pointerVue.idPermSel[x311]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array311.push("forn_fatt");

                    }

                    pointerVue.idPermSel = tmp_array311;

                }


                if (v_val == 36) {

                    var tmp_array36 = [];

                    for (var x36 = 0; x36 <= pointerVue.idPermSel.length - 1; x36++) {

                        if ((pointerVue.idPermSel[x36] != "grp_mail_todolist") &&
                            (pointerVue.idPermSel[x36] != "slider_2dolist") &&
                            (pointerVue.idPermSel[x36] != "conf_invio_mail_2dolist") &&
                            (pointerVue.idPermSel[x36] != "grp_not_2dolist")) {
                            tmp_array36.push(pointerVue.idPermSel[x36]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array36.push("grp_mail_todolist");
                        tmp_array36.push("slider_2dolist");
                        tmp_array36.push("conf_invio_mail_2dolist");
                        tmp_array36.push("grp_not_2dolist");

                    }

                    pointerVue.idPermSel = tmp_array36;

                }


                if (v_val == 37) {

                    var tmp_array37 = [];

                    for (var x37 = 0; x37 <= pointerVue.idPermSel.length - 1; x37++) {

                        if ((pointerVue.idPermSel[x37] != "argomenti_2dolist") &&
                            (pointerVue.idPermSel[x37] != "attivita_2dolist") &&
                            (pointerVue.idPermSel[x37] != "pianificazione_2dolist") &&
                            (pointerVue.idPermSel[x37] != "remind_2dolist")) {
                            tmp_array37.push(pointerVue.idPermSel[x37]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array37.push("argomenti_2dolist");
                        tmp_array37.push("attivita_2dolist");
                        tmp_array37.push("pianificazione_2dolist");
                        tmp_array37.push("remind_2dolist");

                    }

                    pointerVue.idPermSel = tmp_array37;

                }



                if (v_val == 38) {

                    var tmp_array38 = [];

                    for (var x38 = 0; x38 <= pointerVue.idPermSel.length - 1; x38++) {

                        if ((pointerVue.idPermSel[x38] != "profilo_2dolist") &&
                            (pointerVue.idPermSel[x38] != "notes_2dolist")) {
                            tmp_array38.push(pointerVue.idPermSel[x38]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array38.push("profilo_2dolist");
                        tmp_array38.push("notes_2dolist");


                    }

                    pointerVue.idPermSel = tmp_array38;

                }






                if (v_val == 39) {

                    var tmp_array39 = [];

                    for (var x39 = 0; x39 <= pointerVue.idPermSel.length - 1; x39++) {

                        if ((pointerVue.idPermSel[x39] != "categorie_hdtool") &&
                            (pointerVue.idPermSel[x39] != "sottocategorie_hdtool")) {
                            tmp_array39.push(pointerVue.idPermSel[x39]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array39.push("categorie_hdtool");
                        tmp_array39.push("sottocategorie_hdtool");

                    }

                    pointerVue.idPermSel = tmp_array39;

                }


                if (v_val == 40) {

                    var tmp_array40 = [];

                    for (var x40 = 0; x40 <= pointerVue.idPermSel.length - 1; x40++) {

                        if ((pointerVue.idPermSel[x40] != "conf_mail_hdtool") &&
                            (pointerVue.idPermSel[x40] != "gruppo_hdtool") &&
                            (pointerVue.idPermSel[x40] != "auto_assign_hdtool") &&
                            (pointerVue.idPermSel[x40] != "logo_firma_hdtool")) {
                            tmp_array40.push(pointerVue.idPermSel[x40]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array40.push("conf_mail_hdtool");
                        tmp_array40.push("gruppo_hdtool");
                        tmp_array40.push("auto_assign_hdtool");
                        tmp_array40.push("logo_firma_hdtool");

                    }

                    pointerVue.idPermSel = tmp_array40;

                }


                if (v_val == 41) {

                    var tmp_array41 = [];

                    for (var x41 = 0; x41 <= pointerVue.idPermSel.length - 1; x41++) {

                        if ((pointerVue.idPermSel[x41] != "ticket_hdtool")) {
                            tmp_array41.push(pointerVue.idPermSel[x41]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array41.push("ticket_hdtool");

                    }

                    pointerVue.idPermSel = tmp_array41;

                }



                if (v_val == 42) {

                    var tmp_array42 = [];

                    for (var x42 = 0; x42 <= pointerVue.idPermSel.length - 1; x42++) {

                        if ((pointerVue.idPermSel[x42] != "profilo_hdtool")) {
                            tmp_array42.push(pointerVue.idPermSel[x42]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array42.push("profilo_hdtool");

                    }

                    pointerVue.idPermSel = tmp_array42;

                }




                if (v_val == 43) {

                    var tmp_array43 = [];

                    for (var x43 = 0; x43 <= pointerVue.idPermSel.length - 1; x43++) {

                        if ((pointerVue.idPermSel[x43] != "notif_docsign") &&
                            (pointerVue.idPermSel[x43] != "slider_docsign")) {
                            tmp_array43.push(pointerVue.idPermSel[x43]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array43.push("notif_docsign");
                        tmp_array43.push("slider_docsign");

                    }

                    pointerVue.idPermSel = tmp_array43;

                }


                if (v_val == 44) {

                    var tmp_array44 = [];

                    for (var x44 = 0; x44 <= pointerVue.idPermSel.length - 1; x44++) {

                        if ((pointerVue.idPermSel[x44] != "profilo_docsign") &&
                            (pointerVue.idPermSel[x44] != "utenti_docsign")) {
                            tmp_array44.push(pointerVue.idPermSel[x44]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array44.push("profilo_docsign");
                        tmp_array44.push("utenti_docsign");

                    }

                    pointerVue.idPermSel = tmp_array44;

                }


                if (v_val == 45) {

                    var tmp_array45 = [];

                    for (var x45 = 0; x45 <= pointerVue.idPermSel.length - 1; x45++) {

                        if ((pointerVue.idPermSel[x45] != "oggetti_docsign") &&
                            (pointerVue.idPermSel[x45] != "doc_docsign")) {
                            tmp_array45.push(pointerVue.idPermSel[x45]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array45.push("oggetti_docsign");
                        tmp_array45.push("doc_docsign");

                    }

                    pointerVue.idPermSel = tmp_array45;

                }

                if (v_val == 46) {

                    var tmp_array46 = [];

                    for (var x46 = 0; x46 <= pointerVue.idPermSel.length - 1; x46++) {

                        if ((pointerVue.idPermSel[x46] != "calendario_smartform") &&
                            (pointerVue.idPermSel[x46] != "corsi_smartform") &&
                            (pointerVue.idPermSel[x46] != "revisore_smartform") &&
                            (pointerVue.idPermSel[x46] != "documenti_smartform")) {
                            tmp_array46.push(pointerVue.idPermSel[x46]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array46.push("calendario_smartform");
                        tmp_array46.push("corsi_smartform");
                        tmp_array46.push("revisore_smartform");
                        tmp_array46.push("documenti_smartform");

                    }

                    pointerVue.idPermSel = tmp_array46;

                }



                if (v_val == 47) {

                    var tmp_array47 = [];

                    for (var x47 = 0; x47 <= pointerVue.idPermSel.length - 1; x47++) {

                        if ((pointerVue.idPermSel[x47] != "moduli_smartform")) {
                            tmp_array47.push(pointerVue.idPermSel[x47]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array47.push("moduli_smartform");

                    }

                    pointerVue.idPermSel = tmp_array47;

                }

                if (v_val == 48) {

                    var tmp_array48 = [];

                    for (var x48 = 0; x48 <= pointerVue.idPermSel.length - 1; x48++) {

                        if ((pointerVue.idPermSel[x48] != "costi_azi_smartform") &&
                            (pointerVue.idPermSel[x48] != "partecipanti_smartform") &&
                            (pointerVue.idPermSel[x48] != "partecipanti2_smartform")) {
                            tmp_array48.push(pointerVue.idPermSel[x48]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array48.push("partecipanti_smartform");
                        tmp_array48.push("partecipanti2_smartform");
                        tmp_array48.push("costi_azi_smartform");

                    }

                    pointerVue.idPermSel = tmp_array48;

                }



                if (v_val == 59) {

                    var tmp_array59 = [];

                    for (var x59 = 0; x59 <= pointerVue.idPermSel.length - 1; x59++) {

                        if ((pointerVue.idPermSel[x59] != "dip_collab_smartform")) {
                            tmp_array59.push(pointerVue.idPermSel[x59]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array59.push("dip_collab_smartform");

                    }

                    pointerVue.idPermSel = tmp_array59;

                }

                if (v_val == 6.1) {

                    var tmp_array61 = [];

                    for (var x61 = 0; x61 <= pointerVue.idPermSel.length - 1; x61++) {

                        if ((pointerVue.idPermSel[x61] != "manage_cespiti") &&
                            (pointerVue.idPermSel[x61] != "tipi_cespiti_emt")) {
                            tmp_array61.push(pointerVue.idPermSel[x61]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array61.push("manage_cespiti");
                        tmp_array61.push("tipi_cespiti_emt");

                    }

                    pointerVue.idPermSel = tmp_array61;

                }



                if (v_val == 6.2) {

                    var tmp_array62 = [];

                    for (var x62 = 0; x62 <= pointerVue.idPermSel.length - 1; x62++) {

                        if ((pointerVue.idPermSel[x62] != "edit_holidays_permits") &&
                            (pointerVue.idPermSel[x62] != "reminder_ferie_permessi")) {
                            tmp_array62.push(pointerVue.idPermSel[x62]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array62.push("edit_holidays_permits");
                        tmp_array62.push("reminder_ferie_permessi");

                    }

                    pointerVue.idPermSel = tmp_array62;

                }


                if (v_val == 6.3) {

                    var tmp_array63 = [];

                    for (var x63 = 0; x63 <= pointerVue.idPermSel.length - 1; x63++) {

                        if ((pointerVue.idPermSel[x63] != "edit_disease")) {
                            tmp_array63.push(pointerVue.idPermSel[x63]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array63.push("edit_disease");

                    }

                    pointerVue.idPermSel = tmp_array63;

                }



                if (v_val == 13.1) {

                    var tmp_array131 = [];

                    for (var x131 = 0; x131 <= pointerVue.idPermSel.length - 1; x131++) {

                        if ((pointerVue.idPermSel[x131] != "doc_cond_alertmsg")) {
                            tmp_array131.push(pointerVue.idPermSel[x131]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array131.push("doc_cond_alertmsg");

                    }

                    pointerVue.idPermSel = tmp_array131;

                }

                if (v_val == 11.1) {

                    var tmp_array111 = [];

                    for (var x111 = 0; x111 <= pointerVue.idPermSel.length - 1; x111++) {

                        if ((pointerVue.idPermSel[x111] != "msg_base_alert") &&
                            (pointerVue.idPermSel[x111] != "msg_base_storico")) {
                            tmp_array111.push(pointerVue.idPermSel[x111]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array111.push("msg_base_alert");
                        tmp_array111.push("msg_base_storico");

                    }

                    pointerVue.idPermSel = tmp_array111;

                }






                if (v_val == 49) {

                    var tmp_array49 = [];

                    for (var x49 = 0; x49 <= pointerVue.idPermSel.length - 1; x49++) {

                        if ((pointerVue.idPermSel[x49] != "slider_jobmule")) {
                            tmp_array49.push(pointerVue.idPermSel[x49]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array49.push("slider_jobmule");

                    }

                    pointerVue.idPermSel = tmp_array49;

                }



                if (v_val == 50) {

                    var tmp_array50 = [];

                    for (var x50 = 0; x50 <= pointerVue.idPermSel.length - 1; x50++) {

                        if ((pointerVue.idPermSel[x50] != "gruppi_mail_jobmule") &&
                            (pointerVue.idPermSel[x50] != "invio_jobmule")) {
                            tmp_array50.push(pointerVue.idPermSel[x50]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array50.push("gruppi_mail_jobmule");
                        tmp_array50.push("invio_jobmule");

                    }

                    pointerVue.idPermSel = tmp_array50;

                }




                if (v_val == 51) {

                    var tmp_array51 = [];

                    for (var x51 = 0; x51 <= pointerVue.idPermSel.length - 1; x51++) {

                        if ((pointerVue.idPermSel[x51] != "aziende_jobmule") &&
                            (pointerVue.idPermSel[x51] != "giorn_lav_tipo_jobmule") &&
                            (pointerVue.idPermSel[x51] != "provacy_jobmule") &&
                            (pointerVue.idPermSel[x51] != "settore_jobmule") &&
                            (pointerVue.idPermSel[x51] != "studi_jobmule") &&
                            (pointerVue.idPermSel[x51] != "tipo_contr_jobmule") &&
                            (pointerVue.idPermSel[x51] != "gruppi_jobmule")) {
                            tmp_array51.push(pointerVue.idPermSel[x51]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array51.push("aziende_jobmule");
                        tmp_array51.push("giorn_lav_tipo_jobmule");
                        tmp_array51.push("provacy_jobmule");
                        tmp_array51.push("settore_jobmule");
                        tmp_array51.push("studi_jobmule");
                        tmp_array51.push("tipo_contr_jobmule");
                        tmp_array51.push("gruppi_jobmule");



                    }

                    pointerVue.idPermSel = tmp_array51;

                }



                if (v_val == 52) {

                    var tmp_array52 = [];

                    for (var x52 = 0; x52 <= pointerVue.idPermSel.length - 1; x52++) {

                        if ((pointerVue.idPermSel[x52] != "profilo_jobmule")) {
                            tmp_array52.push(pointerVue.idPermSel[x52]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array52.push("profilo_jobmule");




                    }

                    pointerVue.idPermSel = tmp_array52;

                }



                if (v_val == 53) {

                    var tmp_array53 = [];

                    for (var x53 = 0; x53 <= pointerVue.idPermSel.length - 1; x53++) {

                        if ((pointerVue.idPermSel[x53] != "comuni_jobmule") &&
                            (pointerVue.idPermSel[x53] != "province_jobmule") &&
                            (pointerVue.idPermSel[x53] != "regioni_jobmule")) {
                            tmp_array53.push(pointerVue.idPermSel[x53]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array53.push("comuni_jobmule");
                        tmp_array53.push("province_jobmule");
                        tmp_array53.push("regioni_jobmule");




                    }

                    pointerVue.idPermSel = tmp_array53;

                }




                if (v_val == 54) {

                    var tmp_array54 = [];

                    for (var x54 = 0; x54 <= pointerVue.idPermSel.length - 1; x54++) {

                        if ((pointerVue.idPermSel[x54] != "stati_iter_jobmule")) {
                            tmp_array54.push(pointerVue.idPermSel[x54]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array54.push("stati_iter_jobmule");




                    }

                    pointerVue.idPermSel = tmp_array54;

                }

                if (v_val == 55) {

                    var tmp_array55 = [];

                    for (var x55 = 0; x55 <= pointerVue.idPermSel.length - 1; x55++) {

                        if ((pointerVue.idPermSel[x55] != "annunci_jobmule") &&
                            (pointerVue.idPermSel[x55] != "candidati_jobmule") &&
                            (pointerVue.idPermSel[x55] != "cv_ric_jobmule") &&
                            (pointerVue.idPermSel[x55] != "refer_jobmule") &&
                            (pointerVue.idPermSel[x55] != "ricerca_jobmule")) {
                            tmp_array55.push(pointerVue.idPermSel[x55]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array55.push("annunci_jobmule");
                        tmp_array55.push("candidati_jobmule");
                        tmp_array55.push("cv_ric_jobmule");
                        tmp_array55.push("refer_jobmule");
                        tmp_array55.push("ricerca_jobmule");


                    }

                    pointerVue.idPermSel = tmp_array55;

                }

                if (v_val == 56) {

                    var tmp_array56 = [];

                    for (var x56 = 0; x56 <= pointerVue.idPermSel.length - 1; x56++) {

                        if ((pointerVue.idPermSel[x56] != "logo_jobmule") &&
                            (pointerVue.idPermSel[x56] != "imgs_sofndo_jobmule") &&
                            (pointerVue.idPermSel[x56] != "azienda_jobmule") &&
                            (pointerVue.idPermSel[x56] != "rss_jobmule") &&
                            (pointerVue.idPermSel[x56] != "power_by_jobmule") &&
                            (pointerVue.idPermSel[x56] != "privacy_portale_jobmule") &&
                            (pointerVue.idPermSel[x56] != "term_of_use_jobmule")) {
                            tmp_array56.push(pointerVue.idPermSel[x56]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array56.push("logo_jobmule");
                        tmp_array56.push("imgs_sofndo_jobmule");
                        tmp_array56.push("azienda_jobmule");
                        tmp_array56.push("rss_jobmule");
                        tmp_array56.push("power_by_jobmule");
                        tmp_array56.push("privacy_portale_jobmule");
                        tmp_array56.push("term_of_use_jobmule");

                    }

                    pointerVue.idPermSel = tmp_array56;

                }


                if (v_val == 55.1) {

                    var tmp_array551 = [];

                    for (var x551 = 0; x551 <= pointerVue.idPermSel.length - 1; x551++) {

                        if ((pointerVue.idPermSel[x551] != "cv_personali")) {
                            tmp_array551.push(pointerVue.idPermSel[x551]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array551.push("cv_personali");


                    }

                    pointerVue.idPermSel = tmp_array551;

                }


                if (v_val == 50.1) {

                    var tmp_array501 = [];

                    for (var x501 = 0; x501 <= pointerVue.idPermSel.length - 1; x501++) {

                        if ((pointerVue.idPermSel[x501] != "cv_personali")) {
                            tmp_array501.push(pointerVue.idPermSel[x501]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array501.push("cv_personali");


                    }

                    pointerVue.idPermSel = tmp_array501;

                }

                if (v_val == 50.2) {

                    var tmp_array502 = [];

                    for (var x502 = 0; x502 <= pointerVue.idPermSel.length - 1; x502++) {

                        if ((pointerVue.idPermSel[x502] != "subcat_jobmule")) {
                            tmp_array502.push(pointerVue.idPermSel[x502]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array502.push("subcat_jobmule");


                    }

                    pointerVue.idPermSel = tmp_array502;

                }


                if (v_val == 50.3) {

                    var tmp_array503 = [];

                    for (var x503 = 0; x503 <= pointerVue.idPermSel.length - 1; x503++) {

                        if ((pointerVue.idPermSel[x503] != "skills_jobmule") &&
                            (pointerVue.idPermSel[x503] != "cat_skills_jobmule")) {
                            tmp_array503.push(pointerVue.idPermSel[x503]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array503.push("skills_jobmule");
                        tmp_array503.push("cat_skills_jobmule");


                    }

                    pointerVue.idPermSel = tmp_array503;

                }

                if (v_val == 57) {

                    var tmp_array57 = [];

                    for (var x57 = 0; x57 <= pointerVue.idPermSel.length - 1; x57++) {

                        if ((pointerVue.idPermSel[x57] != "utenti_azi_smartform")) {
                            tmp_array57.push(pointerVue.idPermSel[x57]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array57.push("utenti_azi_smartform");


                    }

                    pointerVue.idPermSel = tmp_array57;

                }

                if (v_val == 58) {

                    var tmp_array58 = [];

                    for (var x58 = 0; x58 <= pointerVue.idPermSel.length - 1; x58++) {

                        if ((pointerVue.idPermSel[x58] != "ore_lul_smartform")) {
                            tmp_array58.push(pointerVue.idPermSel[x58]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array58.push("ore_lul_smartform");


                    }

                    pointerVue.idPermSel = tmp_array58;

                }


                if (v_val == 60) {

                    var tmp_array60 = [];

                    for (var x60 = 0; x60 <= pointerVue.idPermSel.length - 1; x60++) {

                        if ((pointerVue.idPermSel[x60] != "newsletter_jobmule") &&
                            (pointerVue.idPermSel[x60] != "invio_newsletter_jobmule")) {
                            tmp_array60.push(pointerVue.idPermSel[x60]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array60.push("newsletter_jobmule");
                        tmp_array60.push("invio_newsletter_jobmule");


                    }

                    pointerVue.idPermSel = tmp_array60;

                }





                if (v_val == 61) {

                    var tmp_array061 = [];

                    for (var x061 = 0; x061 <= pointerVue.idPermSel.length - 1; x061++) {

                        if ((pointerVue.idPermSel[x061] != "config_orari_smartbook") &&
                            (pointerVue.idPermSel[x061] != "configurazione_smartbook") &&
                            (pointerVue.idPermSel[x061] != "eccezzioni_smartbook") &&
                            (pointerVue.idPermSel[x061] != "mail_gruppi_smartbook") &&
                            (pointerVue.idPermSel[x061] != "medici_smartbook") &&
                            (pointerVue.idPermSel[x061] != "parenti_smartbook") &&
                            (pointerVue.idPermSel[x061] != "slider_smartbook")) {
                            tmp_array061.push(pointerVue.idPermSel[x061]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array061.push("config_orari_smartbook");
                        tmp_array061.push("configurazione_smartbook");
                        tmp_array061.push("eccezzioni_smartbook");
                        tmp_array061.push("mail_gruppi_smartbook");
                        tmp_array061.push("medici_smartbook");
                        tmp_array061.push("parenti_smartbook");
                        tmp_array061.push("slider_smartbook");


                    }

                    pointerVue.idPermSel = tmp_array061;

                }

                if (v_val == 62) {

                    var tmp_array062 = [];

                    for (var x062 = 0; x062 <= pointerVue.idPermSel.length - 1; x062++) {

                        if ((pointerVue.idPermSel[x062] != "conf_prenot_smartbook") &&
                            (pointerVue.idPermSel[x062] != "elenco_prenot_smartbook") &&
                            (pointerVue.idPermSel[x062] != "prenota_smartbook") &&
                            (pointerVue.idPermSel[x062] != "statistiche_smartbook") &&
                            (pointerVue.idPermSel[x062] != "tipologie_smartbook")) {
                            tmp_array062.push(pointerVue.idPermSel[x062]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array062.push("conf_prenot_smartbook");
                        tmp_array062.push("elenco_prenot_smartbook");
                        tmp_array062.push("prenota_smartbook");
                        tmp_array062.push("statistiche_smartbook");
                        tmp_array062.push("tipologie_smartbook");

                    }

                    pointerVue.idPermSel = tmp_array062;

                }

                if (v_val == 63) {

                    var tmp_array063 = [];

                    for (var x063 = 0; x063 <= pointerVue.idPermSel.length - 1; x063++) {

                        if ((pointerVue.idPermSel[x063] != "profilo_smartbook") &&
                            (pointerVue.idPermSel[x063] != "utenti_smartbook")) {
                            tmp_array063.push(pointerVue.idPermSel[x063]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array063.push("profilo_smartbook");
                        tmp_array063.push("utenti_smartbook");


                    }

                    pointerVue.idPermSel = tmp_array063;

                }




                if (v_val == 64) {

                    var tmp_array64 = [];

                    for (var x64 = 0; x64 <= pointerVue.idPermSel.length - 1; x64++) {

                        if ((pointerVue.idPermSel[x64] != "timesheet_fornitori")) {
                            tmp_array64.push(pointerVue.idPermSel[x64]);
                        }

                    }

                    if (v_chk == true) {


                        tmp_array64.push("timesheet_fornitori");

                    }

                    pointerVue.idPermSel = tmp_array64;

                }

                if (v_val == 65) {

                    var tmp_array65 = [];

                    for (var x65 = 0; x65 <= pointerVue.idPermSel.length - 1; x65++) {

                        if ((pointerVue.idPermSel[x65] != "timesheet_clienti")) {
                            tmp_array65.push(pointerVue.idPermSel[x65]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array65.push("timesheet_clienti");

                    }

                    pointerVue.idPermSel = tmp_array65;

                }


                if (v_val == 66) {

                    var tmp_array066 = [];

                    for (var x066 = 0; x066 <= pointerVue.idPermSel.length - 1; x066++) {

                        if ((pointerVue.idPermSel[x066] != "log_notif_sm") &&
                            (pointerVue.idPermSel[x066] != "log_sms_sm")) {
                            tmp_array066.push(pointerVue.idPermSel[x066]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array066.push("log_notif_sm");
                        tmp_array066.push("log_sms_sm");



                    }

                    pointerVue.idPermSel = tmp_array066;

                }


                if (v_val == 67) {

                    var tmp_array67 = [];

                    for (var x67 = 0; x67 <= pointerVue.idPermSel.length - 1; x67++) {

                        if ((pointerVue.idPermSel[x67] != "send_notif_sm")) {
                            tmp_array67.push(pointerVue.idPermSel[x67]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array67.push("send_notif_sm");

                    }

                    pointerVue.idPermSel = tmp_array67;

                }

                if (v_val == 68) {

                    var tmp_array68 = [];

                    for (var x68 = 0; x68 <= pointerVue.idPermSel.length - 1; x68++) {

                        if ((pointerVue.idPermSel[x68] != "send_sms_sm")) {
                            tmp_array68.push(pointerVue.idPermSel[x68]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array68.push("send_sms_sm");

                    }

                    pointerVue.idPermSel = tmp_array68;

                }


                if (v_val == 69) {

                    var tmp_array69 = [];

                    for (var x69 = 0; x69 <= pointerVue.idPermSel.length - 1; x69++) {

                        if ((pointerVue.idPermSel[x69] != "profilo_sm")) {
                            tmp_array69.push(pointerVue.idPermSel[x69]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array69.push("profilo_sm");

                    }

                    pointerVue.idPermSel = tmp_array69;

                }

                if (v_val == 70) {

                    var tmp_array070 = [];

                    for (var x070 = 0; x070 <= pointerVue.idPermSel.length - 1; x070++) {

                        if ((pointerVue.idPermSel[x070] != "corsi_online_corsi") &&
                            (pointerVue.idPermSel[x070] != "corsi_online_flusso") &&
                            (pointerVue.idPermSel[x070] != "corsi_online_conf_domande") &&
                            (pointerVue.idPermSel[x070] != "corsi_online_conf_risposte") &&
                            (pointerVue.idPermSel[x070] != "corsi_online_conf_pesi_risp")) {
                            tmp_array070.push(pointerVue.idPermSel[x070]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array070.push("corsi_online_corsi");
                        tmp_array070.push("corsi_online_flusso");
                        tmp_array070.push("corsi_online_conf_domande");
                        tmp_array070.push("corsi_online_conf_risposte");
                        tmp_array070.push("corsi_online_conf_pesi_risp");

                    }

                    pointerVue.idPermSel = tmp_array070;

                }


                if (v_val == 71) {

                    var tmp_array071 = [];

                    for (var x071 = 0; x071 <= pointerVue.idPermSel.length - 1; x071++) {

                        if ((pointerVue.idPermSel[x071] != "formazione_calendario") &&
                            (pointerVue.idPermSel[x071] != "formazione_corsi") &&
                            (pointerVue.idPermSel[x071] != "formazione_documenti")) {
                            tmp_array071.push(pointerVue.idPermSel[x071]);
                        }

                    }

                    if (v_chk == true) {

                        tmp_array071.push("formazione_calendario");
                        tmp_array071.push("formazione_corsi");
                        tmp_array071.push("formazione_documenti");

                    }

                    pointerVue.idPermSel = tmp_array071;

                }














            });





            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '.selstato', function () {

                console.log(("EVENTO CLICK SELSTATO"));

                pointerVue.sheetProducts = true;

            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.seldata', function (event) {
                console.log(event);


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });

            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.dataDa = pointerVue.dataDa;
                window.dataA = pointerVue.dataA;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();


            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });




        });

        // setTimeout(() => {
        //     this.syncProfile();
        // }, 100);
        // console.log (res);


        setTimeout(() => {

            this.initFilter();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_changestatusadmin":

                    console.log("CURR ITEM: ", this.currItem.stato);

                    this.sheetChangeStatus = true;

                    break;

                case "btn_send_approvation":

                    this.sendInApprovation();

                    break;





                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":

                    console.log("ID: ", btoa("0"))

                    var v_id = btoa("0") + "l";


                    router.push({
                        path: "/editConfigurationProduct/" + v_id
                    });


                    break;

                case "btn_edit":

                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    //console.log("IDS SEL:", ids);

                    // var v_id = btoa(ids) + "l";

                    router.push({
                        path: "/editConfigurationProduct/" + ids
                    });

                    // router.push({
                    //     path: "/editConfigurationProduct/" + v_id
                    // });

                    break;

                case "btn_back":


                    var a = window.$cookies.get("a");
                    var b = window.$cookies.get("b");

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        router.push({
                            path: "/filterHolidayHistory"
                        });


                    }
                    else {

                        router.push({
                            path: "/holiday"
                        });

                    }




                    break;

                case "btn_permissions":

                    this.permissions();

                    break;

                default:
                    break;

            }
        });


        // btn_send_approvation


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));


        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {
        
                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,
        
                    },
                    success: function (dati) {
        
                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();
        
                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        sheetProducts: false,

        idPermROSel: [],

        idPermSel: [],
        idSecSel: [],

        permessi: [],


        prodotti: [],

        textSearch: "",

        dataDa: null,
        showPicker3: false,

        dataA: null,
        showPicker4: false,

        aziende: [],

        search: "",

        sheetAziende: false,

        allStatus: [{
            img: 'keep.png',
            title: 'Refused',
            "id": 1,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'to Approve',
            "id": 2,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Approved',
            "id": 3,
            icon: "mdi-account-lock",
            "color": "green"
        },

        {
            img: 'inbox.png',
            title: 'Open',
            "id": 3,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        ],


        allStatusFilter: [{
            img: '',
            title: 'Tutti',
            "id": 1,
            icon: "mdi-table",
            "color": "black"
        },
        {
            img: 'keep.png',
            title: 'Refused',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'to Approve',
            "id": 3,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Approved',
            "id": 4,
            icon: "mdi-account-lock",
            "color": "green"
        },

        {
            img: 'inbox.png',
            title: 'Open',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        ],




        tiles: [{
            img: 'keep.png',
            title: 'Keep'
        }],



        sheetChangeStatus: false,
        sheetStatus: false,

        currItem: { stato: "" },


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,





    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },

    methods: {


        btnResetSearch: function () {

            window.curSrcText = "";


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },



        changeProd: async function (prodid) {

            console.log(status);

            this.sheetProducts = false;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.curProdId = prodid;

            this.hideShowButtons(false);

            window.table.ajax.reload();


        },



        btnConfirmPermissions: function () {

            this.savePermissions();

        },


        btnCancelPermissions: function () {

            this.$modal.hide('popupPermissions');

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },



        initFilter: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.initFilter(
                v_token
            ).then((res) => {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                console.log("res from initFilter", res);

                that.prodotti = res.data.product_filter;

                window.ddlProducts = res.data.products;

                window.ddlUsers = res.data.users;

                window.ddlGroups = res.data.groups;




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        changeStatus: async function (status) {

            console.log(status);

            this.sheetStatus = false;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.curStatus = status;

            this.hideShowButtons(false);

            window.table.ajax.reload();


        },


        delMsg: function async() {
            var token = window.$cookies.get("token");
            var that = this;

            this.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: "Vuoi eliminare gli elementi selezionati ? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        //start request
                        var response;

                        try {

                            //  that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            response = await that.$api.deleteMultiFerie(
                                ids,
                                token

                            ).then((res) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from deleteMultiFerie", res);

                                that.$swal({
                                    icon: "success",
                                    text: "La tua richiesta è stata rimossa correttamente",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                                console.log("response", response);

                            }

                            );


                        } catch (error) {
                            that.$root.$children[0].showProgress = false;
                            console.log(error);

                        }
                        console.log(response);


                    }
                });



        },



        changeStatusAdmin: async function (status) {

            //  this.currFilterStatus = status;

            console.log(status);


            //START
            var token = window.$cookies.get("token");

            /*   var username = window.$cookies.get("username");
              var token_system = window.$cookies.get("token_system"); */
            //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";

            //var API_URL = "https://app.emtool.eu/api/acceptRejectHolidaysPermits";

            var API_URL = this.getUrlDomainApi("acceptRejectMultiHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            var operation = "";
            if (status == "Refused") operation = 'reject';
            if (status == "to Approve") operation = 'to Approve';
            if (status == "Approved") operation = 'accept';
            if (status == "Open") operation = 'open';

            console.log("STATUS: ", status);
            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("OPERATION: ", operation);
            console.log("ID_RICHIESTA: ", ids);

            var that = this;
            that.$root.$children[0].showProgress = true;

            /* eslint-disable no-unreachable */
            /*    return; */
            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,
                    'operation': operation,
                },
                success: function (resultData) {

                    that.$root.$children[0].showProgress = false;

                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    that.sheetChangeStatus = false;

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                },
                error: function (errorData) {

                    that.sheetChangeStatus = false;

                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.showSpinner = false;

                }
            });
            //END

        },



        sendInApprovation: function () {


            var that = this;
            var token = window.$cookies.get("token");

            var API_URL = this.getUrlDomain("sendMultiRequestHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);




            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("id_richiesta: ", ids);

            that.$root.$children[0].showProgress = true;

            $.ajax({
                type: "GET",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,

                },
                success: function (resultData) {
                    that.$root.$children[0].showProgress = false;
                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    that.hideShowButtons(false);

                    window.table.ajax.reload();


                },
                error: function (errorData) {
                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.$root.$children[0].showProgress = false;

                }
            });


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;

            // var a = window.$cookies.get("a");
            // var b = window.$cookies.get("b");

            var filtri = `<img src='/emt/img/Filtro.png' style='border:0!important;' class='selstato' title='Prodotto' />`;


            // var filtri = ``;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                // if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                //     window.curCompany = window.$cookies.get("sel_filter_azienda_storico_ferie");


                // }



                // window.curCompany = v_company;
                // window.curStatus = "S"; // in forza

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'product_name',
                        orderable: true,
                        name: 'product_name',
                        width: '3%'
                    },

                    {
                        data: 'desc_user_group',
                        orderable: true,
                        name: 'desc_user_group',
                        width: '3%'
                    },

                    {
                        data: 'tipo',
                        orderable: true,
                        name: 'tipo',
                        width: '3%'
                    },

                    {
                        data: 'can_view',
                        orderable: false,
                        name: 'can_view',
                        width: '3%'
                    },



                ];


                $(".spin").show();




                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtGetProdConfigurationsApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.dataDa = window.dataDa;
                            valori.dataA = window.dataA;

                            valori.curSrcText = window.curSrcText;

                            valori.curProdId = window.curProdId;


                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);






                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();


                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtGetProdConfigurationsApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    // $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Configurazione Prodotti</div>
                       ` + filtri + `

                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>
            `);




                $(".cl-sec-checkbox").on("click", function (opt) {
                    console.log(opt);


                    // console.log("VAL SEL SEC", this.value);
                    // console.log("VAL ID SEL SEC", this.idSecSel);




                });




                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                $('body').on('click', '.btn-confirm-search', function () {

                    console.log("ok");

                    window.curSrcText = pointerVue.textSearch;

                    pointerVue.$modal.hide('popupSearch');

                    window.table.ajax.reload();


                });

                $('body').on('click', '.btn-cancel-search', function () {

                    console.log("ok");

                    pointerVue.$modal.hide('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });


                $('body').on('click', '.cont_products_configuration tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }



                });


                window.parentiAddedEvents = true;

            });

        },


        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push({
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30
                    });


                }


                if (tot == 1) {

                    pulsantis.push({
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        title: "Modifica",
                        width: 30
                    });

                    pulsantis.push({
                        text: "Permissions",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/permissions-emt.png",
                        link: "/info",
                        id: "btn_permissions",
                        disabled: false,
                        title: "Abilitazione Permessi",
                        width: 27
                    });

                }

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    });

                }




                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },






        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30

                        },

                        {
                            text: "Indietro",
                            icon: "mdi-arrow-left",
                            image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                            link: "/confOrariChoice",
                            id: "btn_back",
                            disabled: false,
                            title: "Indietro",
                            width: 35
                        },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
    ,
    */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        savePermissions: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$modal.hide('popupPermissions');

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            console.log("IDS PERM SEL:", that.idPermSel);

            var strPermessi = "";

            for (var x = 0; x <= that.idPermSel.length - 1; x++) {
                if (strPermessi != "") {
                    strPermessi = strPermessi + "|";
                }
                strPermessi = strPermessi + that.idPermSel[x];
            }

            console.log("IDS PERM SEL STR:", strPermessi);





            var strPermessiDetails = "";

            for (var xx = 0; xx <= that.idPermROSel.length - 1; xx++) {
                if (strPermessiDetails != "") {
                    strPermessiDetails = strPermessiDetails + "|";
                }
                strPermessiDetails = strPermessiDetails + that.idPermROSel[xx];
            }

            console.log("IDS PERM RO SEL STR:", strPermessiDetails);



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.updatePermissions(
                ids,
                strPermessi,
                v_token,
                strPermessiDetails
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updatePermissions", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                that.hideShowButtons(false);

                window.table.ajax.reload();

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        savePermissionsOld: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$modal.hide('popupPermissions');

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            console.log("IDS PERM SEL:", that.idPermSel);

            var strPermessi = "";

            for (var x = 0; x <= that.idPermSel.length - 1; x++) {
                if (strPermessi != "") {
                    strPermessi = strPermessi + "|";
                }
                strPermessi = strPermessi + that.idPermSel[x];
            }

            console.log("IDS PERM SEL STR:", strPermessi);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.updatePermissions(
                ids,
                strPermessi,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updatePermissions", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                that.hideShowButtons(false);

                window.table.ajax.reload();

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        permissions: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.getAllPermissionsForProd(
                ids,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAllPermissionsForProd", res);



                that.permessi = res.data.result;

                setTimeout(() => {

                    $(".contpopuppermissions").show();

                    that.$modal.show('popupPermissions');


                    setTimeout(() => {

                        if (res.data.perm_selected.permissions != null) {

                            var elements = res.data.perm_selected.permissions.split("|");

                            console.log("ELE SEL: ", elements);

                            that.idPermSel = elements;

                        }


                        if (res.data.sec_selected != null) {

                            var elements2 = res.data.sec_selected.split("|");

                            console.log("SEC SEL: ", elements2);

                            that.idSecSel = elements2;

                        }



                        if (res.data.perm_selected.permissions_details != null) {

                            var elements_details = res.data.perm_selected.permissions_details.split("|");

                            console.log("ELE RO SEL: ", elements_details);

                            that.idPermROSel = elements_details;

                        }







                    }, 200);




                }, 300);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiconfigurationproduct.deleteConf(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteConf", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_products_configuration #myTable {
    margin-right: 3px;
}

.cont_products_configuration table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_products_configuration table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 9px !important;
}

.cont_products_configuration th {
    padding-left: 5px !important;
}

.cont_products_configuration ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_products_configuration .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}


.cont_products_configuration #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_products_configuration div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

/* .cont_products_configuration #myTable_wrapper {
    padding-top: 0px;
} */

.cont_products_configuration #myTable_wrapper {
    min-width: initial;
    min-width: 600px;
    max-width: 600px;
    width: 600px;
}


.cont_products_configuration .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_products_configuration .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_products_configuration .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}


.cont_products_configuration .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.closeStatusSheet {
    position: absolute;
    right: 0;
}

.cont_products_configuration .selstato {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}


/* .cont_products_configuration #myTable {
    max-width: 95vw !important;
    margin-right: 0px;
    min-width: 95vw !important;
    position: relative;
    width: 95vw !important;

} */

.cont_products_configuration #myTable {
    margin-right: 3px;
    width: 500px !important;
}


.cont_products_configuration .selazienda {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.cont_products_configuration .clDataDa {
    font-weight: bold;
    transform: translate(6px, -12px);
}

.cont_products_configuration .clValueDataDa {
    font-weight: bold;
    transform: translate(11px, -11px);
}

.cont_products_configuration .seldata {
    max-width: 22px;
    min-height: 22px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: --2px;
}


.cont_products_configuration .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_products_configuration .checkall {
    width: 13px;
    height: 13px;
}


.cont_products_configuration .cl-title-center {
    text-align: center;
}

.cont_products_configuration .cl-text-center {
    text-align: center;
}

.cont_products_configuration .cl-data-inizio {
    width: 60px !important;
}

.cont_products_configuration .cl-data-fine {
    width: 60px !important;
}

.cont_products_configuration input[type='search'] {
    visibility: hidden;
}

.cont_products_configuration .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_products_configuration .val_status {
    width: 10px !important;
}


.contpopuppermissions .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopuppermissions .cl-sec-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contpopuppermissions .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}

.contCheck {
    align-items: top;
}

.contSecCheck {
    align-items: top;
}

.contpopuppermissions .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0px !important;
    transform: translateY(-13px) !important;
}


@media screen and (max-width: 960px) {

    .cl_note {
        display: inline;
    }

    .cont_products_configuration .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_products_configuration #myTable {
        /* margin-top:54px; */
    }

    .cont_products_configuration #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_products_configuration #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_products_configuration #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_products_configuration .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_products_configuration #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_products_configuration .cl_num {
        margin-left: -2px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_products_configuration .cl_checkall {
        padding-left: 1px;
        padding-top: 2px;
    }

    .cont_products_configuration .cl-data-inizio {
        width: 58px !important;
    }

    .cont_products_configuration .cl-text-center {
        text-align: center;
        width: 39px !important;
    }

    .cont_products_configuration .cl-data-fine {
        width: 58px !important;
    }

}
</style>
