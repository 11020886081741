<template>
    <v-container class="w-container v-overflow editCorsoRisorsa" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle">
                    <div>{{ titolo }}</div>

                    <div class="fullNameRes">{{ fullNameRes }}</div>

                </div>



            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field v-model="emt_corsi_risorse.nome_corso" label="Nome" id="nome"></v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="emt_corsi_risorse.data_corso" label="Data" persistent-hint readonly
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="emt_corsi_risorse.data_corso" no-title @input="showPicker4 = false"
                            locale="it"></v-date-picker>
                    </v-menu>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" style="text-align: center;">

                    <button type="button" id="upload-button" class="buttonFile" value="uplFile" @click="openPopupUpload()">

                        <div class="containButtonFile">

                            <div class="button--text">{{ testoPulsante }}</div>

                            <div style="margin-left:10px;">
                                <v-img src="@/assets/upload_file.png" max-width="20" max-height="20" />
                            </div>


                        </div>





                    </button>


                </v-col>

            </v-row>



        </v-form>


        <div class="contpopupuploadfile">

            <template>
                <modal name="popupUploadFile" :clickToClose="false" :width="386" :height="339">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:11px;margin-left:8px;font-weight: bold;">{{ titoloPopup }}</label>


                        </v-col>

                    </v-row>

                    <v-row class="rowPrevFile">

                        <v-col cols="12" md="12" style="margin-left: 12px;">


                            <span class="files">
                                <input type="file" id="input-file-attach" name="logo" :accept="fileAccettati"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>


                        </v-col>

                    </v-row>


                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -23px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">File:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ nomeFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="margin-top: -7px!important;display:flex">

                            <label style="font-size:11px;margin-left:12px;font-weight: bold;">Dim:</label>

                            <div style="margin-left: 10px;font-size:11px;font-weight: bold;">{{ dimFilePresente }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelUpload" />

                                <v-img alt="" class="btn-confirm-upload" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmUpload" />


                            </div>

                        </v-col>

                    </v-row>



                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apirisorse from "../utils/risorse/apirisorse";
import apiuploadfile from "../utils/uploadfile/apiuploadfile";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();



        this.fullNameRes = window.$cookies.get("sel_name_resource_corsi");


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    router.push({
                        path: "/corsiRisorse"
                    });
                    break;

                default:
                    break;
            }
        });


        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Corso";

            this.editCorseRisorsa();



        }



        setTimeout(() => {

            this.deleteAttachment();

        }, 100);


        this.setupButtons();

    },




    data: () => ({

        // parametri funzione upload
        testoPulsante: "Allegato",
        titoloPopup: "Seleziona Allegato",
        fileAccettati: ".pdf",
        nomeFilePresente: "",
        dimFilePresente: "",
        dimKbFilePresente: 0,
        dimMinKbFile: 100000,
        dimMaxKbFile: 5000000,
        folderServerToSave: "/documents/emtool/Corsi/",
        folderDoxToSave: "/Corsi/",
        removeUploadFile: 0,
        attachRemoveVisura: false,
        fileSpecificato: false,






        panel: 0,


        sheetRegioniRes: false,
        searchRegioniRes: "",

        sheetProvinceRes: false,
        searchProvinceRes: "",

        sheetComuniRes: false,
        searchComuniRes: "",




        sheetRegioniDom: false,
        searchRegioniDom: "",

        sheetProvinceDom: false,
        searchProvinceDom: "",

        sheetComuniDom: false,
        searchComuniDom: "",








        regioniRes: [],
        provinceRes: [],
        provinceResTemp: [],
        comuniRes: [],
        comuniResTemp: [],

        regioniDom: [],
        provinceDom: [],
        provinceDomTemp: [],
        comuniDom: [],
        comuniDomTemp: [],



        fullNameRes: "",

        valoreRepGiorn: null,

        valoreGiorniProva: null,

        viewValImpLordoGiornRep: false,

        readOnlyFieldsFromUser: false,


        viewField: true,


        viewValTicket: false,

        valCheckCable: "N",
        valCheckDesktop: "N",
        valCheckNotebook: "N",
        valCheckSoftwareLicence: "N",
        valCheckSmartphone: "N",
        valCheckSimAndNumber: "N",
        valCheckVeicoloAziendale: "N",
        valCheckAltro: "N",

        valoreFerieAP: null,
        valoreFerieAC: null,
        valorePermessiAP: null,
        valorePermessiAC: null,
        valoreProroga: null,
        valoreLordoMese: null,
        valoreDiaria: null,
        valoreTariffaNetta: null,
        valoreCostGiorn: null,
        valoreEbadgePausa: null,
        valorePenalitaIntervallo: null,
        valorePenalitaMM: null,

        valoreTicket: null,

        showPicker3: false,
        showPicker4: false,
        showPicker5: false,
        showPicker6: false,
        showPicker7: false,

        showRow1: false,
        showRow2: false,
        showRow3: false,
        showRow4: false,
        showRow5: false,
        showRow6: false,
        showRow7: false,
        showRow8: false,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        emt_corsi_risorse: { resourceid: "", nome_corso: "", data_corso: "", nome_file_allegato_corso: "" },


        dipendenti: [],
        tipidocumento: [],
        tipolaurea: [],
        contratti: [],
        interviste: [],
        tiporiscontro: [],
        tipocontratto: [],
        sedelavorativa: [],
        qualifica: [],
        ebadgetiporegistrazione: [],
        valoripianoorario: [],

        genere: [{
            id: "M",
            description: "Maschio"
        }, {
            id: "F",
            description: "Femmina"
        }],

        situazionefamigliare: [{
            id: "Celibe / Nubile",
            description: "Celibe / Nubile"
        }, {
            id: "Coniugato",
            description: "Coniugato"
        }, {
            id: "Separato",
            description: "Separato"
        }],

        itemPatenteGuida: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        itemAutomunito: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ore: [],

        tipisgravi: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        veicoloaziendale: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        contrattoitaliano: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        valoresino: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],



        ticket: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        abilitaebadge: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        vedistraordinari: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeregtypeqr: [{
            id: 1,
            description: "Si"
        }, {
            id: 0,
            description: "No"
        }],

        ebadgeregtypeman: [{
            id: 1,
            description: "Si"
        }, {
            id: 0,
            description: "No"
        }],

        ebadgeregtyperec: [{
            id: 1,
            description: "Si"
        }, {
            id: 0,
            description: "No"
        }],

        ebadgearrotondamento: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeverificaip: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        ebadgeinviamail: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        progetticaricaore: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],

        enablepanalita: [{
            id: "S",
            description: "Si"
        }, {
            id: "N",
            description: "No"
        }],



        currAzienda: "",
        titolo: "Aggiungi Corso",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetDipartimento: false,
        searchDipartimento: "",

        sheetTipologie: false,
        searchTipologie: "",

        sheetAziendeClienti: false,
        searchAziendeClienti: "",

        sheetUtentiAssociati: false,
        searchUtentiAssociati: "",

        sheetPianoOrario: false,
        searchPianoOrario: "",


        sheetClienti: false,
        searchClienti: "",

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],
        dipartimenti: [],
        dipartimentiTemp: [],

        aziendeclienti: [],
        aziendeclientiTemp: [],

        utentiassociati: [],
        utentiassociatiTemp: [],

        sediassunzioneTemp: [],
        sediassunzione: [],

        pianiorarioTemp: [],
        pianiorario: [],

    }),

    methods: {


        btnCancelUpload: function () {

            this.$modal.hide('popupUploadFile');

        },


        btnConfirmUpload: function () {

            var errore = "";

            var v_file = $("#input-file-attach")[0];

            if (this.nomeFilePresente == "") {

                errore = "Specificare un file";
            }

            if (errore == "") {

                if (v_file.files.length > 0) {



                    // controllo estensione

                    v_file = v_file.files[0];

                    console.log("FILE 1: ", v_file);

                    var v_elements = v_file.name.split('.');

                    var v_ext_file = v_elements[v_elements.length - 1];

                    console.log("FILE ELEMENTS: ", v_elements);
                    console.log("FILE EXT: ", v_ext_file);


                    var v_ele_file_accept = this.fileAccettati.split(',');

                    console.log("FILE ELEMENTS ACCEPT: ", v_ele_file_accept);

                    var v_find_ext = 0;

                    for (var xx = 0; xx < v_ele_file_accept.length; xx++) {

                        var v_ext_file_tmp = "." + v_ext_file;

                        console.log(v_ext_file_tmp);

                        if (v_ext_file_tmp == v_ele_file_accept[xx]) {
                            v_find_ext = 1;
                        }

                    }

                    if (v_find_ext == 0) {
                        errore = "I formati accettati sono " + this.fileAccettati;
                    }



                    if (errore == "") {

                        // controllo dimensione file

                        if ((this.dimKbFilePresente < this.dimMinKbFile) || (this.dimKbFilePresente > this.dimMaxKbFile)) {


                            var strMin = (this.dimMinKbFile / 1000) + " Kb";

                            var strMax = (this.dimMaxKbFile / 1000000) + " Mb";

                            errore = "La grandezza del file deve essere compresa tra " + strMin + " e " + strMax;

                        }


                    }






                }

            }






            if (errore == "") {

                console.log("ok");

                this.uploadFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3500
                });


            }


        },


        editCorseRisorsa: async function () {

            var that = this;




            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response3 = await apirisorse.editCorseRisorsa(
                that.$route.params.id,
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from editCorseRisorsa", res2);

                that.emt_corsi_risorse = res2.data.Result;

                that.fileSpecificato = true;


            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err2);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },


        deleteAttachment: async function () {

            var that = this;


            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;




            var response3 = await apiuploadfile.deleteAttachCorsiRes(
                v_token,
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from setFileCorsiRisorse", res2);


            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },




        uploadFile: async function () {

            var that = this;

            var id_rec = that.$route.params.id;


            var v_file = $("#input-file-attach")[0].files[0];

            that.$modal.hide('popupUploadFile');


            console.log("ID_REC: ", id_rec);
            console.log("FILE: ", v_file);
            console.log("FOLDER SERVER: ", that.folderServerToSave);
            console.log("FOLDER DOX: ", that.folderDoxToSave);

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;




            var response3 = await apiuploadfile.setFileCorsiRisorse(
                id_rec,
                v_file,
                that.folderServerToSave,
                that.folderDoxToSave,
                "emt_corsi_risorse",
                v_token,
                that.removeUploadFile,
                that.emt_corsi_risorse.nome_corso,
                that.emt_corsi_risorse.data_corso,
                window.$cookies.get("sel_id_resource_corsi"),
                window.$cookies.get("sel_name_resource_corsi")
            ).then((res2) => {

                console.log(response3);

                that.$root.$children[0].showProgress = false;
                console.log("res from setFileCorsiRisorse", res2);

                that.fileSpecificato = true;

                that.$swal({
                    icon: "success",
                    text: "File caricato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



            }).catch(err2 => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err2.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );





        },


        openPopupUpload: async function () {

            var that = this;

            console.log("CORSO: ", that.emt_corsi_risorse);

            that.nomeFilePresente = "";
            that.dimFilePresente = "";
            that.dimKbFilePresente = 0;

            var id_rec = that.$route.params.id;

            var errore = "";

            if (that.emt_corsi_risorse.nome_corso == "") {
                errore = "Specificare il nome del corso";
            }

            if (errore == "") {

                if (that.emt_corsi_risorse.data_corso == "") {
                    errore = "Specificare la data del corso";
                }

            }

            if (errore == "") {

                var v_token = window.$cookies.get("token");


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response3 = await apiuploadfile.getFile(
                    id_rec,
                    "emt_corsi_risorse",
                    that.folderServerToSave,
                    v_token
                ).then((res2) => {

                    console.log(response3);

                    that.$root.$children[0].showProgress = false;
                    console.log("res from getFile", res2);


                    that.dimMinKbFile = res2.data.MinFileSize;

                    that.dimMaxKbFile = res2.data.MaxFileSize;


                    $(".contpopupuploadfile").show();

                    this.$modal.show('popupUploadFile');


                    setTimeout(() => {


                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");

                        oggDrop.on('dropify.afterClear', function (event, element) {

                            console.log(event, element);

                            that.removeUploadFile = 1;

                            that.nomeFilePresente = "";
                            that.dimFilePresente = "";
                            that.dimKbFilePresente = 0;

                        });



                        $('#input-file-attach').on('change', function () {

                            that.removeUploadFile = 0;

                            var v_file = $("#input-file-attach")[0].files[0];

                            console.log("FILE: ", v_file);

                            that.nomeFilePresente = v_file.name;

                            var v_size = v_file.size;

                            that.dimKbFilePresente = v_size;

                            var str_size = "0 Kb";


                            if (v_size > 0) {

                                var v_division = v_size / 1000;

                                str_size = v_division.toFixed(1) + " Kb";


                            }



                            that.dimFilePresente = str_size;



                        });



                        console.log("FILE PRESENT: ", res2.data.NomeFile);

                        if (res2.data.NomeFile != null) {

                            if (res2.data.NomeFile != "") {

                                var elements = res2.data.NomeFile.split(".");

                                console.log("ELEMENTS: ", elements);

                                var v_ext = elements[elements.length - 1];

                                console.log("EXT: ", v_ext);

                                $(".rowPrevFile").find('.dropify-render').append("<i class='dropify-font-file'></i>");
                                $(".rowPrevFile").find('.dropify-render').append("<span class='dropify-extension'>" + v_ext + "</span>");
                                $(".rowPrevFile").find(".dropify-wrapper").addClass("has-preview");
                                $(".rowPrevFile").find(".dropify-preview").show("slow");



                                that.nomeFilePresente = res2.data.NomeFile;

                                that.dimKbFilePresente = res2.data.FileSize;


                                var str_size = "0 Kb";

                                var v_size = res2.data.FileSize;

                                if (v_size > 0) {

                                    var v_division = v_size / 1000;

                                    str_size = v_division.toFixed(1) + " Kb";


                                }



                                that.dimFilePresente = str_size;

                            }


                        }








                    }, 300);



                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err2.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }


                );

            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [


                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30

                    },

                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 35
                    },




                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";

            if (that.fileSpecificato == false) {
                errore = "Specificare allegato";
            }



            if (errore == "") {


                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },



        saveData: async function () {


            var that = this;


            var v_token = window.$cookies.get("token");

            var id_rec = that.$route.params.id;

            that.emt_corsi_risorse.resourceid = window.$cookies.get("sel_id_resource_corsi");


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apirisorse.setCorseRisorsa(
                id_rec,
                that.emt_corsi_risorse,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setCorseRisorsa", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/corsiRisorse"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );

        },


    }

})
</script>

<style>
.editCorsoRisorsa .dropify-wrapper {
    height: 187px !important;
    width: 187px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}

.editCorsoRisorsa .rowPrevFile .dropify-wrapper {
    height: 187px !important;
    width: 363px !important;
    margin-bottom: 20px;
    margin-left: 1px;
}



.buttonFile {
    border: 1px solid black;
    padding: 10px;
    width: 150px;
}

.containButtonFile {
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}


.copyAddress {
    cursor: pointer;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}


.itemRegione {
    margin-left: 15px;
}

.itemProvincia {
    margin-left: 15px;
}

.itemComune {
    margin-left: 15px;
}


.headerSheetRegioni {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetProvince {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetComuni {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.editCorsoRisorsa .fullNameRes {
    background-color: white;
    color: black;
    font-weight: bold;
    font-size: 10px;
    margin-left: 40px;
}

.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.editCorsoRisorsa .mainTitle {
    padding: 20px !important;
    position: fixed;
    z-index: 1;
    top: 65px;
    background-color: white;
    color: black;
    font-weight: bold;
    font-size: 10px;
    min-width: 100vw;
    border-color: #f5cf2e;
    display: flex;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetAziendeClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetDipartimento {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetUtentiAssociati {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPianoOrario {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemAziendaCliente {
    margin-left: 15px;
}

.itemCliente {
    margin-left: 15px;
}

.itemDipartimento {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.itemUtenteAssociato {
    margin-left: 15px;
}


.itemPianoOrarioCodice {
    margin-left: 15px;
}

.itemPianoOrarioDescrizione {
    margin-left: 15px;
}



.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.editCorsoRisorsa .v-label {
    font-size: 1.2em !important
}

.editCorsoRisorsa .v-input {
    font-size: 10px !important;
    padding: 10px !important;
}

.editCorsoRisorsa ion-select {
    font-size: 10px !important;
}



.cont_piano_orario #myTablePianoOrario {
    margin-right: 3px;
}

.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_piano_orario table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_piano_orario th {
    padding-left: 5px !important;
}

.cont_piano_orario ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_piano_orario .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_piano_orario #myTablePianoOrario_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_piano_orario div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_piano_orario #myTablePianoOrario_wrapper {
    padding-top: 0px;
}

.text_title {
    background-color: #f5cf2e;
    color: white;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 82vw
}

.cont_piano_orario .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.first_column_piano_orario {
    width: 4%;
    text-align: left;
}

.second_column_piano_orario {
    width: 32%;
    text-align: left;
    font-weight: bold;
    font-size: 10px;
}

.third_column_piano_orario {
    width: 32%;
    text-align: left;
    margin-left: -6px;
    font-weight: bold;
    font-size: 10px;
}

.fourth_column_piano_orario {
    margin-left: -5px;
    font-weight: bold;
    font-size: 10px;
}

.cl-del-date-nascita {
    width: 15px;
    transform: translate(413px, -54px);
    cursor: pointer;
}

.cl-del-inizio-rapporto {
    width: 15px;
    cursor: pointer;
    transform: translate(415px, -54px);
}

.cl-del-fine-rapporto {
    width: 15px;
    cursor: pointer;
    transform: translate(415px, -54px);
}


.height-panel-asset {
    height: 381px !important
}


@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .editCorsoRisorsa .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .cont_piano_orario .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_piano_orario #myTablePianoOrario {
        /* margin-top:54px; */
    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }


    .first_column_piano_orario {
        width: 19%;
        text-align: left;
    }

    .second_column_piano_orario {
        width: 28%;
        text-align: left;
        font-weight: bold;
        font-size: 10px;
    }

    .third_column_piano_orario {
        width: 28%;
        text-align: left;
        margin-left: -6px;
        font-weight: bold;
        font-size: 10px;
    }

    .fourth_column_piano_orario {
        margin-left: -5px;
        font-weight: bold;
        font-size: 10px;
    }


    .cl-del-date {
        transform: translate(286px, -56px);
    }

    .height-panel-asset {
        height: 381px !important
    }



}


@media screen and (max-width: 1024px) {


    .cont_piano_orario #myTablePianoOrario {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_piano_orario #myTablePianoOrario_filter {
        top: 56px;
        position: fixed;

    }

    .cont_piano_orario .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_piano_orario #myTablePianoOrario_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_piano_orario .cl_num {
        margin-left: 16px !important;
    }





}
</style>
