import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICespiti {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initInsert(user, id_cmp) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_cmp", id_cmp);

    return axios.post(this.getUrlDomain("initInsertConfSmtp"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async saveConf(v_conf, v_id)
  {

    let formData = new FormData();


    formData.append("v_id",  v_id);

    for ( var key in v_conf ) {
      formData.append(key, v_conf[key]);
   }


    return axios.post(this.getUrlDomain("saveConfSmtp"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async initEdit(id_asset, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id_asset);

    return axios.post(this.getUrlDomain("initEditConfSmtp"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteConnf(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("deleteConfSmtp"), formData, { 'Content-Type': 'multipart/form-data' });

  }













  async insertCespiti(cespiti, user, file)
  {

    let formData = new FormData();


    formData.append("username",  user);

    if (file && (file != undefined))
    {

        formData.append( "attachment" ,  file[0] );

    }    

    for ( var key in cespiti ) {
      formData.append(key, cespiti[key]);
   }


    return axios.post(this.getUrlDomain("EmtInsertCespitiAdminApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getOfficeRes(user, id_res) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_resource_id", id_res);

    return axios.post(this.getUrlDomain("EmtCespitiGetOfficeResAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async updateCespiti(cespiti, user, file, attach_remove, id)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("attach_remove",  attach_remove);
    formData.append("id",  id);

    if (file && (file != undefined))
    {

        formData.append( "attachment" ,  file[0] );

    }    

    for ( var key in cespiti ) {
      formData.append(key, cespiti[key]);
   }


    return axios.post(this.getUrlDomain("EmtUpdateCespitiAdminApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteCespiti(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("EmtDeleteCespitiAdminApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async mandaInvito(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("EmtSendInviteCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async downAttach(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtDownAllegatoCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APICespiti({
  url: "https://services.ebadge.it/public/api/"
})
