import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

function getUrlDomainGlobal(nome_metodo)
{

  let result = "";

  if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
  }

  if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

  }

  return result + nome_metodo;


}

var main_domain = getUrlDomainGlobal("");

class APIDisease {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async getHolPermReq(user)
  {

    let formData = new FormData();

    formData.append("username",  user);
   
    return axios.post(this.getUrlDomain("EmtGetVoicesReqHolidayApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getEditDisease(user, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
   
    return axios.post(this.getUrlDomain("EmtGetEditDiseaseApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async initInsertDisease(user, companyid)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("companyid",  companyid);
   
    return axios.post(this.getUrlDomain("initInsertDisease") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getEditAdminDisease(user, id, companyid)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("companyid",  companyid);
   
    return axios.post(this.getUrlDomain("EmtGetEditAdminDiseaseApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APIDisease({
  url: main_domain
})
