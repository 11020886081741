<template>
    <v-container class="w-container-mod-fatt v-overflow edit_mod_fatt_agila" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <div class="noDevMobile">


                <v-row>

                    <v-col cols="6" md="6">

                        <div id="cont_tipo_progetto_id" style="cursor:pointer">
                            <ion-item id="itemTipoProgetto">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Tipo</ion-label>

                                <ion-select id="tipo" name="tipo" class='tipo' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.tipo" interface="action-sheet">

                                    <ion-select-option v-for="item in tipi" :key="item.id" :value='item.id'> {{
                                            item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>


                    <v-col cols="6" md="6" v-if="showCol9">

                        <div id="cont_intmand" style="cursor:pointer">
                            <ion-item id="itemItemMand">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Intestatario Mandato</ion-label>

                                <ion-select id="intestatario_mandato" name="intestatario_mandato"
                                    class='intestatario_mandato' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.intestatario_mandato" interface="action-sheet">

                                    <ion-select-option v-for="item in intestatari_mandato" :key="item.id"
                                        :value='item.id'>
                                        {{
                                                item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="6" md="6" v-if="showCol1">

                        <div id="cont_companyid" style="cursor:pointer">
                            <ion-item id="itemCompany"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Cliente </ion-label>

                                <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                    done-Text="" :value="controllo.companyid" interface="action-sheet" disabled
                                    readonly>

                                    <ion-select-option v-for="item in aziende" :key="item.companyid"
                                        :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>


                    <v-col cols="6" md="6" v-if="showCol2">

                        <div id="cont_praticaid" style="cursor:pointer">
                            <ion-item id="itemTipoProgetto" style="margin-top:-24px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Pratica</ion-label>

                                <ion-select id="praticaid" name="praticaid" class='praticaid' cancel-Text="Chiudi"
                                    done-Text="" :value="controllo.praticaid" interface="action-sheet">

                                    <ion-select-option v-for="item in pratiche" :key="item.id" :value='item.id'> {{
                                            item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                </v-row>


                <v-row>

                    <v-col cols="6" md="6" v-if="showCol3">

                        <v-text-field id="numero" v-model="controllo.numero" label="Numero" readonly></v-text-field>

                    </v-col>

                    <v-col cols="6" md="6" v-if="showCol4">

                        <div id="cont_iddescrizione" style="cursor:pointer">
                            <ion-item id="itemDescrizione" style="margin-top:-11px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Descrizione</ion-label>

                                <ion-select id="id_descrizione" name="id_descrizione" class='id_descrizione'
                                    cancel-Text="Chiudi" done-Text="" :value="controllo.id_descrizione"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in descrizioni" :key="item.id" :value='item.id'> {{
                                            item.descrizione
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row v-if="showRow1">

                    <v-col cols="6" md="6">

                        <div id="d_valore_fisso"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Valore Fisso</label>

                                        <vue-autonumeric v-model="controllo.valore_fisso" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>


                <v-row v-if="showRow2">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Contributo Pratica
                        </div>
                    </div>


                </v-row>

                <v-row v-if="showRow3">

                    <v-col cols="6" md="6">

                        <div id="d_contr_pratica"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.contributo_pratica" :disabled="true"
                                            :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>


                    <v-col cols="6" md="6">

                        <div id="cont_perccontr" style="cursor:pointer">
                            <ion-item id="itemPercContr" style="margin-top:-11px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Percentuale</ion-label>

                                <ion-select id="percentuale_contributo_pratica" name="percentuale_contributo_pratica"
                                    class='percentuale_contributo_pratica' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.percentuale_contributo_pratica" interface="action-sheet">

                                    <ion-select-option v-for="item in percentuale" :key="item.id" :value='item.id'> {{
                                            item.valore
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row v-if="showRow4">

                    <v-col cols="6" md="6">

                        <div id="d_contr_pratica_val_calc"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Valore Calcolato</label>

                                        <vue-autonumeric v-model="controllo.importo_calcolato_contributo_pratica"
                                            :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>



                <v-row v-if="showRow5">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Investimento Pratica
                        </div>
                    </div>


                </v-row>

                <v-row v-if="showRow6">

                    <v-col cols="6" md="6">

                        <div id="d_inv_pratica"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.investimento_pratica" :disabled="true"
                                            :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>


                    <v-col cols="6" md="6">

                        <div id="cont_percinv" style="cursor:pointer">
                            <ion-item id="itemPercInv" style="margin-top:-11px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Percentuale</ion-label>

                                <ion-select id="percentuale_investimento_pratica"
                                    name="percentuale_investimento_pratica" class='percentuale_investimento_pratica'
                                    cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.percentuale_investimento_pratica" interface="action-sheet">

                                    <ion-select-option v-for="item in percentuale" :key="item.id" :value='item.id'> {{
                                            item.valore
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row v-if="showRow7">

                    <v-col cols="6" md="6">

                        <div id="d_inv_pratica_val_calc"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Valore Calcolato</label>

                                        <vue-autonumeric v-model="controllo.importo_calcolato_investimento_pratica"
                                            :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>


                <v-row v-if="showRow8">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            &nbsp;
                        </div>
                    </div>


                </v-row>

                <v-row>

                    <v-col cols="6" md="6" v-if="showCol5">

                        <div id="d_previsionale"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.importo_cert" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>


                    <v-col cols="6" md="6" v-if="showCol6">
                        &nbsp;
                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="6" md="6" v-if="showCol7">

                        <div id="cont_dafatt" style="cursor:pointer">
                            <ion-item id="itemDaFatt" style="margin-top:-24px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Da Fatturare</ion-label>

                                <ion-select id="status" name="status" class='status' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.status" interface="action-sheet">

                                    <ion-select-option v-for="item in valori_sino" :key="item.id" :value='item.id'> {{
                                            item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                    <v-col cols="6" md="6" v-if="showCol8">

                        <div id="cont_idresources_group_name" style="cursor:pointer">
                            <ion-item id="itemGruppo" style="margin-top:-25px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Gruppo </ion-label>

                                <ion-select id="idresources_group_name" name="idresources_group_name"
                                    class='idresources_group_name' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.idresources_group_name" interface="action-sheet">

                                    <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name"
                                        :value='item.idresources_group_name'> {{
                                                item.name
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                </v-row>



                <v-row v-if="showRow9">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Previsionale Pratica
                        </div>
                    </div>


                </v-row>


                <v-row v-if="showRow10">

                    <v-col cols="6" md="6">

                        <div id="d_imp_prev"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.previsionale_pratica" :disabled="true"
                                            :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>


                    <v-col cols="6" md="6">

                        <div id="d_scost_prev"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Scostamento</label>

                                        <vue-autonumeric v-model="controllo.differenza_previsionale_pratica"
                                            :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '-999999999999'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>




                </v-row>



                <v-row v-if="showRow11">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Agila
                        </div>
                    </div>


                </v-row>


                <v-row v-if="showRow12">

                    <v-col cols="6" md="6">

                        <div id="cont_percapi" style="cursor:pointer">
                            <ion-item id="itemPercApi" style="margin-top:-11px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Percentuale</ion-label>

                                <ion-select id="percentuale_api" name="percentuale_api" class='percentuale_api'
                                    cancel-Text="Chiudi" done-Text="" :value="controllo.percentuale_api"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in percentuale" :key="item.id" :value='item.id'> {{
                                            item.valore
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                    <v-col cols="6" md="6">

                        <div id="d_imp_api"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.importo_api" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>


                </v-row>


            </div>


            <div class="devMobile">

                <v-row>


                    <v-col cols="12" md="12">

                        <div id="cont_tipo_progetto_id" style="cursor:pointer">
                            <ion-item id="itemTipoProgetto"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Tipo</ion-label>

                                <ion-select id="tipo" name="tipo" class='tipo' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.tipo" interface="action-sheet">

                                    <ion-select-option v-for="item in tipi" :key="item.id" :value='item.id'> {{
                                            item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>


                </v-row>

                <v-row id="showCol9">

                    <v-col cols="12" md="12">

                        <div id="cont_intmand" style="cursor:pointer">
                            <ion-item id="itemItemMand"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Intestatario Mandato</ion-label>

                                <ion-select id="intestatario_mandato" name="intestatario_mandato"
                                    class='intestatario_mandato' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.intestatario_mandato" interface="action-sheet">

                                    <ion-select-option v-for="item in intestatari_mandato" :key="item.id"
                                        :value='item.id'>
                                        {{
                                                item.nome
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                </v-row>

                <v-row id="showCol1">

                    <v-col cols="12" md="12">

                        <div id="cont_companyid" style="cursor:pointer">
                            <ion-item id="itemCompany"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Cliente </ion-label>

                                <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                    done-Text="" :value="controllo.companyid" interface="action-sheet" disabled
                                    readonly>

                                    <ion-select-option v-for="item in aziende" :key="item.companyid"
                                        :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>


                </v-row>

                <v-row id="showCol2">


                    <v-col cols="12" md="12">

                        <div id="cont_praticaid" style="cursor:pointer">
                            <ion-item id="itemTipoProgetto"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Pratica</ion-label>

                                <ion-select id="praticaid" name="praticaid" class='praticaid' cancel-Text="Chiudi"
                                    done-Text="" :value="controllo.praticaid" interface="action-sheet">

                                    <ion-select-option v-for="item in pratiche" :key="item.id" :value='item.id'> {{
                                            item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>

                </v-row>

                <v-row id="showCol3">

                    <v-col cols="12" md="12">

                        <v-text-field id="numero" v-model="controllo.numero" label="Numero" readonly></v-text-field>

                    </v-col>

                </v-row>

                <v-row id="showCol4">

                    <v-col cols="12" md="12">

                        <div id="cont_iddescrizione" style="cursor:pointer">
                            <ion-item id="itemDescrizione"
                                style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Descrizione</ion-label>

                                <ion-select id="id_descrizione" name="id_descrizione" class='id_descrizione'
                                    cancel-Text="Chiudi" done-Text="" :value="controllo.id_descrizione"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in descrizioni" :key="item.id" :value='item.id'> {{
                                            item.descrizione
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row id="showRow1">

                    <v-col cols="12" md="12">

                        <div id="d_valore_fisso"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Valore Fisso</label>

                                        <vue-autonumeric v-model="controllo.valore_fisso" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>

                </v-row>

                <v-row id="showRow2">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Contributo Pratica
                        </div>
                    </div>

                </v-row>


                <v-row id="showRow3">

                    <v-col cols="12" md="12">

                        <div id="d_contr_pratica"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.contributo_pratica" :disabled="true"
                                            :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>


                </v-row>


                <v-row v-if="showRow3">

                    <v-col cols="12" md="12">

                        <div id="cont_perccontr" style="cursor:pointer">
                            <ion-item id="itemPercContr" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Percentuale</ion-label>

                                <ion-select id="percentuale_contributo_pratica" name="percentuale_contributo_pratica"
                                    class='percentuale_contributo_pratica' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.percentuale_contributo_pratica" interface="action-sheet">

                                    <ion-select-option v-for="item in percentuale" :key="item.id" :value='item.id'> {{
                                            item.valore
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>

                <v-row v-if="showRow4">

                    <v-col cols="12" md="12">

                        <div id="d_contr_pratica_val_calc"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Valore Calcolato</label>

                                        <vue-autonumeric v-model="controllo.importo_calcolato_contributo_pratica"
                                            :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>


                <v-row v-if="showRow5">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Investimento Pratica
                        </div>
                    </div>


                </v-row>

                <v-row v-if="showRow6">

                    <v-col cols="12" md="12">

                        <div id="d_inv_pratica"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.investimento_pratica" :disabled="true"
                                            :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>


                </v-row>


                <v-row v-if="showRow6">

                    <v-col cols="12" md="12">

                        <div id="cont_percinv" style="cursor:pointer">
                            <ion-item id="itemPercInv" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Percentuale</ion-label>

                                <ion-select id="percentuale_investimento_pratica"
                                    name="percentuale_investimento_pratica" class='percentuale_investimento_pratica'
                                    cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.percentuale_investimento_pratica" interface="action-sheet">

                                    <ion-select-option v-for="item in percentuale" :key="item.id" :value='item.id'> {{
                                            item.valore
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>


                </v-row>

                <v-row v-if="showRow7">

                    <v-col cols="12" md="12">

                        <div id="d_inv_pratica_val_calc"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Valore Calcolato</label>

                                        <vue-autonumeric v-model="controllo.importo_calcolato_investimento_pratica"
                                            :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>

                <v-row v-if="showRow8">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            &nbsp;
                        </div>
                    </div>


                </v-row>

                <v-row v-if="showCol5">


                    <v-col cols="12" md="12">

                        <div id="d_previsionale"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.importo_cert" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>

                <v-row v-if="showCol7">

                    <v-col cols="12" md="12">

                        <div id="cont_dafatt" style="cursor:pointer">
                            <ion-item id="itemDaFatt" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Da Fatturare</ion-label>

                                <ion-select id="status" name="status" class='status' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.status" interface="action-sheet">

                                    <ion-select-option v-for="item in valori_sino" :key="item.id" :value='item.id'> {{
                                            item.nome
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>


                </v-row>

                <v-row v-if="showCol8">

                    <v-col cols="12" md="12">

                        <div id="cont_idresources_group_name" style="cursor:pointer">
                            <ion-item id="itemGruppo" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Gruppo </ion-label>

                                <ion-select id="idresources_group_name" name="idresources_group_name"
                                    class='idresources_group_name' cancel-Text="Chiudi" done-Text=""
                                    :value="controllo.idresources_group_name" interface="action-sheet">

                                    <ion-select-option v-for="item in gruppi" :key="item.idresources_group_name"
                                        :value='item.idresources_group_name'> {{
                                                item.name
                                        }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>


                    </v-col>


                </v-row>

                <v-row v-if="showRow9">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Previsionale Pratica
                        </div>
                    </div>


                </v-row>

                <v-row v-if="showRow10">

                    <v-col cols="12" md="12">

                        <div id="d_imp_prev"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.previsionale_pratica" :disabled="true"
                                            :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>


                </v-row>


                <v-row v-if="showRow10">

                    <v-col cols="12" md="12">

                        <div id="d_scost_prev"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Scostamento</label>

                                        <vue-autonumeric v-model="controllo.differenza_previsionale_pratica"
                                            :disabled="true" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '-999999999999'
                                            }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </v-col>


                </v-row>

                <v-row v-if="showRow11">

                    <div style="background:#8080808c;
height: 20px;
padding: 0;
margin-left: 11px;
min-width: 100vw;
padding-left: 5px;
margin-bottom:5px">
                        <div style="font-size:12px;padding-left:3px;padding-top:3px;font-weight:bold">
                            Agila
                        </div>
                    </div>


                </v-row>


                <v-row v-if="showRow12">

                    <v-col cols="12" md="12">

                        <div id="cont_percapi" style="cursor:pointer">
                            <ion-item id="itemPercApi" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                    position="floating">Percentuale</ion-label>

                                <ion-select id="percentuale_api" name="percentuale_api" class='percentuale_api'
                                    cancel-Text="Chiudi" done-Text="" :value="controllo.percentuale_api"
                                    interface="action-sheet">

                                    <ion-select-option v-for="item in percentuale" :key="item.id" :value='item.id'> {{
                                            item.valore
                                    }}</ion-select-option>

                                </ion-select>
                            </ion-item>
                        </div>

                    </v-col>

                </v-row>


                <v-row v-if="showRow12">

                    <v-col cols="12" md="12">

                        <div id="d_imp_api"
                            class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                            <div class="v-input__control">
                                <div class="v-input__slot">
                                    <div class="v-text-field__slot"><label for="input-29"
                                            class="v-label v-label--active theme--light"
                                            style="left: 0px; right: auto; position: absolute">Importo</label>

                                        <vue-autonumeric v-model="controllo.importo_api" :disabled="true" :options="{
                                            digitGroupSeparator: '.',
                                            decimalCharacter: ',',
                                            decimalCharacterAlternative: '.',
                                            currencySymbol: '\u00a0€',
                                            currencySymbolPlacement: 's',
                                            roundingMethod: 'U',
                                            minimumValue: '0'
                                        }"></vue-autonumeric>

                                    </div>
                                </div>

                                <div class="v-text-field__details">
                                    <div class="v-messages theme--light">
                                        <div class="v-messages__wrapper"></div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </v-col>

                </v-row>






            </div>






        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupposizione">

            <template>
                <modal name="popupPosizione" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Posizione</label>

                            <div id="d_posizione_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePosizione" :options="'integer'"
                                                ref="fldPosizione" id="fldPosizione"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-posizione" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelPosizione" />

                                <v-img alt="" class="btn-confirm-posizione" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmPosizione" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupprevisionale">

            <template>
                <modal name="popupPrevisionale" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Importo</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreImporto" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldProroga" id="fldProroga"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-imp" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelImporto" />

                                <v-img alt="" class="btn-confirm-imp" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmImporto" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuppvalorefisso">

            <template>
                <modal name="popupValoreFisso" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Valore Fisso</label>

                            <div id="d_ferie_ap_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreFisso" :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }" ref="fldValoreFisso" id="fldValoreFisso"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-vfisso" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" @click="btnCancelValoreFisso" />

                                <v-img alt="" class="btn-confirm-vfisso" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    @click="btnConfirmValoreFisso" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apigroups from "../utils/groups/apigroups";

import apimodalitafatturazione from "../utils/modalitafatturazione/apimodalitafatturazione";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        console.log("PARAMS: ", this.$route.params.id);

        console.log("AZIENDE: ", window.ddlAziende);

        console.log("PRATICHE: ", window.ddlPratiche);

        console.log("ID PRAT SEL: ", window.idPratSelEdit);

        console.log("ID CUST SEL: ", window.idCustSelEdit);






        var pointerVue = this;


        this.cur_id = this.decodeString(this.$route.params.id);

        this.setupButtons();

        if (window.idCustSelEdit == 0) {

            this.aziende = window.ddlAziende;

        }

        if (window.idCustSelEdit != 0) {

            var Aziende = [];

            for (var i = 0; i < window.ddlAziende.length; i++) {

                if ((parseInt(window.ddlAziende[i].companyid) == parseInt(window.idCustSelEdit))) {

                    Aziende.push({
                        companyid: window.ddlAziende[i].companyid,
                        fld_name: window.ddlAziende[i].fld_name
                    });

                }

            }

            console.log("AZI SEL FROM PRAT: ", Aziende);

            this.aziende = Aziende;

        }


        if (window.idPratSelEdit == 0) {

            this.praticheTemp = window.ddlPratiche;

        }

        if (window.idPratSelEdit != 0) {

            var PraticheTemp = [];

            for (var x = 0; x < window.ddlPratiche.length; x++) {

                if ((parseInt(window.ddlPratiche[x].id) == parseInt(window.idPratSelEdit))) {

                    PraticheTemp.push({
                        cod_data_n_concessione: window.ddlPratiche[x].cod_data_n_concessione,
                        companyid: window.ddlPratiche[x].companyid,
                        contributo: window.ddlPratiche[x].contributo,
                        created_at: window.ddlPratiche[x].created_at,
                        data_inoltro_domanda: window.ddlPratiche[x].data_inoltro_domanda,
                        data_mandato: window.ddlPratiche[x].data_mandato,
                        data_stipula_fin_leasing: window.ddlPratiche[x].data_stipula_fin_leasing,
                        descrizione_progetto: window.ddlPratiche[x].descrizione_progetto,
                        finanziamento: window.ddlPratiche[x].finanziamento,
                        id: window.ddlPratiche[x].id,
                        id_controllo_pratica_stato: window.ddlPratiche[x].id_controllo_pratica_stato,
                        id_pratica_stato: window.ddlPratiche[x].id_pratica_stato,
                        id_pratica_stato_pagamento: window.ddlPratiche[x].id_pratica_stato_pagamento,
                        idinvoice_valuta: window.ddlPratiche[x].idinvoice_valuta,
                        intestatario_mandato: window.ddlPratiche[x].intestatario_mandato,
                        investimento: window.ddlPratiche[x].investimento,
                        load_in_doxweb: window.ddlPratiche[x].load_in_doxweb,
                        login: window.ddlPratiche[x].login,
                        mandato: window.ddlPratiche[x].mandato,
                        minimo_mandato: window.ddlPratiche[x].minimo_mandato,
                        nickname_doxweb: window.ddlPratiche[x].nickname_doxweb,
                        nome: window.ddlPratiche[x].nome,
                        note: window.ddlPratiche[x].note,
                        num: window.ddlPratiche[x].num,
                        password: window.ddlPratiche[x].password,
                        previsionale: window.ddlPratiche[x].previsionale,
                        prima_data_ricerca_sviluppo: window.ddlPratiche[x].prima_data_ricerca_sviluppo,
                        referenteid: window.ddlPratiche[x].referenteid,
                        sede_di_intervento: window.ddlPratiche[x].sede_di_intervento,
                        tipo_di_azienda: window.ddlPratiche[x].tipo_di_azienda,
                        tipo_finanziamento: window.ddlPratiche[x].tipo_finanziamento,
                        tipo_progetto_id: window.ddlPratiche[x].tipo_progetto_id,
                        updated_at: window.ddlPratiche[x].updated_at,
                        username: window.ddlPratiche[x].username,

                    });

                }

            }

            console.log("PRAT SEL FROM PRAT: ", PraticheTemp);

            this.praticheTemp = PraticheTemp;

        }



        this.tipo_progetto = window.ddlTipoProgetto;



        this.gruppi = window.ddlGruppi;

        this.tipo_controllo = window.ddlTipoControllo;

        this.descrizioni = window.ddlDescrizioni;

        this.percentuale = window.ddlPerc;

        console.log("PERCENTUALE: ", this.percentuale);

        // tipo_controllo

        if (this.cur_id == 0) {

            $("#divTitStorico").hide();
            $("#divValStorico").hide();

            this.controllo.tipo = "S"; // default semplice

            this.controllo.numero = window.newNumModFatt;

            this.controllo.status = "N";

            this.setFields();

        }

        if (this.cur_id > 0) {

            this.titolo = "Modifica Modalità Fatturazione";

            this.initEditGruppo();

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.saveData();

                    break;


                case "btn_back":

                    var id_pratica = window.$cookies.get("id_pratica");

                    // router.push({
                    //     path: "/controlli"
                    // });

                    router.push({
                        path: "/modalitaFatturazione/" + id_pratica
                    });

                    break;

                case "btn_utenti":

                    window.$cookies.set("sel_filter_azienda_gruppo_utenti", $("#companyid").val(), "9y");
                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupSelUsers"
                    });


                    break;

                case "btn_mail":

                    window.$cookies.set("sel_id_group", this.$route.params.id, "9y");

                    router.push({
                        path: "/editGroupMails"
                    });

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {


            $('body').on('ionChange', '.id_descrizione', function (event) {

                console.log(event);

                pointerVue.controllo.id_descrizione = this.value;

                pointerVue.setupButtons();

            });


            $('body').on('ionChange', '.idresources_group_name', function (event) {

                console.log(event);

                pointerVue.controllo.idresources_group_name = this.value;

                pointerVue.setupButtons();

            });


            $('body').on('ionChange', '.percentuale_investimento_pratica', function (event) {
                console.log(event);

                pointerVue.controllo.percentuale_investimento_pratica = this.value;

                var imp_calc = (parseFloat(pointerVue.controllo.investimento_pratica) * pointerVue.controllo.percentuale_investimento_pratica) / 100;

                pointerVue.controllo.importo_calcolato_investimento_pratica = parseFloat(imp_calc).toFixed(2);

                var res = pointerVue.calcTotal(pointerVue.controllo.valore_fisso, pointerVue.controllo.importo_calcolato_contributo_pratica, pointerVue.controllo.importo_calcolato_investimento_pratica);

                console.log("RES: ", res);

                pointerVue.controllo.importo_cert = res;

                setTimeout(() => {

                    var res2 = pointerVue.updateDifference(pointerVue.controllo.previsionale_pratica, pointerVue.controllo.importo_cert);

                    pointerVue.controllo.differenza_previsionale_pratica = res2;

                }, 300);


                pointerVue.setupButtons();

            });



            $('body').on('ionChange', '.percentuale_contributo_pratica', function (event) {
                console.log(event);

                pointerVue.controllo.percentuale_contributo_pratica = this.value;

                console.log("CONTR PRAT: ", pointerVue.controllo.contributo_pratica);

                console.log("PERC CONTR PRAT: ", pointerVue.controllo.percentuale_contributo_pratica);



                console.log("CONTR PRAT 2: ", parseFloat(pointerVue.controllo.contributo_pratica));


                var imp_calc = (parseFloat(pointerVue.controllo.contributo_pratica) * pointerVue.controllo.percentuale_contributo_pratica) / 100;

                console.log("IMP CALC: ", imp_calc);

                console.log("IMP CALC 2: ", parseFloat(imp_calc).toFixed(2));

                pointerVue.controllo.importo_calcolato_contributo_pratica = parseFloat(imp_calc).toFixed(2);



                var res = pointerVue.calcTotal(pointerVue.controllo.valore_fisso, pointerVue.controllo.importo_calcolato_contributo_pratica, pointerVue.controllo.importo_calcolato_investimento_pratica);

                console.log("RES: ", res);

                pointerVue.controllo.importo_cert = res;

                setTimeout(() => {

                    var res2 = pointerVue.updateDifference(pointerVue.controllo.previsionale_pratica, pointerVue.controllo.importo_cert);

                    pointerVue.controllo.differenza_previsionale_pratica = res2;

                }, 300);


                pointerVue.setupButtons();
            });


            // $('body').on('ionChange', '.intestatario_mandato', function (event) {
            //     console.log(event);

            //     pointerVue.controllo.intestatario_mandato = this.value;

            //     pointerVue.showRow11 = false;
            //     pointerVue.showRow12 = false;

            //     if (pointerVue.controllo.intestatario_mandato == "Api") {


            //         pointerVue.showRow11 = true;
            //         pointerVue.showRow12 = true;



            //     }




            // });


            $('body').on('ionChange', '.praticaid', function (event) {
                console.log(event);

                pointerVue.controllo.praticaid = this.value;

                if (pointerVue.controllo.tipo == "C") { // modalità calcolata

                    setTimeout(() => {

                        pointerVue.getValuesPratica();

                    }, 200);



                }

                pointerVue.setupButtons();

            });


            $('body').on('ionChange', '.tipo', function (event) {
                console.log(event);

                pointerVue.controllo.tipo = this.value;

                pointerVue.setFields();

                setTimeout(() => {

                    pointerVue.setupButtons();

                }, 200);


            });




            $('body').on('click', '#d_valore_fisso', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupValoreFisso');

            });





            $('body').on('click', '#d_previsionale', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPrevisionale');

            });


            $('body').on('click', '#d_posizione', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupPosizione');

            });

            $('body').on('ionChange', '.tipo_progetto_id', function (event) {
                console.log(event);

                pointerVue.controllo.tipo_progetto_id = this.value;

                pointerVue.setPratiche(pointerVue.controllo.companyid, pointerVue.controllo.tipo_progetto_id);

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.praticaid', function (event) {
                console.log(event);

                pointerVue.controllo.praticaid = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.id_tipo_controllo', function (event) {
                console.log(event);

                pointerVue.controllo.id_tipo_controllo = this.value;

                pointerVue.setupButtons();

            });

            $('body').on('ionChange', '.idresources_group_name', function (event) {
                console.log(event);

                pointerVue.controllo.idresources_group_name = this.value;

            });

            $('body').on('ionChange', '.status', function (event) {
                console.log(event);

                pointerVue.controllo.status = this.value;

            });



            $('body').on('blur', '#nome', function (event) {
                console.log(event);

                pointerVue.setupButtons();

            });

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        percentuale: [],

        showRow1: false,
        showRow2: false,
        showRow3: false,
        showRow4: false,
        showRow5: false,
        showRow6: false,
        showRow7: false,
        showRow8: false,
        showRow9: false,
        showRow10: false,
        showRow11: false,
        showRow12: false,



        valoreFisso: null,

        showCol1: false,
        showCol2: false,
        showCol3: false,
        showCol4: false,
        showCol5: false,
        showCol6: false,
        showCol7: false,
        showCol8: false,

        showCol9: false,


        valoreImporto: null,

        descrizioni: [],

        tipi: [{ id: "S", nome: "Semplice" }, { id: "C", nome: "Calcolata" }],

        intestatari_mandato: [{ id: "Agila", nome: "Agila" }, { id: "Api", nome: "Api" }, { id: "Vico", nome: "Vico" }],

        removeValue: "S",

        valorePosizione: null,

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,



        // controllo: { companyid: "", tipo_progetto_id: "", nome: "", praticaid: "", id_tipo_controllo: "", posizione: "", 
        // send_as_mail: "N", send_as_sms: "N", send_as_wapp: "N", enable_history: "N" },


        controllo: { tipo: "", companyid: "", idresources_group_name: "", importo_cert: "", praticaid: "", valore_fisso: "", percentuale_contributo_pratica: "", percentuale_investimento_pratica: "", id_descrizione: "" },



        dipendenti: [],

        dipartimentiTemp: [],

        scopesTemp: [],

        ore: [],

        tipo_progetto: [],

        pratiche: [],

        praticheTemp: [],

        gruppi: [],

        tipo_controllo: [],

        stati: [{ id: "N", name: "No" }, { id: "Y", name: "Si" }, { id: "1", name: "NA" }, { id: "2", name: "WIP" }],


        valori_sino: [{ id: "N", nome: "No" }, { id: "Y", nome: "Si" }],

        currAzienda: "",
        titolo: "Aggiungi Modalità Fatturazione",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    components: {
        VueAutonumeric,
    },


    methods: {



        getValuesPratica: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimodalitafatturazione.getValuesPratica(
                v_token,
                that.controllo.praticaid
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getValuesPratica", res);

                that.controllo.contributo_pratica = res.data.val_contributo;
                that.controllo.investimento_pratica = res.data.val_investimento;
                that.controllo.previsionale_pratica = res.data.val_previsionale;


                setTimeout(() => {

                    var res = that.calcTotal(that.controllo.valore_fisso, that.controllo.importo_calcolato_contributo_pratica, that.controllo.importo_calcolato_investimento_pratica);

                    console.log("RES: ", res);

                    that.controllo.importo_cert = res;

                    setTimeout(() => {

                        var res2 = that.updateDifference(that.controllo.previsionale_pratica, that.controllo.importo_cert);

                        that.controllo.differenza_previsionale_pratica = res2;

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        btnCancelValoreFisso: function () {

            this.$modal.show('popupValoreFisso');

        },

        btnConfirmValoreFisso: function () {

            var that = this;

            this.controllo.valore_fisso = this.valoreFisso;

            this.$modal.hide('popupValoreFisso');

            var res = this.calcTotal(this.controllo.valore_fisso, this.controllo.importo_calcolato_contributo_pratica, this.controllo.importo_calcolato_investimento_pratica);

            // console.log("RES: ", res);

            this.controllo.importo_cert = res;

            setTimeout(() => {

                var res2 = that.updateDifference(this.controllo.previsionale_pratica, this.controllo.importo_cert);

                that.controllo.differenza_previsionale_pratica = res2;

                that.setupButtons();

            }, 300);


        },

        formatFloatForCalc: function(v_value) {

            var result = v_value;

            if (result.toString().indexOf(",") != -1)
            {

                result = result.replace(",", ".");

            }

            return result;

        },

        updateDifference: function (v_previsionale_pratica, v_importo_cert) {

            var v_result = "";


            console.log("PREV PRATICA: ", v_previsionale_pratica);
            console.log("IMPORTO CERT: ", v_importo_cert);

            var v_previsionale_pratica_tmp = this.formatFloatForCalc(v_previsionale_pratica);
            var v_importo_cert_tmp = this.formatFloatForCalc(v_importo_cert);

            console.log("PREV PRATICA FORMAT: ", v_previsionale_pratica_tmp);
            console.log("IMPORTO CERT FORMAT: ", v_importo_cert_tmp);


            // var v1 = parseFloat(v_previsionale_pratica).toFixed(2);
            // var v2 = parseFloat(v_importo_cert).toFixed(2);

            var v1 = parseFloat(v_previsionale_pratica_tmp).toFixed(2);
            var v2 = parseFloat(v_importo_cert_tmp).toFixed(2);

            console.log("PREV PRATICA 2: ", v1);
            console.log("IMPORTO CERT 2: ", v2);

            if (!isNaN(v1)) {

                if (!isNaN(v2)) {


                    v_result = v1 - v2;

                }


            }

            console.log("RESULT: ", v_result);


            return v_result;

        },


        calcTotal: function (v_fisso, v_calc_contrib, v_calc_inv) {

            var v_result = 0;


            if (v_fisso != "") {


                v_fisso = parseFloat(v_fisso);

                if (!isNaN(v_fisso)) {
                    v_result = v_fisso;
                }
            }

            if (v_calc_contrib != "") {


                v_calc_contrib = parseFloat(v_calc_contrib);


                if (!isNaN(v_calc_contrib)) {
                    v_result = v_result + v_calc_contrib;
                }
            }

            if (v_calc_inv != "") {


                v_calc_inv = parseFloat(v_calc_inv);


                if (!isNaN(v_calc_inv)) {
                    v_result = v_result + v_calc_inv;
                }
            }


            v_result = v_result.toString().replace(".", ",");


            return v_result;


        },


        setFields: function () {


            if (this.controllo.tipo == "S") // semplice
            {

                this.showCol1 = true;
                this.showCol2 = true;
                this.showCol3 = true;
                this.showCol4 = true;
                this.showCol5 = true;
                this.showCol6 = true;
                this.showCol7 = true;
                this.showCol8 = true;
                this.showCol9 = false;
                this.showRow1 = false;
                this.showRow2 = false;
                this.showRow3 = false;
                this.showRow4 = false;
                this.showRow5 = false;
                this.showRow6 = false;
                this.showRow7 = false;
                this.showRow8 = false;
                this.showRow9 = false;
                this.showRow10 = false;
                // this.showRow11 = false;
                // this.showRow12 = false;

            }


            if (this.controllo.tipo == "C") // calcolata
            {


                this.showCol1 = true;
                this.showCol2 = true;
                this.showCol3 = true;
                this.showCol4 = true;
                this.showCol5 = true;
                this.showCol6 = true;
                this.showCol7 = true;
                this.showCol8 = true;
                this.showCol9 = true;
                this.showRow1 = true;
                this.showRow2 = true;
                this.showRow3 = true;
                this.showRow4 = true;
                this.showRow5 = true;
                this.showRow6 = true;
                this.showRow7 = true;
                this.showRow8 = true;
                this.showRow9 = true;
                this.showRow10 = true;

                this.controllo.intestatario_mandato = "Agila";



            }


        },

        btnCancelImporto: function () {

            this.$modal.show('popupPrevisionale');

        },

        btnConfirmImporto: function () {

            this.controllo.importo_cert = this.valoreImporto;


            this.$modal.hide('popupPrevisionale');


            this.setupButtons();

        },


        chkCancelRemoveValue: function () {

            this.removeValue = "N";

        },


        chkRemoveValue: function () {

            if (this.removeValue == "S") {

                this.controllo.send_as_mail = "N";
                this.controllo.send_as_sms = "N";
                this.controllo.send_as_wapp = "N";

            }

        },

        btnConfirmPosizione: function () {

            this.controllo.posizione = this.valorePosizione;

            this.$modal.hide('popupPosizione');

            this.setupButtons();

        },


        btnCancelPosizione: function () {

            this.$modal.hide('popupPosizione');

        },

        decodeString: function (value) {

            var v = value.substring(0, value.length - 1);

            console.log("VAL: ", v);

            return atob(v);

        },



        enableSaveButton: function () {

            var result = 1;

            console.log("CONTROLLO: ", this.controllo);

            if (this.controllo.tipo == "") {
                result = 0;
            }

            if (result == 1) {
                if (this.controllo.companyid == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.idresources_group_name == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.importo_cert == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.praticaid == "") {
                    result = 0;
                }
            }

            if (result == 1) {
                if (this.controllo.id_descrizione == "") {
                    result = 0;
                }
            }


            if (result == 1) {
                if (this.controllo.tipo == "C") // calcolata
                {
                    if (this.controllo.valore_fisso == "") {
                        result = 0;
                    }
                }
            }

            if (result == 1) {
                if (this.controllo.tipo == "C") // calcolata
                {
                    if (this.controllo.percentuale_contributo_pratica == "") {
                        result = 0;
                    }
                }
            }

            if (result == 1) {
                if (this.controllo.tipo == "C") // calcolata
                {
                    if (this.controllo.percentuale_investimento_pratica == "") {
                        result = 0;
                    }
                }
            }


            return result;

        },

        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        setDipartimenti: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("DIP TMP: ", this.dipartimentiTemp);

            var dipartimentiAzienda = [];

            for (var i = 0; i < this.dipartimentiTemp.length; i++) {

                if (parseInt(this.dipartimentiTemp[i].companyid) == parseInt(id_azienda)) {

                    dipartimentiAzienda.push({
                        departmentid: this.dipartimentiTemp[i].departmentid,
                        fld_name: this.dipartimentiTemp[i].fld_name
                    });

                }

            }

            console.log("DIP SEL: ", dipartimentiAzienda);

            this.dipartimenti = dipartimentiAzienda;

        },


        setPraticheOld: function (id_azienda, id_tipo_progetto) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("TIPO PROJ 2: ", id_tipo_progetto);

            console.log("PRAT TMP: ", this.praticheTemp);

            var Pratiche = [];

            if ((id_azienda != "") && (id_tipo_progetto != "")) {

                for (var i = 0; i < this.praticheTemp.length; i++) {

                    if ((parseInt(this.praticheTemp[i].companyid) == parseInt(id_azienda)) && (parseInt(this.praticheTemp[i].tipo_progetto_id) == parseInt(id_tipo_progetto))) {

                        Pratiche.push({
                            id: this.praticheTemp[i].id,
                            nome: this.praticheTemp[i].nome
                        });

                    }

                }

            }

            console.log("PRAT SEL: ", Pratiche);

            this.pratiche = Pratiche;

        },

        setPratiche: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("PRAT TMP: ", this.praticheTemp);

            var Pratiche = [];

            if (id_azienda != "") {

                for (var i = 0; i < this.praticheTemp.length; i++) {

                    if (parseInt(this.praticheTemp[i].companyid) == parseInt(id_azienda)) {

                        Pratiche.push({
                            id: this.praticheTemp[i].id,
                            nome: this.praticheTemp[i].nome
                        });

                    }

                }

            }

            console.log("PRAT SEL: ", Pratiche);

            this.pratiche = Pratiche;

        },




        setScopes: function (id_azienda) {

            console.log("AZI SEL 2: ", id_azienda);

            console.log("SCOPES TMP: ", this.scopesTemp);

            var scopesAzienda = [];

            for (var i = 0; i < this.scopesTemp.length; i++) {

                if (parseInt(this.scopesTemp[i].company_id) == parseInt(id_azienda)) {

                    scopesAzienda.push({
                        id: this.scopesTemp[i].id,
                        name: this.scopesTemp[i].name
                    });

                }

            }

            console.log("SCOPES SEL: ", scopesAzienda);

            this.scopes = scopesAzienda;

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.controllo.companyid = azienda.companyid;

            this.setPratiche(this.controllo.companyid);

            this.setupButtons();
        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                var enableSave = this.enableSaveButton();

                if (enableSave == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30
                    });



                }



                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });



                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);





        },

        validate() {

            var that = this;

            var errore = "";

            if ($("#companyid").val() == "") {
                errore = "Specificare l'azienda";
            }

            if (errore == "") {

                if ($("#name").val() == "") {
                    errore = "Specificare il nome";
                }

            }

            if (errore == "") {

                if ($("#departmentid").val() == "") {
                    errore = "Specificare il dipartimento";
                }

            }


            if ($("#group_scope_id").val() != "") {

                that.gruppo.group_scope_id = $("#group_scope_id").val();

            }


            if (errore == "") {


                that.saveData();

            }

            if (errore != "") {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.aziende;

                this.dipartimentiTemp = res.data.dipartimenti;

                this.scopesTemp = res.data.scope;


                // imposto di default l'azienda del filtro
                var v_company = window.$cookies.get("sel_filter_azienda_gruppi");

                that.gruppo.companyid = v_company;

                this.setDipartimenti(v_company);

                this.setScopes(v_company);


                $("#itemCompany").addClass("item-has-value");



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },



        initEditGruppo: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimodalitafatturazione.editFattura(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editFattura", res);

                that.setPratiche(res.data.controllo.companyid);

                that.controllo = res.data.controllo;



                that.setupButtons();



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },




        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.getPianoOrario(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianoOrario", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

                this.pianoorario = res.data.piano_orario;

                $("#itemCompany").addClass("item-has-value");

                if (this.pianoorario.lunedi != null) {

                    $("#itemLunedi").addClass("item-has-value");

                }

                if (this.pianoorario.martedi != null) {

                    $("#itemMartedi").addClass("item-has-value");

                }

                if (this.pianoorario.mercoledi != null) {

                    $("#itemMercoledi").addClass("item-has-value");

                }

                if (this.pianoorario.giovedi != null) {

                    $("#itemGiovedi").addClass("item-has-value");

                }

                if (this.pianoorario.venerdi != null) {

                    $("#itemVenerdi").addClass("item-has-value");

                }

                if (this.pianoorario.sabato != null) {

                    $("#itemSabato").addClass("item-has-value");

                }

                if (this.pianoorario.domenica != null) {

                    $("#itemDomenica").addClass("item-has-value");

                }





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {


            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("CONTROLLO: ", that.controllo);
            console.log("CUR ID: ", that.cur_id);
            console.log("TOKEN: ", v_token);


            if (that.cur_id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response = await apimodalitafatturazione.insertFattura(
                    that.controllo,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertFattura", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();



                        var id_pratica = window.$cookies.get("id_pratica");


                        router.push({
                            path: "/modalitaFatturazione/" + id_pratica
                        });



                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );


            }


            if (that.cur_id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var response2 = await apimodalitafatturazione.updateFattura(
                    that.controllo,
                    v_token,
                    that.cur_id
                ).then((res2) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateFattura", res2);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        // router.push({
                        //     path: "/controlli"
                        // });

                        var id_pratica = window.$cookies.get("id_pratica");


                        router.push({
                            path: "/modalitaFatturazione/" + id_pratica
                        });

                    }, 200);

                }).catch(err2 => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err2);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response2);

                }

                );


            }





            // this.cur_id 

            // var id_rec = that.$route.params.id;

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            // var response = await apisedioperative.saveSedeOperativa(
            //     that.sede_operativa,
            //     v_token,
            //     id_rec
            // ).then((res) => {

            //     that.$root.$children[0].showProgress = false;
            //     console.log("res from saveSedeOperativa", res);

            //     that.$swal({
            //         icon: "success",
            //         text: "Dati salvati correttamente",
            //         showConfirmButton: false,
            //         timer: 2000
            //     });

            //     setTimeout(() => {

            //         that.dialogMsg = false;
            //         that.setupButtons();

            //         router.push({
            //             path: "/sediOperative"
            //         });

            //     }, 200);

            // }).catch(err => {
            //     that.$root.$children[0].showProgress = false;
            //     console.log(err);
            //     var msg = err.response.data.Error;

            //     that.$swal({
            //         icon: "error",
            //         text: msg,
            //         showConfirmButton: false,
            //         timer: 8000
            //     });
            //     console.log("Errori", "Non è stato possibile salvare i dati");
            //     console.log("response", response);

            // }

            // );

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.noDevMobile {}

.devMobile {
    display: none;
}


.w-container-mod-fatt {
    width: 500px;
    padding: 0px;
    z-index: 3;
    overflow-x: hidden !important;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-mod-fatt {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .noDevMobile {
        display: none;
    }

    .devMobile {
        display: block;
    }

    .edit_mod_fatt_agila .v-label {
        font-size: 1em !important
    }

    .edit_mod_fatt_agila .v-input {
        font-size: 10px !important;
        padding: 10px !important;
    }

    .edit_mod_fatt_agila ion-select {
        font-size: 10px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.edit_mod_fatt_agila .v-label {
    font-size: 1.2em !important
}


.edit_mod_fatt_agila .v-input {
    font-size: 10px !important;
    padding: 10px !important;
}

.edit_mod_fatt_agila ion-select {
    font-size: 10px !important;
}


.edit_mod_fatt_agila .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.edit_mod_fatt_agila .v-input.cl-checkbox.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
    margin-top: 0px !important;
}

.edit_mod_fatt_agila .v-input.cl-checkbox.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0px !important;
}
</style>
