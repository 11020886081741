import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIConfigurationProduct {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  

  async initFilter(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("EmtGetProdConfigurationsInitFilterApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insConf(conf, user, id)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("val_id",  id);

    for ( var key in conf ) {
      formData.append(key, conf[key]);
   }


    return axios.post(this.getUrlDomain("EmtGetProdConfigurationsUpdateApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getConf(id, user)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("id",  id);


    return axios.post(this.getUrlDomain("EmtGetProdConfigurationsEditApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteConf(id, user)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("id",  id);


    return axios.post(this.getUrlDomain("EmtGetProdConfigurationsDelApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getAllPermissionsForProd(id, user)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("id",  id);


    return axios.post(this.getUrlDomain("EmtGetAllPermsForProdApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }






  async getPermissions(id, user)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("id",  id);


    return axios.post(this.getUrlDomain("EmtProdConfigurationsGetPermApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updatePermissions(id, permessi, user, permessidetails)
  {

    let formData = new FormData();


    formData.append("username",  user);
    formData.append("id",  id);
    formData.append("permessi",  permessi);
    formData.append("permessidetails",  permessidetails);


    return axios.post(this.getUrlDomain("EmtProdConfigurationsUpdPermApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getUsersForConf(user)
  {

    let formData = new FormData();


    formData.append("username",  user);


    return axios.post(this.getUrlDomain("getUsersConfProduct"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APIConfigurationProduct({
  url: "http://emtool.local/public/api/smartbook/"
})
