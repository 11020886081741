import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIAziende {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  
  async initInsertAzienda(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtInitAddAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async validateVATAzienda(id, vat, user) {

    let formData = new FormData();

    formData.append("val_vat", vat);
    formData.append("val_id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtValidateVATApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async validateVATCFAzienda(id, vat, cf, user) {

    let formData = new FormData();

    formData.append("val_vat", vat);
    formData.append("val_cf", cf);
    formData.append("val_id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtValCFVATAziApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async insertAzienda(azienda, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in azienda) {
      formData.append(key, azienda[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertEmtAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async insertAziendaNew(azienda, user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    for (var key in azienda) {
      formData.append(key, azienda[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertEmtAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async insertAziendaAgila(azienda, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in azienda) {
      formData.append(key, azienda[key]);
    }

    return axios.post(this.getUrlDomain("EmtInsertEmtAziendaAgilaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async initEditAzienda(id, user) {

    return axios.get(this.getUrlDomain("EmtInitEditAziendApi") + "/" + id + "?api=1&username=" + user, { 'Content-Type': 'multipart/form-data' });

  }

  async updateAzienda(azienda, id, user, attach_remove, attach_remove_visura, prodotto) {

    let formData = new FormData();

    formData.append("user", user);
    formData.append("attach_remove", attach_remove);
    formData.append("attach_remove_visura", attach_remove_visura);
    formData.append("prodotto", prodotto);

    for (var key in azienda) {

      if (azienda[key] != null) {

        formData.append(key, azienda[key]);

      }
      else {

        formData.append(key, "");

      }

    }

    return axios.post(this.getUrlDomain("EmtUpdateAziendaApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }




  async deleteAzienda(id, user)
  {


    return axios.get(this.getUrlDomain("EmtDeleteAziendaApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteAziendaNew(v_id, username, prodotto)
  {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("username", username);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("EmtDelAziendaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async exportXlsAgila(user, curSrcText, curCompany, curFormaGiuId, curSecActId, curComId, curRefId, curPropId, curPratId, curTipoProgId, curDimAzi, curProvincia, curStato, curAziendaArtigiana) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("curSrcText", curSrcText);
    formData.append("curCompany", curCompany);
    formData.append("curFormaGiuId", curFormaGiuId);
    formData.append("curSecActId", curSecActId);
    formData.append("curComId", curComId);
    formData.append("curRefId", curRefId);
    formData.append("curPropId", curPropId);
    formData.append("curPratId", curPratId);
    formData.append("curTipoProgId", curTipoProgId);
    formData.append("curDimAzi", curDimAzi);
    formData.append("curProvincia", curProvincia);
    formData.append("curStato", curStato);
    formData.append("curAziendaArtigiana", curAziendaArtigiana);

    return axios.post(this.getUrlDomain("EmtAgilaAziendeExportXlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async importXlsAgila(user, v_file) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("file_excel", v_file);

    return axios.post(this.getUrlDomain("EmtAgilaAziendeImportXlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getTotResImp(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaImpTotResApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async initFilterValues(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaAziValuesDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIAziende({
  url: "https://services.ebadge.it/public/api/"
})
