var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"w-container-edit-cespiti-admin v-overflow editCespitiAdmin",staticStyle:{"padding-top":"59px","background-color":"rgb(255 255 255 / 50%)"}},[_c('v-row',[_c('v-col',{staticClass:"mainCont",staticStyle:{"min-width":"100vw","position":"fixed","left":"0","padding-left":"0px","z-index":"3"}},[_c('div',{staticClass:"mainTitle",staticStyle:{"background-color":"white","color":"black","font-weight":"bold","padding":"4px","font-size":"10px","min-width":"100vw","border-color":"#f5cf2e"}},[_vm._v(_vm._s(_vm.titolo))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"12px","margin-left":"8px"}},[_vm._v("RAL")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_fido_ant_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldFidoAnt",attrs:{"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '\u00a0€',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0',
                                    modifyValueOnWheel: false,
                                },"id":"fldFidoAnt"},model:{value:(_vm.resources_costi_aziendali.ral),callback:function ($$v) {_vm.$set(_vm.resources_costi_aziendali, "ral", $$v)},expression:"resources_costi_aziendali.ral"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"12px","margin-left":"8px"}},[_vm._v("Costo Aziendale")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_fido_ant_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldFidoAnt",attrs:{"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '\u00a0€',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0',
                                    modifyValueOnWheel: false,
                                },"id":"fldFidoAnt"},model:{value:(_vm.resources_costi_aziendali.costo_az),callback:function ($$v) {_vm.$set(_vm.resources_costi_aziendali, "costo_az", $$v)},expression:"resources_costi_aziendali.costo_az"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"12px","margin-left":"8px"}},[_vm._v("Ticket")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_fido_ant_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldFidoAnt",attrs:{"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '\u00a0€',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0',
                                    modifyValueOnWheel: false,
                                },"id":"fldFidoAnt"},model:{value:(_vm.resources_costi_aziendali.ticket),callback:function ($$v) {_vm.$set(_vm.resources_costi_aziendali, "ticket", $$v)},expression:"resources_costi_aziendali.ticket"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"12px","margin-left":"8px"}},[_vm._v("Diaria")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_fido_ant_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldFidoAnt",attrs:{"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '\u00a0€',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '0',
                                    modifyValueOnWheel: false,
                                },"id":"fldFidoAnt"},model:{value:(_vm.resources_costi_aziendali.diaria),callback:function ($$v) {_vm.$set(_vm.resources_costi_aziendali, "diaria", $$v)},expression:"resources_costi_aziendali.diaria"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"10px!important"},attrs:{"cols":"12","md":"12"}},[_c('label',{staticStyle:{"font-size":"12px","margin-left":"8px"}},[_vm._v("Sgravi")]),_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_fido_ant_popup"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}}),_c('vue-autonumeric',{ref:"fldFidoAnt",attrs:{"options":{
                                    digitGroupSeparator: '.',
                                    decimalCharacter: ',',
                                    decimalCharacterAlternative: '.',
                                    currencySymbol: '\u00a0€',
                                    currencySymbolPlacement: 's',
                                    roundingMethod: 'U',
                                    minimumValue: '-999999999',
                                    modifyValueOnWheel: false,
                                    allowNegative: true
                                },"id":"fldFidoAnt"},model:{value:(_vm.resources_costi_aziendali.sgravi),callback:function ($$v) {_vm.$set(_vm.resources_costi_aziendali, "sgravi", $$v)},expression:"resources_costi_aziendali.sgravi"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }