import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIPianoOrario {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async initInsert(user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("prodotto",  prodotto);
    
   
    return axios.post(this.getUrlDomain("EmtInitAddPianoOrarioApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async insPianoOrario(pianoorario, user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    for ( var key in pianoorario ) {
      formData.append(key, pianoorario[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtInsPianoOrarioApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getPianoOrario(id, user, prodotto)
  {

    return axios.get(this.getUrlDomain("EmtGetPianoOrarioApi") + "/" + id + "?api=1&username=" + user + "&prodotto=" + prodotto,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updPianoOrario(pianoorario, id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    for ( var key in pianoorario ) {
      formData.append(key, pianoorario[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtUpdPianoOrarioApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deletePianoOrario(id, user)
  {


    return axios.get(this.getUrlDomain("EmtDelPianoOrarioApi") + "?id=" + id + "&username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getAziende(user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("prodotto",  prodotto);

   
    return axios.post(this.getUrlDomain("EmtPianoOrarioGetAziendeApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async insFestivita(festivita, user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    for ( var key in festivita ) {
      formData.append(key, festivita[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtInsFestivita") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getFestivity(v_id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    formData.append("v_id",  v_id);
    
   
    return axios.post(this.getUrlDomain("EmtGetFestivita") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updFestivita(festivita, v_id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    formData.append("v_id",  v_id);

    for ( var key in festivita ) {
      formData.append(key, festivita[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtUpdFestivita") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteFestivita(v_id, user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    formData.append("v_id",  v_id);
    
   
    return axios.post(this.getUrlDomain("EmtDelFestivita") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async copiaDate(v_anno)
  {

    let formData = new FormData();


    formData.append("v_anno",  v_anno);
    
   
    return axios.post(this.getUrlDomain("EmtFestivitaCopia") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APIPianoOrario({
  url: "https://services.ebadge.it/public/api/"
})
