import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIPratiche {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initDDL(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsInitDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAzienda(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpGetInfoAzApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSedeOperativa(sede_operativa, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in sede_operativa) {
      formData.append(key, sede_operativa[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editPratica(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditPraticaApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deletePratiche(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaPraticheDeleteApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertControllo(controllo, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaInsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  
  async updatePratica(pratica, user, id) {

    let formData = new FormData();

    formData.append("v_user", user);
    formData.append("val_id", id);

    for (var key in pratica) {
      formData.append(key, pratica[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaPraticheUpdateApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCondizioni(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsCondApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async exportXls(user, curSrcText, curCompany, curPrjType, curCtrType, curStatus) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("curSrcText", curSrcText);
    formData.append("curCompany", curCompany);
    formData.append("curPrjType", curPrjType);
    formData.append("curCtrType", curCtrType);
    formData.append("curStatus", curStatus);


    return axios.post(this.getUrlDomain("EmtAgilaExportXlsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getTotPratiche(nome, anno, companyid, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_desc_tipo", nome);
    formData.append("v_dossier_anno", anno);
    formData.append("v_companyid", companyid);

    return axios.post(this.getUrlDomain("EmtAgilaGetTotPraticheApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getInfoCompany(user, v_id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id_company", v_id);

    return axios.post(this.getUrlDomain("EmtAgilaPraticheGetInfoCompanyApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async insertPratica(pratica, user) {

    let formData = new FormData();

    formData.append("v_user", user);

    for (var key in pratica) {
      formData.append(key, pratica[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaPraticheInsertApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async exportXLS(user, v_curCompany, v_curPrjType, v_curStatus, v_curSrcText, v_curReferente, v_curGestore, v_curPagamento, v_curIntMand, v_dateFrom, v_dateTo) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("curCompany", v_curCompany);
    formData.append("curPrjType", v_curPrjType);
    formData.append("curStatus", v_curStatus);
    formData.append("curSrcText", v_curSrcText);
    formData.append("curReferente", v_curReferente);
    formData.append("curGestore", v_curGestore);
    formData.append("curPagamento", v_curPagamento);
    formData.append("curIntMand", v_curIntMand);
    formData.append("dateFrom", v_dateFrom);
    formData.append("dateTo", v_dateTo);


    return axios.post(this.getUrlDomain("EmtAgilaPraticheExportXlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




}

export default new APIPratiche({
  url: "https://services.ebadge.it/public/api/"
})
