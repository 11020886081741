import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIControlliCondizioni {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async infoControllo(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaGetCtrlsCondInfoCtrApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAzienda(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpGetInfoAzApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async associaCondizioni(user, id, id_ctr_master) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);
    formData.append("id_ctr_master", id_ctr_master);

    return axios.post(this.getUrlDomain("EmtAgilaGetCtrlsCondAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async associaCondizioniConCond(user, id, id_ctr_master, type_cond) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);
    formData.append("id_ctr_master", id_ctr_master);
    formData.append("type_cond", type_cond);

    return axios.post(this.getUrlDomain("EmtAgilaGetCtrlsCondAssocApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  // async saveSedeOperativa(sede_operativa, user, id) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("val_id", id);

  //   for (var key in sede_operativa) {
  //     formData.append(key, sede_operativa[key]);
  //   }

  //   return axios.post(this.getUrlDomain("EmtAgilaSaveSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }


  // async editControllo(id, user) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("id", id);


  //   return axios.post(this.getUrlDomain("EmtAgilaEditCtrlApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }


  // async deleteControlli(id, user) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("val_id", id);


  //   return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }



  // async insertControllo(controllo, user) {

  //   let formData = new FormData();

  //   formData.append("username", user);

  //   for (var key in controllo) {
  //     formData.append(key, controllo[key]);
  //   }

  //   return axios.post(this.getUrlDomain("EmtAgilaInsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  // async updateControllo(controllo, user, id) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("val_id", id);

  //   for (var key in controllo) {
  //     formData.append(key, controllo[key]);
  //   }

  //   return axios.post(this.getUrlDomain("EmtAgilaUpdCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  // }





}

export default new APIControlliCondizioni({
  url: "https://services.ebadge.it/public/api/"
})
