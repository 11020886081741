import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIControlliStandard {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initDDL(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaCtrlsInitDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getInfoAzienda(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaSediOpGetInfoAzApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveSedeOperativa(sede_operativa, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in sede_operativa) {
      formData.append(key, sede_operativa[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveSediOpApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editControlloStandard(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditCtrlsStdApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteControlliStandard(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsStdApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertControlloStandard(controllo, user) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaInsCtrlsStdApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updateControlloStandard(controllo, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in controllo) {
      formData.append(key, controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaUpdCtrlsStdApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCondizioni(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    return axios.post(this.getUrlDomain("EmtAgilaDeleteCtrlsStdCondApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async exportXls(user, curSrcText, curCompany, curPrjType, curCtrType, curStatus) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("curSrcText", curSrcText);
    formData.append("curCompany", curCompany);
    formData.append("curPrjType", curPrjType);
    formData.append("curCtrType", curCtrType);
    formData.append("curStatus", curStatus);


    return axios.post(this.getUrlDomain("EmtAgilaExportXlsCtrlsApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIControlliStandard({
  url: "https://services.ebadge.it/public/api/"
})
