import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIDescrModalitaFatturazione {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveDescrModFatt(descr_mod_fatt, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in descr_mod_fatt) {
      formData.append(key, descr_mod_fatt[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveDescModFattApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editDescrModFatt(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditDescModFattApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteDescrModFatt(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteDescModFattApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIDescrModalitaFatturazione({
  url: "https://services.ebadge.it/public/api/"
})
