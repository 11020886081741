<template>
    <v-container class="w-container-edit-user-su-emt v-overflow edit_utente_su_emt"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">



        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;">


                <div class="mainTitle" style="
                            background-color: white!important;
                            color: black;
                            font-weight: bold;
                            padding: 4px;
                            font-size: 10px;                
                            min-width:100vw;
                            border-color:#f5cf2e;">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="utente.nome_utente" label="Nome" required :rules="nameRules">
                    </v-text-field>

                </v-col>


            </v-row>


            <v-row>


                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="utente.cognome_utente" label="Cognome" required :rules="surnameRules">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="viewRow">


                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="utente.login" label="Username" required :rules="usernameRules"
                        :readonly="IsReadonly">
                    </v-text-field>

                </v-col>

            </v-row>



            <v-row v-if="viewRow">


                <v-col cols="12" md="12" xs="12">

                    <v-text-field id="newPas" type="password" v-model="utente.password" label="Password" required
                        :rules="passwordRules">
                    </v-text-field>
                    <img @click="showPassword3" class="showpwd4" src="@/assets/icons8-eye-24.png" />

                </v-col>

            </v-row>

            <v-row>


                <v-col cols="12" md="12" xs="12">

                    <v-text-field v-model="utente.email" label="E-Mail" required :rules="emailRules">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="6" xs="6">

                    <v-select v-model="utente.prefix_mobile" :items="prefissi" label="Prefisso" item-text="prefix"
                        item-value="prefix"></v-select>

                </v-col>

                <v-col cols="12" md="6" xs="6">

                    <v-text-field v-model="utente.mobile" label="Telefono"></v-text-field>

                </v-col>

            </v-row>


        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                    id="fldTextSearchAzi">
                                </v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        var a = window.$cookies.get("a");

        if (a == 'Y' || a == 'S') {
            this.isSU = "S";
        }




        var pointerVue = this;

        this.idUserSelect = this.$route.params.id;

        this.setupButtons();

        // if (this.$route.params.id == 0) {

        //     setTimeout(() => {

        //         this.initInsert();

        //     }, 100);

        // }

        if (this.$route.params.id > 0) {

            setTimeout(() => {
                this.syncUtente();
            }, 100);



        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Utente SU";

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/usersSU"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {




            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGroup) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGroup.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({

        prefissi: [{ prefix: "+39" }],

        viewRow: true,

        passwordRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8) || 'minimo 8 caratteri',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',
            (value) => (value && /[a-z]/.test(value)) || 'Almeno un carattere minuscolo',
            (value) => (value && /[0-9]/.test(value)) || 'Almeno un numero',
            (value) => (value && /[!@#$^&*+]/.test(value)) || 'Almeno un carattere speciale ! @ # $ ^ & * +',
        ],


        idUserSelect: 0,

        isSU: "N",


        idGroupSel: [],

        searchGroup: "",

        IsReadonly: false,

        valoresino: [{ id: "S", name: "Si" }, { id: "N", name: "No" }],

        changePsw: false,

        titolo: "Aggiungi Utente SU",
        currDipendenteObj: {},

        gruppi_checked: [],

        aziende: [],
      
        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: { is_admin_company: "N", active: "Y", is_su: "N", force_change_psw: "N" },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "Campo richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Campo richiesto",
        ],


        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Campo richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Campo richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ]

    }),

    methods: {

        showPassword3() {

            var confPasType = $("#newPas").attr("type");

            console.log(confPasType);

            if (confPasType == "password") {
                $("#newPas").attr("type", "text");
            }
            else {
                $("#newPas").attr("type", "password");
            }



        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.utente.idcompany_logo = azienda.companyid;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30

                    },


                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 35
                    },



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            //console.log("UTENTE 1: ", that.utente);

            //console.log("COMPANYID: ", $("#companyid").val());

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {


                console.log("ok");



                that.saveData();





            }
        },


        saveData: async function () {

            var that = this;


            if (that.$route.params.id == 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.insertUtenteSU(
                    that.utente
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertUtenteSU", res);

                    if (res.data.Result == "OK") {

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });

                        setTimeout(() => {

                            that.dialogMsg = false;
                            that.setupButtons();

                            router.push({
                                path: "/usersSU"
                            });

                        }, 200);


                    }
                    else {


                        that.$swal({
                            icon: "error",
                            text: res.data.ErrDetails,
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }


                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                response = await apiusers.updateUtenteSU(
                    that.utente,
                    that.$route.params.id
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updateUtenteSU", res);

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/usersSU"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

        },

        syncUtente: async function () {

            var that = this;

            // var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtenteSU(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtenteSU", res);

                try {


                    this.utente = res.data.Utente;

                    this.utente.nome_utente = res.data.Utente.name;

                    this.utente.cognome_utente = res.data.Utente.surname;

                    this.viewRow = false;



                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        initInsert: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteNewApi(
                v_token,
                "emt"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from createUtenteNewApi", res);


                try {

                    that.aziende = res.data.aziende;

                    that.prefissi = res.data.prefix;

                    that.gruppi = res.data.models;

                    setTimeout(() => {

                        that.utente.idcompany_logo = window.$cookies.get("sel_filter_azienda_utenti");

                        $(".companyid").val(window.$cookies.get("sel_filter_azienda_utenti"));

                    }, 200);



                    var a = window.$cookies.get("a");

                    if ((a == "S") || (a == "Y")) {


                        var type_user_ins = window.$cookies.get("type_user_ins");

                        if (type_user_ins == "DIP") {

                            this.idGroupSel.push(12);

                        }

                        if (type_user_ins == "PIVA") {

                            this.idGroupSel.push(32);

                        }

                    }







                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
img.showpwd4 {
    max-width: 22px;
    cursor: pointer;
    transform: translate(445px, -59px);
}

.w-container-edit-user-su-emt .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-edit-user-su-emt {
    width: 500px;
    padding: 0px;
    overflow-x: hidden;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.edit_utente_su_emt .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}




.edit_utente_su_emt .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.edit_utente_su_emt .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.edit_utente_su_emt ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}



.edit_utente_su_emt .cl-checkbox i {
    font-size: 13px !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
