import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIStatiPagamenti {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveStatoPagamento(stato_pagamento, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in stato_pagamento) {
      formData.append(key, stato_pagamento[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveStatPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editStatoPagamento(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditStatPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteStatoPagamento(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteStatPagApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIStatiPagamenti({
  url: "https://services.ebadge.it/public/api/"
})
