import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIStatiPratiche {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveStatoPratica(stato_pratica, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in stato_pratica) {
      formData.append(key, stato_pratica[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveStatPratApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editStatoPratica(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditStatPratApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteStatiPratica(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteStatPratApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APIStatiPratiche({
  url: "https://services.ebadge.it/public/api/"
})
