<template>
    <v-container class="w-container-users v-overflow editCespiti"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form style="max-width:700px;" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <div id="d_year"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Ore per cui non si matura un
                                        ticket</label>


                                    <vue-autonumeric id="valYear" v-model="cespiti.hours" :options="{
                                        digitGroupSeparator: '',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '',
                                        currencySymbol: '',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0',
                                        decimalPlaces: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


        </v-form>

    </v-container>
</template>

<script>
import apiusers from "../utils/users/apiusers";
import apicespiti from "../utils/cespiti/apicespiti";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import apitimesheet from "../utils/timesheet/apitimesheet";

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;

        //window.$cookies.get("aziende_user")

        this.setupButtons();

        setTimeout(() => {


            this.syncConfTicket();


        }, 100);




        // if (this.$route.params.id == 0) {

        //     this.titolo = "Aggiungi Cespiti";

        //     setTimeout(() => {


        //         this.syncInitInsert();


        //     }, 100);

        // }

        // if (this.$route.params.id > 0) {

        //     this.titolo = "Modifica Cespiti";

        //     setTimeout(() => {


        //         this.syncAsset();


        //     }, 100);

        // }



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_idresource', function (event) {
                console.log(event);

                pointerVue.sheetRisorse = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);


            $(".idassettype").on("ionChange", function (opt) {
                console.log(opt);

                if ((this.value == 4) || (this.value == 1) || (this.value == 2) || (this.value == 3)) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = true;
                    pointerVue.visOP = true;
                    pointerVue.visSw = true;

                    pointerVue.visMemNB = true;
                    pointerVue.visDiscoNB = true;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;


                    pointerVue.visPollici = false;


                }


                if ((this.value == 5) || (this.value == 6)) {

                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = true;
                    pointerVue.visTelNum = true;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visPollici = false;


                }

                if (this.value == 7) {

                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;


                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = true;
                    pointerVue.visVeicolo = true;

                    pointerVue.visPollici = false;


                }

                if (this.value == 9) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visPollici = false;


                }


                if (this.value == 10) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = true;

                    pointerVue.visPollici = false;


                }


                if (this.value == 11) {

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = true;



                }



            });




        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.resource.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },

    data: () => ({

        pdfUrl: "",

        visMemNB: false,
        visDiscoNB: false,

        risNomeSede: "",

        attachRemove: false,

        sheetRisorse: false,

        fidoAnt: null,
        fidoCassa: null,

        visDescrizione: false,
        visNMatricola: false,
        visOP: false,
        visSw: false,

        visNSim: false,
        visTelNum: false,

        visTarga: false,
        visVeicolo: false,

        visTipoDisco: false,

        visPollici: false,



        sheetGruppi: false,

        searchGruppi: "",

        tipo_cespiti: [],

        risorse: [],

        banca: {},

        cespiti: {
            hours: null
        },

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "Configurazione Ticket",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ],

        tipi_dischi: [{
            "id": "RPM",
            "value": "RPM"
        },
        {
            "id": "SSD",
            "value": "SSD"
        }
        ]

    }),

    methods: {


        syncConfTicket: async function () {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitimesheet.getConfTicket(
                0
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;

                console.log("res from getConfTicket", res);

                that.cespiti.hours = res.data.Result.hours;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },


        btnResetSignatureViewFile: function () {

            window.signaturePad.clear();

        },

        saveSignatureViewFile: async function () {

            var that = this;

            that.$modal.hide('popupSignatureViewFile');


            var signature = window.signaturePad.toDataURL();

            console.log("SIGNATURE: ", signature);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.saveSignatureCespiti(
                that.$route.params.id,
                signature
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;

                console.log("res from saveSignatureCespiti", res);



                that.viewAllegato();



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        btnConfirmSignatureViewFile: function () {

            var errore = "";

            if (window.signaturePad.isEmpty()) {
                errore = "Firma richiesta";
            }

            if (errore == "") {

                console.log("ok");

                this.saveSignatureViewFile();

            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        viewAllegato: async function () {

            var that = this;



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.downAttach(
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from downAttach", res);

                setTimeout(() => {


                    $(".contpopupviewfilepdf").show();

                    that.$modal.show('popupViewFilePdf');

                    that.pdfUrl = res.data.UrlVal;

                    setTimeout(() => {


                        that.loadPdf();

                        that.centerPopupViewFilePdf();


                    }, 200);



                }, 100);







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },


        centerPopupViewFilePdf: function () {



            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },


        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },

        btnCancelViewFilePdf: function () {

            var that = this;

            that.$modal.hide('popupViewFilePdf');

            setTimeout(() => {

                that.dialogMsg = false;
                that.setupButtons();

                router.push({
                    path: "/cespiti"
                });

            }, 200);

        },




        manageClickRisorse: async function (risorsa) {

            console.log("RIS SEL: ", risorsa);

            this.sheetRisorse = false;

            this.cespiti.idresource = risorsa.resourceid;

            this.syncOfficeRes(risorsa.resourceid);

        },




        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.banca.companyid = azienda.companyid;

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30,

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";

            if (that.cespiti.hours == "") {
                errore = "Specificare un valore delle ore maggiore di 0 e minore o uguale a 8";
            }

            if (errore == "") {
                if (that.cespiti.hours == null) {
                    errore = "Specificare un valore delle ore maggiore di 0 e minore o uguale a 8";
                }
            }


            if (errore == "") {
                if (that.cespiti.hours > 8) {
                    errore = "Specificare un valore delle ore maggiore di 0 e minore o uguale a 8";
                }
            }




            if (errore == "") {



                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveData: async function () {



            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apitimesheet.saveConfTicket(
                that.cespiti
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveConfTicket", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );









        },

        syncUtente: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getUtente(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUtente", res);

                try {

                    this.aziende = res.data.aziende;
                    this.prefissi = res.data.prefix;
                    this.gruppi = res.data.models;
                    this.utente = res.data.curr_user;

                    this.utente.codice_fiscale = res.data.val_codice_fiscale;

                    if (res.data.curr_user.models != null) {

                        var grp_selected = res.data.curr_user.models.split(",");

                        console.log("GRP SEL: ", grp_selected);

                        if (grp_selected.length > 0) {

                            for (var i = 0; i < this.gruppi.length; i++) {

                                if (this.gruppi[i] !== undefined) {

                                    for (var x = 0; x < grp_selected.length; x++) {

                                        console.log("GRP SEL 2: ", grp_selected[x]);

                                        if (parseInt(this.gruppi[i].id) == parseInt(grp_selected[x])) {

                                            this.gruppi[i].checked = this.gruppi[i].id;

                                        }

                                    }

                                }

                            }

                        }


                    }




                    $("#itemAzienda").addClass("item-has-value");


                    console.log("USER INFO EASYER: ", res.data.info_easyer);


                    if (res.data.info_easyer != null) {

                        this.utente.enable_easyer = res.data.info_easyer.enable;

                        this.utente.max_easyer = res.data.info_easyer.max_value;

                        this.utente.datestart_easyer = res.data.info_easyer.period_from;

                        this.utente.dateend_easyer = res.data.info_easyer.period_to;


                    }






                    //console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.createUtenteApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncAziende", res);

                try {

                    this.aziende = res.data.aziende;

                    this.prefissi = res.data.prefix;

                    this.gruppi = res.data.models;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncMailGroup: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getGruppi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from syncMailGroup", res);

                this.gruppi = res.data.invoice_mail_group;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncInitInsert: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;



                // this.aziende = res.data.aziende;

                // this.gruppi = res.data.invoice_mail_group;

                // this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncOfficeRes: async function (id_resource) {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.getOfficeRes(
                v_token,
                id_resource
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getOfficeRes", res);

                this.risNomeSede = res.data.DescOffice;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncAsset: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var id_cmp_sel = window.$cookies.get("cid");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicespiti.initEdit(
                that.$route.params.id,
                v_token,
                id_cmp_sel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initEdit", res);


                this.tipo_cespiti = res.data.asset_type;

                this.risorse = res.data.resource;

                this.cespiti = res.data.asset;

                this.cespiti.idasset_type = res.data.asset.idassettype;

                this.cespiti.tipo_disco = res.data.asset.tipo_disco;

                this.cespiti.pollici = res.data.asset.schermo_pollici;

                $("#itemIdAssetType").addClass("item-has-value");

                $("#itemRisorsa").addClass("item-has-value");

                this.syncOfficeRes(res.data.asset.idresource);


                if (res.data.asset.filename != null) {

                    $('.dropify-render').append("<i class='dropify-font-file'></i>");
                    $('.dropify-render').append("<span class='dropify-extension'>pdf</span>");

                    // dropify-font-file
                    $(".dropify-wrapper").addClass("has-preview");
                    $(".dropify-preview").show("slow");

                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncPrefix: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getPrefix().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPrefix", res);

                try {

                    this.prefissi = res.data.tel_prefix;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
#firmaCanvas {
    border: 1px solid black;
}


.titleSignature {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0px;
}

.subtitleSignature {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    margin-top: 0px;
}



canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemRisorsa {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetRisorse {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-users {
    width: 700px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editCespiti {
    overflow-x: hidden;
}

.editCespiti .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editCespiti .v-input {
    font-size: 1.2em !important;
}

.editCespiti .v-select {
    font-size: 1.2em !important;
}

.editCespiti .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
