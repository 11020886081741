<template>
    <v-container id="conf_settings_alertmsg" class="w-container editDisease" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_resourceid" style="cursor:pointer">
                        <ion-item id="itemResource"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:11px;cursor:pointer;opacity:1" position="floating">Risorsa
                            </ion-label>

                            <ion-select id="resourceid" name="resourceid" class='resourceid' cancel-Text="Chiudi"
                                done-Text="" :value="malattia.login" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in risorse" :key="item.login" :value='item.login'> {{
                item.full_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>





            <v-row v-if="fieldInsert">

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="malattia.periodo_dal" label="Data Inizio" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="malattia.periodo_dal" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>


                </v-col>

            </v-row>

            <v-row v-if="fieldInsert">

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                        offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="malattia.periodo_al" label="Data Fine" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="malattia.periodo_al" no-title @input="showPicker2 = false"
                            locale="it"></v-date-picker>
                    </v-menu>


                </v-col>

            </v-row>




            <v-row v-if="!fieldInsert">

                <v-col cols="12" md="12">

                    <v-text-field id="periodo_dal" v-model="malattia.periodo_dal" label="Data Inizio" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row v-if="!fieldInsert">

                <v-col cols="12" md="12">

                    <v-text-field id="periodo_al" v-model="malattia.periodo_al" label="Data Fine" readonly>
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-text-field id="numero_protocollo" v-model="malattia.numero_protocollo" label="Numero Protocollo">
                    </v-text-field>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea id="note" v-model="malattia.note" label="Note"></v-textarea>

                </v-col>

            </v-row>

        </v-form>


        <div class="contresources">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Risorse
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                        id="fldTextSearchAzi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.login"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/profilo3.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>
                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

    </v-container>
</template>

<script>

import apidisease from "../utils/disease/apidisease";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        this.$root.$children[0].checkLogout();

        var pointerVue = this;


        /*eslint-disable no-undef*/

        if (this.$route.params.id == 0) {

            this.titolo = "Inserimento Malattia";

            this.initInsertDisease();

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Malattia";

            this.fieldInsert = false;

            this.initEditDisease();

        }

        $(document).ready(function () {

            $('body').on('click', '#cont_resourceid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

            });





        });


        this.setupButtons();





        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":


                    window.$cookies.set("sel_filter_azienda_storico_periodo_dal", "", "9y");
                    window.$cookies.set("sel_filter_azienda_storico_periodo_al", "", "9y");


                    router.push({
                        path: "/diseaseHistory"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.full_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({


        risorse: [],


        fieldInsert: true,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        malattia: { periodo_dal: "", periodo_al: "", login: "", numero_protocollo: "", note: "" },

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Modifica Malattia",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {


        manageClickAziende: async function (risorsa) {

            console.log("AZI SEL: ", risorsa);

            this.sheetAziende = false;

            this.malattia.login = risorsa.login;


        },


        generateRandomString: function (length, characters) {

            let result = "";
            const charLength = characters.length;

            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charLength);
                result += characters.charAt(randomIndex);
            }

            return result;


        },


        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30,

                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/confOrariChoice",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                }

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },


        addZero: function (number) {
            return (number < 10 ? '0' : '') + number;
        },

        validate() {

            var that = this;

            var errore = "";


            if (that.malattia.login == "") {

                errore = "Specificare la risorsa";

            }

            if (errore == "") {

                if (that.malattia.periodo_dal == "") {
                    errore = "Specificare valore Data Inizio";
                }

            }

            if (errore == "") {

                if (that.malattia.periodo_al == "") {
                    errore = "Specificare valore Data Fine";
                }

            }


            if (errore == "") {

                var dt_start = "";
                var dt_end = "";

                dt_start = new Date(that.malattia.periodo_dal).getTime();
                dt_end = new Date(that.malattia.periodo_al).getTime();

                if (dt_start > dt_end) {

                    errore = "Data Fine deve essere maggiore o uguale a Data Inizio";


                }

            }



            if (errore == "") {

                console.log("MALATTIA: ", that.malattia);

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }





        },

        saveData: function () {

            var that = this;

            var v_token = btoa(that.malattia.login);

            var startDate = that.malattia.periodo_dal;
            var endDate = that.malattia.periodo_al;
            var certificato = that.malattia.numero_protocollo;
            var notes = that.malattia.note;

            if (that.$route.params.id == 0) {


                var API_URL = this.getUrlDomainApi("insertDiseaseNew");

                var v_key = this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                v_key = v_key + "|" + v_token.toString();
                v_key = v_key + "|" + startDate.toString();
                v_key = v_key + "|" + endDate.toString();
                v_key = v_key + "|" + certificato.toString();
                v_key = v_key + "|" + notes.toString();
                v_key = v_key + "|0";
                v_key = v_key + "|" + this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                v_key = btoa(v_key);
                v_key = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");



                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                $.ajax({
                    type: "POST",
                    url: API_URL,
                    data: {
                        "v_token": v_key

                    },
                    success: function (resultData) {

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;

                        console.log("resultData", resultData, that);

                        that.$swal({
                            icon: "success",
                            text: "Dati salvati correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });


                        setTimeout(() => {


                            window.$cookies.set("sel_filter_azienda_storico_periodo_dal", "", "9y");
                            window.$cookies.set("sel_filter_azienda_storico_periodo_al", "", "9y");


                            router.push({
                                path: "/diseaseHistory"
                            });

                        }, 2000);

                    },
                    error: function (errorData) {
                        console.log(errorData);
                        var msg = errorData.responseJSON.Error;

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;

                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 3000
                        });
                        that.showSpinner = false;

                    }
                });







            }

            if (that.$route.params.id > 0) {

                var id_disease = that.$route.params.id;

                console.log("START DATE:", startDate);
                console.log("END DATE:", endDate);
                console.log("CERTIFICATO:", certificato);
                console.log("NOTE:", notes);
                console.log("ID:", id_disease);
                console.log("TOKEN:", v_token);

                var API_URL2 = this.getUrlDomainApi("updateDiseaseNew");

                var v_key2 = this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                v_key2 = v_key2 + "|" + v_token.toString();
                v_key2 = v_key2 + "|" + startDate.toString();
                v_key2 = v_key2 + "|" + endDate.toString();
                v_key2 = v_key2 + "|" + certificato.toString();
                v_key2 = v_key2 + "|" + id_disease.toString();
                v_key2 = v_key2 + "|" + notes.toString();
                v_key2 = v_key2 + "|0";
                v_key2 = v_key2 + "|" + this.generateRandomString(100, "gWnKdU7d4Sb58hFbL4G6");
                v_key2 = btoa(v_key2);
                v_key2 = this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6") + v_key2 + this.generateRandomString(10, "gWnKdU7d4Sb58hFbL4G6");



                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                $.ajax({
                    type: "POST",
                    url: API_URL2,
                    data: {
                        "v_token": v_key2

                    },
                    success: function (resultData) {

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;

                        console.log("resultData", resultData, that);

                        that.$swal({
                            icon: "success",
                            text: "La richiesta è stata salvata correttamente",
                            showConfirmButton: false,
                            timer: 2000
                        });


                        setTimeout(() => {

                            window.$cookies.set("sel_filter_azienda_storico_periodo_dal", "", "9y");
                            window.$cookies.set("sel_filter_azienda_storico_periodo_al", "", "9y");


                            router.push({
                                path: "/diseaseHistory"
                            });

                        }, 2000);


                    },
                    error: function (errorData) {
                        console.log(errorData);
                        var msg = errorData.responseJSON.Error;
                        that.$swal({
                            icon: "error",
                            text: msg,
                            showConfirmButton: false,
                            timer: 3000
                        });

                        that.showSpinner = false;
                        that.$root.$children[0].showProgress = false;
                    }
                });


            }






        },


        initInsertDisease: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidisease.initInsertDisease(
                v_token,
                window.$cookies.get("sel_filter_azienda_storico_malattia")
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsertDisease", res);

                that.risorse = res.data.resources;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        initEditDisease: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apidisease.getEditAdminDisease(
                v_token,
                that.$route.params.id,
                window.$cookies.get("sel_filter_azienda_storico_malattia")
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getEditAdminDisease", res);

                that.risorse = res.data.resources;

                setTimeout(() => {

                    that.malattia = res.data.disease;

                }, 200);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.editDisease {
    overflow-y: hidden !important;
}

.editDisease .v-input {
    font-size: 1em !important;
}

.editDisease .v-select {
    font-size: 1em !important;
}

.editDisease .v-label {
    font-size: 1em !important;
}


#conf_settings_alertmsg .v-label {
    font-size: 1em;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
