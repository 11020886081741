import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICostiAziendali {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveCostiAziendali(costi_aziendali, val_id) {

    let formData = new FormData();

    formData.append("val_id", val_id);

    for (var key in costi_aziendali) {
      formData.append(key, costi_aziendali[key]);
    }

    return axios.post(this.getUrlDomain("saveCostiAziendali"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async editCostiAziendali(val_id) {

    let formData = new FormData();

    formData.append("val_id", val_id);

    return axios.post(this.getUrlDomain("editCostiAziendali"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteCostiAziendali(val_id) {

    let formData = new FormData();

    formData.append("val_id", val_id);

    return axios.post(this.getUrlDomain("deleteCostiAziendali"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APICostiAziendali({
  url: "https://services.ebadge.it/public/api/"
})
