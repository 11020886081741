import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITipoProgetto {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveTipoProgetto(tipo_progetto, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in tipo_progetto) {
      formData.append(key, tipo_progetto[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveTipoProgettoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editTipoProgetto(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditTipoProgettoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteTipoProgetto(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteTipoProgettoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APITipoProgetto({
  url: "https://services.ebadge.it/public/api/"
})
