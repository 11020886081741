import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIHome {


  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  
  async getInfoHome(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("EmtHomeGetInfoApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getConfUser(user)
  {

    let formData = new FormData();

    formData.append("username",  user);

    return axios.post(this.getUrlDomain("EmtHomeGetConfUserApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }








}

export default new APIHome({
  url: "http://emtool.local/public/api/smartbook/"
})
