import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITipoControllo {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async initDDL(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtAgilaTipoCtrlInitiDDLApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveTipoControllo(tipo_controllo, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in tipo_controllo) {
      formData.append(key, tipo_controllo[key]);
    }

    return axios.post(this.getUrlDomain("EmtAgilaSaveTipoCtrlApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editTipoControllo(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);


    return axios.post(this.getUrlDomain("EmtAgilaEditTipoCtrlApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteTipoControllo(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtAgilaDeleteTipoCtrlApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APITipoControllo({
  url: "https://services.ebadge.it/public/api/"
})
