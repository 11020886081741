<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_verifica_timesheet">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start'
                                                    style="width:10px!important;height:10px!important">
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Cognome</th>
                                        <th class='all'>Nome</th>
                                        <th class='all' style="text-align:center">Stato</th>
                                        <th class='all' style="text-align:center">Ordinario</th>
                                        <th class='all' style="text-align:center">Straord.</th>
                                        <th class='all' style="text-align:center">Assenze</th>
                                        <th class='all' style="text-align:center">Lavorate</th>
                                        <th class='all' style="text-align:center">Rep.</th>
                                        <th class='all' style="text-align:center">Interv.</th>
                                        <th class='all' style="text-align:center">Totale</th>
                                        <th class='all'>Cliente</th>
                                        <th class='all'>Progetto</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="14">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetChangeStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetChangeStatus = !sheetChangeStatus">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.title" @click="changeStatusAdmin(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetStatus">
                <v-sheet class="text-center" height="385px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatusFilter" :key="tile.title" @click="changeStatus(tile.title)">
                        <v-list-item-avatar>
                            <v-avatar size="32px" tile>
                                <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupdatefrom" style="display:none">

            <template>
                <modal name="popupDateFrom" :clickToClose="false" :width="300" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataDa" v-model="dataDa" label="Da" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker4" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpDataA" v-model="dataA" label="A" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker4 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date-from" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-reset-date-from" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" />

                                <v-img alt="" class="btn-confirm-date-from" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/icon_delete_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina filtro" @click="btnResetSearch" />


                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupotpdelete" style="display:none">

            <template>
                <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Codice OTP inviatoLe via mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelOtpDelete" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmOtpDelete" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetAnni">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetAnni = !sheetAnni">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Anno
                    </div>
                    <v-list-item v-for="prj in anni" :key="prj.valore" @click="filterAnno(prj.valore)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetMesi">
                <v-sheet class="text-center" height="668px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetMesi = !sheetMesi">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Mese
                    </div>
                    <v-list-item v-for="prj in mesi" :key="prj.valore" @click="filterMese(prj.valore)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.descrizione
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>



        <div class="text-left">
            <v-bottom-sheet v-model="sheetFilterStatus">
                <v-sheet class="text-center" height="438px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetFilterStatus = !sheetFilterStatus">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allFilterStatus" :key="tile.id" @click="filterStatus(tile.id)">
                        <v-list-item-avatar>
                            <v-avatar :size="tile.aSize" tile>

                                <v-img alt="" contain :src="tile.img" :max-width="tile.width"
                                    :max-height="tile.height" />

                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>





    </div>
</template>

<script>
import router from ".././router";
import apirisorse from "../utils/risorse/apirisorse";
import apiusers from "../utils/users/apiusers";
import apitimesheet from "../utils/timesheet/apitimesheet";
import apiotp from "../utils/otp/apiotp";

import {
    bus
} from "../main";
//import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        window.curSrcText = "";

        window.myVal = "";

        window.perpage = 50;
        bus.$off("bottomIconClickEvent");


        var pointerVue = this;

        // default value 
        //window.curCompany = "-1";

        var curYear = new Date().getFullYear();
        var curMonth = new Date().getMonth() + 1;

        console.log("CUR YEAR: ", curYear);

        console.log("CUR MONTH: ", curMonth);

        window.curYear = curYear;
        window.curMonth = curMonth;

        window.curStatus = -1; // default tutti

        window.totTimesheet = 0;
        window.totTimesheetForApprove = 0;


        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.selanno', function () {

                console.log(("EVENTO CLICK SELANNO"));

                pointerVue.sheetAnni = true;

            });


            $('body').on('click', '.selmese', function () {

                console.log(("EVENTO CLICK SELMESE"));

                pointerVue.sheetMesi = true;

            });




            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

            });


            $('body').on('click', '.selaggiorna', function () {

                console.log(("EVENTO CLICK SELAGGIORNA"));

                window.$cookies.set("tot_val_ts", 0, "9y");
                window.$cookies.set("tot_val_open_appr", 0, "9y");

                setTimeout(() => {

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();

                }, 200);




            });



            $('body').on('click', '.selstato', function () {

                console.log(("EVENTO CLICK SELSTATO"));

                pointerVue.sheetStatus = true;

            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.seldata', function (event) {
                console.log(event);


                $(".contpopupdatefrom").show();

                pointerVue.$modal.show('popupDateFrom');



            });

            $('body').on('click', '.btn-cancel-date-from', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupDateFrom');

            });

            $('body').on('click', '.btn-reset-date-from', function (event) {
                console.log(event);

                pointerVue.dataDa = null;
                pointerVue.dataA = null;


            });


            $('body').on('click', '.btn-confirm-date-from', function (event) {
                console.log(event);

                window.dataDa = pointerVue.dataDa;
                window.dataA = pointerVue.dataA;

                pointerVue.$modal.hide('popupDateFrom');

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();


            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });




        });

        setTimeout(() => {

            this.syncAziende();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.getOtpDelete();

                    break;

                case "btn_changestatusadmin":

                    this.sheetChangeStatus = true;

                    break;

                case "btn_send_approvation":
                    break;

                case "btn_exit":
                    break;

                case "btn_add":
                    break;

                case "btn_edit":

                    var nome = "";
                    var cognome = "";
                    var anno = "";
                    var mese = "";
                    var azienda = "";
                    var login = "";
                    var inizio_rapp = "";
                    var fine_rapp = "";
                    var is_enable_rep = "";
                    var n_proj_assoc = "";
                    var is_enable_interv = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            nome = $(this).attr("name-res-sel");
                            cognome = $(this).attr("surname-res-sel");
                            anno = $(this).attr("anno-sel");
                            mese = $(this).attr("mese-sel");
                            azienda = $(this).attr("name-azi-sel");
                            login = $(this).attr("login-user-sel");
                            inizio_rapp = $(this).attr("ini-rapp-res-sel");
                            fine_rapp = $(this).attr("fine-rapp-res-sel");
                            is_enable_rep = $(this).attr("is-enable-rep");
                            n_proj_assoc = $(this).attr("n-proj-assoc");
                            is_enable_interv = $(this).attr("is-enable-interv");

                        }

                    });

                    console.log("NOME:", nome);
                    console.log("COGNOME:", cognome);
                    console.log("ANNO:", anno);
                    console.log("MESE:", mese);
                    console.log("AZIENDA:", azienda);
                    console.log("LOGIN:", login);
                    console.log("INIZIO RAPPORTO:", inizio_rapp);
                    console.log("FINE RAPPORTO:", fine_rapp);

                    console.log("IS ENABLE REP:", is_enable_rep);
                    console.log("N PROJ ASSOC:", n_proj_assoc);
                    console.log("IS ENABLE INTERV:", is_enable_interv);

                    window.$cookies.set("sel_cognome_nome_ver_ts", cognome + " " + nome, "9y");
                    window.$cookies.set("sel_anno_ver_ts", anno, "9y");
                    window.$cookies.set("sel_mese_ver_ts", mese, "9y");
                    window.$cookies.set("sel_azienda_ver_ts", azienda, "9y");
                    window.$cookies.set("sel_login_ver_ts", login, "9y");
                    window.$cookies.set("sel_inizio_rapp_ver_ts", inizio_rapp, "9y");
                    window.$cookies.set("sel_fine_rapp_ver_ts", fine_rapp, "9y");

                    router.push({
                        path: "/editVerificaTimesheetUnProj"
                    });

                    // if (n_proj_assoc > 1) {


                    //     if ((is_enable_rep == "N") && (is_enable_interv == "N")) {

                    //         router.push({
                    //             path: "/editVerificaTimesheet"
                    //         });


                    //     }
                    //     else {

                    //         if ((is_enable_rep == "S") && (is_enable_interv == "N")) {


                    //             router.push({
                    //                 path: "/editVerificaTimesheetWithRep"
                    //             });


                    //         }

                    //         if ((is_enable_rep == "S") && (is_enable_interv == "S")) {


                    //             router.push({
                    //                 path: "/editVerificaTimesheetWithRepInterv"
                    //             });


                    //         }


                    //     }

                    // }

                    // if (n_proj_assoc <= 1) {

                    //     if ((is_enable_rep == "N") && (is_enable_interv == "N")) {

                    //         router.push({
                    //             path: "/editVerificaTimesheetUnProj"
                    //         });


                    //     }
                    //     else {


                    //         if ((is_enable_rep == "S") && (is_enable_interv == "N")) {


                    //             router.push({
                    //                 path: "/editVerificaTimesheetUnProjWithRep"
                    //             });


                    //         }


                    //         if ((is_enable_rep == "S") && (is_enable_interv == "S")) {


                    //             router.push({
                    //                 path: "/editVerificaTimesheetUnProjWithRepInterv"
                    //             });


                    //         }


                    //     }


                    // }









                    break;

                case "btn_back":


                    router.push({
                        path: "/filterVerificaTimesheet"
                    });



                    break;

                case "btn_send_invite":

                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("id-user-sel");
                        }

                    });


                    window.$cookies.set("id_users_sel_invite", ids, "9y");

                    window.$cookies.set("year_sel_invite", window.curYear, "9y");
                    window.$cookies.set("month_sel_invite", window.curMonth, "9y");

                    router.push({
                        path: "/invitoClientiFornitori"
                    });

                    break;





                default:
                    break;

            }
        });


        // btn_send_approvation


        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));


        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);


    },
    data: () => ({

        sheetFilterStatus: false,

        sheetMesi: false,

        sheetAnni: false,

        anni: [],

        mesi: [],

        valueOtpDelete: "",

        descAziSel: "",

        textSearch: "",

        curSelRow: null,

        dataDa: null,
        showPicker3: false,

        dataA: null,
        showPicker4: false,

        aziende: [],

        search: "",

        sheetAziende: false,


        allStatus: [{
            img: 'keep.png',
            title: 'Open',
            "id": 1,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'To Approve',
            "id": 2,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Closed',
            "id": 3,
            icon: "mdi-account-lock",
            "color": "green"
        },
        ],


        allStatusFilter: [{
            img: '',
            title: 'Tutti',
            "id": 1,
            icon: "mdi-table",
            "color": "black"
        },
        {
            img: 'keep.png',
            title: 'Refused',
            "id": 2,
            icon: "mdi-account-lock-open",
            "color": "red"
        },
        {
            img: 'hangouts.png',
            title: 'to Approve',
            "id": 3,
            icon: "mdi-account-arrow-right",
            "color": "yellow"
        },
        {
            img: 'inbox.png',
            title: 'Approved',
            "id": 4,
            icon: "mdi-account-lock",
            "color": "green"
        },

        {
            img: 'inbox.png',
            title: 'Open',
            "id": 5,
            icon: "mdi-account-lock-open",
            "color": "orange"
        },

        ],





        allFilterStatus: [{
            img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
            title: 'Tutti',
            "id": -1,
            icon: "",
            "color": "black",
            height: 28,
            width: 28,
            aSize: "28px",
        },
        {
            img: 'https://app.emtool.eu/public/_lib/img/icon_ts_open_red.png',
            title: 'Open',
            "id": 1,
            icon: "",
            "color": "",
            height: 25,
            width: 25,
            aSize: "25px",
        },


        {
            img: 'https://app.emtool.eu/public/_lib/img/icon_ts_to_approve_yellow.png',
            title: 'To Approve',
            "id": 2,
            icon: "",
            "color": "",
            height: 25,
            width: 25,
            aSize: "25px",
        },


        {
            img: 'https://app.emtool.eu/public/_lib/img/icon_ts_closed_green.png',
            title: 'Closed',
            "id": 3,
            icon: "",
            "color": "",
            height: 25,
            width: 25,
            aSize: "25px",
        },

        {
            img: 'https://app.emtool.eu/public/_lib/img/icon_ts_not_open_gray.png',
            title: 'Not Open',
            "id": 4,
            icon: "",
            "color": "",
            height: 25,
            width: 25,
            aSize: "25px",
        },



        ],







        tiles: [{
            img: 'keep.png',
            title: 'Keep'
        }],



        sheetChangeStatus: false,
        sheetStatus: false,

        currItem: { stato: "" },


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,





    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },

    methods: {

        btnResetSearch: function () {

            window.curSrcText = "";



            this.$modal.hide('popupSearch');

            window.$cookies.set("tot_val_ts", 0, "9y");
            window.$cookies.set("tot_val_open_appr", 0, "9y");


            window.totTimesheet = 0;
            window.totTimesheetForApprove = 0;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();
        },

        updateHeaderTable: function () {

            if (window.totTimesheet == 0) {

                window.totTimesheet = window.table.rows().count();
            }


            if (window.totTimesheetForApprove == 0) {

                var n_tot_for_approval = 0;

                window.table.rows().every(function () {
                    var rowData = this.data();
                    console.log("Dati della riga:", rowData);

                    var status = rowData.description.toString().toUpperCase();

                    console.log("Dati status:", status);


                    if (status.indexOf("TO APPROVE") !== -1) {
                        n_tot_for_approval = n_tot_for_approval + 1;
                    }

                    if (status.indexOf("CLOSED") !== -1) {
                        n_tot_for_approval = n_tot_for_approval + 1;
                    }

                    return true;
                });

                window.totTimesheetForApprove = n_tot_for_approval;

            }







            var txtAziPeriodo = "";

            pointerVue.$root.$children[0].showAziendaSelezionata = true;
            pointerVue.$root.$children[0].nomeAziendaSelezionata = "";

            if (pointerVue.descAziSel != "") {

                if (pointerVue.descAziSel != "Tutti") {

                    pointerVue.$root.$children[0].nomeAziendaSelezionata = pointerVue.descAziSel;

                }

            }


            var descMonth = "";
            if (window.curMonth == 1) {
                descMonth = "Gennaio";
            }
            if (window.curMonth == 2) {
                descMonth = "Febbraio";
            }
            if (window.curMonth == 3) {
                descMonth = "Marzo";
            }
            if (window.curMonth == 4) {
                descMonth = "Aprile";
            }
            if (window.curMonth == 5) {
                descMonth = "Maggio";
            }
            if (window.curMonth == 6) {
                descMonth = "Giugno";
            }
            if (window.curMonth == 7) {
                descMonth = "Luglio";
            }
            if (window.curMonth == 8) {
                descMonth = "Agosto";
            }
            if (window.curMonth == 9) {
                descMonth = "Settembre";
            }
            if (window.curMonth == 10) {
                descMonth = "Ottobre";
            }
            if (window.curMonth == 11) {
                descMonth = "Novembre";
            }
            if (window.curMonth == 12) {
                descMonth = "Dicembre";
            }

            if (txtAziPeriodo != "") {
                txtAziPeriodo = txtAziPeriodo + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
            }

            txtAziPeriodo = txtAziPeriodo + descMonth + " " + valori.curYear.toString();


            if (txtAziPeriodo != "") {
                txtAziPeriodo = txtAziPeriodo + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
            }


            txtAziPeriodo = txtAziPeriodo + window.totTimesheetForApprove.toString() + " / " + window.totTimesheet.toString();



            $("#infoAziPeriodo").html(txtAziPeriodo);


        },


        filterStatus: function (valore) {


            console.log("STATUS SEL: ", valore);

            this.sheetFilterStatus = false;

            window.curStatus = valore;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },




        filterMese: function (valore) {


            console.log("MESE SEL: ", valore);

            this.sheetMesi = false;

            window.$cookies.set("tot_val_ts", 0, "9y");
            window.$cookies.set("tot_val_open_appr", 0, "9y");


            window.curMonth = valore;

            this.hideShowButtons(false);

            window.curStatus = -1; // default tutti

            window.totTimesheet = 0;
            window.totTimesheetForApprove = 0;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },



        filterAnno: function (valore) {


            console.log("ANNO SEL: ", valore);

            this.sheetAnni = false;

            window.curYear = valore;

            window.$cookies.set("tot_val_ts", 0, "9y");
            window.$cookies.set("tot_val_open_appr", 0, "9y");


            this.hideShowButtons(false);

            window.curStatus = -1; // default tutti

            window.totTimesheet = 0;
            window.totTimesheetForApprove = 0;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            window.myVal = "";

            that.valueOtpDelete = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMail(
                v_token,
                'emt',
                'elimina_timesheet',
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMail", res);

                window.myVal = res.data.Result;


                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.delMsg();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },






        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },


        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },






        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            window.$cookies.set("sel_filter_azienda_verifica_ts", azienda.companyid, "9y");

            this.hideShowButtons(false);

            window.$cookies.set("tot_val_ts", 0, "9y");
            window.$cookies.set("tot_val_open_appr", 0, "9y");

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;



            var descAzi = "";

            for (var x = 0; x <= this.aziende.length - 1; x++) {

                if (parseInt(this.aziende[x].companyid) == parseInt(azienda.companyid)) {

                    descAzi = this.aziende[x].fld_name;

                }

            }

            console.log("DESC AZI: ", descAzi);

            this.descAziSel = descAzi;

            this.$root.$children[0].nomeAziendaSelezionata = descAzi;

            window.curStatus = -1; // default tutti

            window.totTimesheet = 0;
            window.totTimesheetForApprove = 0;

            window.table.ajax.reload();


        },



        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getFilterAziendeNew(
                v_token,
                "emt"
            ).then((res) => {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;

                console.log("res from getFilterAziendeNew", res);

                try {

                    this.aziende = res.data.company;

                    var id_azi_sel = window.$cookies.get("sel_filter_azienda_verifica_ts");

                    var descAzi = "";

                    for (var x = 0; x <= this.aziende.length - 1; x++) {

                        if (parseInt(this.aziende[x].companyid) == parseInt(id_azi_sel)) {

                            descAzi = this.aziende[x].fld_name;

                        }

                    }

                    console.log("DESC AZI: ", descAzi);

                    this.descAziSel = descAzi;


                    this.$root.$children[0].showAziendaSelezionata = true;
                    this.$root.$children[0].nomeAziendaSelezionata = descAzi;



                    this.anni = res.data.anni;

                    this.mesi = res.data.mesi;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        changeStatus: async function (status) {

            console.log(status);

            this.sheetStatus = false;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.curStatus = status;

            this.hideShowButtons(false);

            window.table.ajax.reload();


        },


        delMsg: function async() {
            var token = window.$cookies.get("token");
            var that = this;

            this.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: "Vuoi eliminare i timesheet selezionati ? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var v_anno = window.curYear;
                        var v_mese = window.curMonth;

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("id-user-sel");
                            }

                        });

                        console.log("TOKEN:", token);
                        console.log("ANNO:", v_anno);
                        console.log("MESE:", v_mese);
                        console.log("IDS SEL:", ids);


                        //start request
                        var response;

                        try {

                            that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            response = await apitimesheet.multiDelete(
                                token,
                                window.curYear,
                                window.curMonth,
                                ids
                            ).then((res) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from multiDelete", res);

                                that.$swal({
                                    icon: "success",
                                    text: "Timesheet eliminati correttamente",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                                console.log("response", response);

                            }

                            );


                        } catch (error) {
                            that.$root.$children[0].showProgress = false;
                            console.log(error);

                        }
                        console.log(response);


                    }
                });



        },

        changeStatusAdmin: async function (status) {

            console.log(status);

            this.sheetChangeStatus = false;

            var that = this;

            var v_token = window.$cookies.get("token");

            var v_year = window.curYear;
            var v_month = window.curMonth;

            var statusInt = 0;

            if (status == "Open") statusInt = 1;
            if (status == "To Approve") statusInt = 2;
            if (status == "Closed") statusInt = 3;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("id-user-sel");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("CUR YEAR:", v_year);
            console.log("CUR MONTH:", v_month);
            console.log("VAL STATUS:", statusInt);
            console.log("ID SEL:", ids);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apitimesheet.updateMultiStatus(
                v_token,
                window.curYear,
                window.curMonth,
                ids,
                statusInt
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateMultiStatus", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Stati aggiornati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();


                        window.table.ajax.reload();

                        that.hideShowButtons(false);


                    }, 200);


                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },


        changeStatusAdminOld: async function (status) {

            //  this.currFilterStatus = status;

            console.log(status);


            //START
            var token = window.$cookies.get("token");

            /*   var username = window.$cookies.get("username");
              var token_system = window.$cookies.get("token_system"); */
            //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";

            //var API_URL = "https://app.emtool.eu/api/acceptRejectHolidaysPermits";

            var API_URL = this.getUrlDomainApi("acceptRejectMultiHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            var operation = "";
            if (status == "Refused") operation = 'reject';
            if (status == "to Approve") operation = 'to Approve';
            if (status == "Approved") operation = 'accept';
            if (status == "Open") operation = 'open';

            console.log("STATUS: ", status);
            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("OPERATION: ", operation);
            console.log("ID_RICHIESTA: ", ids);

            var that = this;
            that.$root.$children[0].showProgress = true;

            /* eslint-disable no-unreachable */
            /*    return; */
            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,
                    'operation': operation,
                },
                success: function (resultData) {

                    that.$root.$children[0].showProgress = false;

                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    that.sheetChangeStatus = false;

                    that.hideShowButtons(false);

                    window.table.ajax.reload();

                },
                error: function (errorData) {

                    that.sheetChangeStatus = false;

                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.showSpinner = false;

                }
            });
            //END

        },



        sendInApprovation: function () {


            var that = this;
            var token = window.$cookies.get("token");

            var API_URL = this.getUrlDomain("sendMultiRequestHolidaysPermits");

            //var id_richiesta = window.id_par_sel;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);




            console.log("TOKEN: ", token);
            console.log("API_URL: ", API_URL);
            console.log("id_richiesta: ", ids);

            that.$root.$children[0].showProgress = true;

            $.ajax({
                type: "GET",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': ids,

                },
                success: function (resultData) {
                    that.$root.$children[0].showProgress = false;
                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    that.hideShowButtons(false);

                    window.table.ajax.reload();


                },
                error: function (errorData) {
                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.$root.$children[0].showProgress = false;

                }
            });


        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/public/api/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/api/";

            }

            return result + nome_metodo;

        },


        setupGrid: function () {

            var pointerVue = this;

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            var filtri = "";

            if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {



                filtri = `<div><img src='/emt/img/refresh-emt.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /></div><div><img src='/emt/img/aziende-emt.png' style='border:0!important;' class='selazienda' title='Aziende' /></div><div><img src='/emt/img/calendario-giorni-emt.png' style='border:0!important;' class='selanno' title='Anno' /></div><div><img src='/emt/img/calendario-mesi-emt.png' style='border:0!important;' class='selmese' title='Mese' /></div><div><img src='/emt/img/filtro-stato-emt.png' style='border:0!important;' class='selstato2' title='Stato' /></div>`;


            }
            else {

                filtri = `<div style="transform: translateY(-1px);margin-left:7px" title='Anno'><ion-select data-id="1" value="` + window.curYear + `" class="selanno" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Anno'><ion-select-option value='2020'>2020</ion-select-option><ion-select-option value='2021'>2021</ion-select-option><ion-select-option value='2022'>2022</ion-select-option><ion-select-option value='2023'>2023</ion-select-option><ion-select-option value='2024'>2024</ion-select-option></ion-select></div><div style="transform: translateY(-1px);margin-left:7px" title='Mese'><ion-select data-id="1" value="` + window.curMonth + `" class="selmese" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Mese'><ion-select-option value='1'>Gennaio</ion-select-option><ion-select-option value='2'>Febbraio</ion-select-option><ion-select-option value='3'>Marzo</ion-select-option><ion-select-option value='4'>Aprile</ion-select-option><ion-select-option value='5'>Maggio</ion-select-option><ion-select-option value='6'>Giugno</ion-select-option><ion-select-option value='7'>Luglio</ion-select-option><ion-select-option value='8'>Agosto</ion-select-option><ion-select-option value='9'>Settembre</ion-select-option><ion-select-option value='10'>Ottobre</ion-select-option><ion-select-option value='11'>Novembre</ion-select-option><ion-select-option value='12'>Dicembre</ion-select-option></ion-select></div>`;


            }



            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.curCompany = window.$cookies.get("sel_filter_azienda_verifica_ts");



                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'surname',
                        orderable: false,
                        name: 'surname',
                        width: '4%'
                    },

                    {
                        data: 'name',
                        orderable: false,
                        name: 'name',
                        width: '4%'
                    },

                    {
                        data: 'icon_status',
                        orderable: false,
                        name: 'icon_status',
                        width: '4%'
                    },


                    {
                        data: 'hour_ordinario',
                        orderable: false,
                        name: 'hour_ordinario',
                        width: '1%'
                    },


                    {
                        data: 'hour_straordinario',
                        orderable: false,
                        name: 'hour_straordinario',
                        width: '1%'
                    },

                    {
                        data: 'hour_activity',
                        orderable: false,
                        name: 'hour_activity',
                        width: '0.1%'
                    },



                    {
                        data: 'total_hour_worked',
                        orderable: false,
                        name: 'total_hour_worked',
                        width: '2%'
                    },

                    {
                        data: 'hour_reperibilita',
                        orderable: false,
                        name: 'hour_reperibilita',
                        width: '2%'
                    },

                    {
                        data: 'hour_intervento',
                        orderable: false,
                        name: 'hour_intervento',
                        width: '2%'
                    },

                    {
                        data: 'total_hour',
                        orderable: false,
                        name: 'total_hour',
                        width: '2%'
                    },


                    {
                        data: 'cliente',
                        orderable: false,
                        name: 'cliente',
                        width: '2%'
                    },

                    {
                        data: 'progetto',
                        orderable: false,
                        name: 'progetto',
                        width: '2%'
                    },



                ];


                $(".spin").show();





                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",


                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtCheckTimesheetNotEbadgeApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curStatus = window.curStatus;

                            valori.dataDa = window.dataDa;
                            valori.dataA = window.dataA;

                            valori.curYear = window.curYear;
                            valori.curMonth = window.curMonth;

                            valori.curSrcText = window.curSrcText;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                            var tot1 = window.$cookies.get("tot_val_ts");

                            if (tot1 == 0) {
                                window.$cookies.set("tot_val_ts", window.table.rows().count(), "9y");
                            }
                            else {

                                window.totTimesheet = window.$cookies.get("tot_val_ts");


                            }




                            var n_tot_for_approval = 0;

                            window.table.rows().every(function () {
                                var rowData = this.data();
                                console.log("Dati della riga:", rowData);

                                var status = rowData.description.toString().toUpperCase();

                                console.log("Dati status:", status);


                                if (status.indexOf("TO APPROVE") !== -1) {
                                    n_tot_for_approval = n_tot_for_approval + 1;
                                }

                                if (status.indexOf("CLOSED") !== -1) {
                                    n_tot_for_approval = n_tot_for_approval + 1;
                                }

                                return true;
                            });

                            var tot2 = window.$cookies.get("tot_val_open_appr");


                            if (tot2 == 0) {
                                window.$cookies.set("tot_val_open_appr", n_tot_for_approval, "9y");
                            }
                            else {

                                window.totTimesheetForApprove = window.$cookies.get("tot_val_open_appr");


                            }


                            var descMonth = "";
                            if (window.curMonth == 1) {
                                descMonth = "Gen";
                            }
                            if (window.curMonth == 2) {
                                descMonth = "Feb";
                            }
                            if (window.curMonth == 3) {
                                descMonth = "Mar";
                            }
                            if (window.curMonth == 4) {
                                descMonth = "Apr";
                            }
                            if (window.curMonth == 5) {
                                descMonth = "Mag";
                            }
                            if (window.curMonth == 6) {
                                descMonth = "Giu";
                            }
                            if (window.curMonth == 7) {
                                descMonth = "Lug";
                            }
                            if (window.curMonth == 8) {
                                descMonth = "Ago";
                            }
                            if (window.curMonth == 9) {
                                descMonth = "Set";
                            }
                            if (window.curMonth == 10) {
                                descMonth = "Ott";
                            }
                            if (window.curMonth == 11) {
                                descMonth = "Nov";
                            }
                            if (window.curMonth == 12) {
                                descMonth = "Dic";
                            }



                            var txtDescPeriodo = descMonth + " " + valori.curYear.toString();
                            $("#descPeriodo").html(txtDescPeriodo);

                            var txtCounters = window.totTimesheetForApprove.toString() + " / " + window.totTimesheet.toString();
                            $("#countersSheet").html(txtCounters);


                        }, 1500);






                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);



                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();
                                window.loading = 0;

                            }, 500);

                            // var valori = window.valori;
                            // valori.start = window.start;

                            // $.ajax({
                            //     url: pointerVue.getUrlDomain("EmtCheckTimesheetNotEbadgeApi") + '?username=' + v_token,
                            //     data: window.valori,
                            //     success: function (data) {
                            //         $(".spin").hide();
                            //         window.loading = 0;
                            //         window.start += window.perpage;

                            //         for (let [index, val] of data.data.entries()) {
                            //             console.log(index, val);
                            //             window.table.row.add(val).draw(false);
                            //             //  window.dataset.push(val);
                            //         }
                            //         console.log(data.data);
                            //     },
                            // });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`

                <div style="display:table">
                    
                    <div style="display:table-row">

                        <div style="display:table-cell">
                        
                            <div class="cont_buttons">

                                <div class="text_title">Verifica Timesheet</div>
                                


                                </div>
                        
                            </div><div style="display:flex; justify-items: center;
    align-items: center; transform: translateY(9px);">` + filtri + `</div>


                    </div>

                    <div style="display:table-row">

                        <div style="display:table-cell">
                            <div style="display:flex;margin-left:10px; transform: translateY(-6px);">
                                <div style="font-weight:bold;font-size:9px;width:55px;text-align:left" id="descPeriodo"></div>
                                <div style="font-weight:bold;font-size:9px;width:47px;text-align:right" id="countersSheet"></div>
                            </div>
                        </div>
                        
                    </div>


                </div>

           


                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });


                $('body').on('click', '.cont_verifica_timesheet tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        console.log("DATA ROW: ", row_data);

                        pointerVue.curSelRow = row_data;


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        //$("tr").removeClass("active-row");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }




                });


                $(".selstato2").on("click", function (opt) {

                    console.log(opt);

                    pointerVue.sheetFilterStatus = true;


                });


                // $(".selstato2").on("ionChange", function (opt) {
                //     console.log(opt);

                //     console.log("VALUE STATO: ", this.value)

                //     window.curStatus = this.value;

                //     pointerVue.hideShowButtons(false);

                //     pointerVue.showSpinner = true;
                //     pointerVue.$root.$children[0].showProgress = true;

                //     window.table.ajax.reload();



                // });





                window.parentiAddedEvents = true;

            });

        },


        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_open_status = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                    var val_status_id = $(this).attr("status-id");

                    if (val_status_id == "Open") // stato aperto
                    {

                        tot_open_status = tot_open_status + 1;

                    }



                }

            });

            console.log("TOT SEL: ", tot);
            console.log("TOT SEL OPEN STATUS: ", tot_open_status);

            setTimeout(() => {


                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                var pulsantis = [];


                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30
                        });


                }


                if (tot > 0) {

                    if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-send-clock-outline",
                                link: "/btn_changestatusadmin",
                                id: "btn_changestatusadmin",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/cambio-stato-emt.png",
                                title: "Aggiorna Stato",
                                width: 30

                            }

                        );


                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-delete",
                                image: "https://app.emtool.eu/public/_lib/img/invio-emt.png",
                                link: "/delete",
                                id: "btn_send_invite",
                                disabled: false,
                                title: "Manda Invito",
                                width: 30
                            }


                        );




                    }


                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {


                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-delete",
                                image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                                link: "/delete",
                                id: "btn_delete",
                                disabled: false,
                                title: "Elimina",
                                width: 30
                            }


                        );


                    }





                }





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },






        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                // var a = window.$cookies.get("a");
                // var b = window.$cookies.get("b");

                console.log(v_enable);

                var pulsantis = [];

                // if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                //     pulsantis.push(

                //         {
                //             text: "Indietro",
                //             icon: "mdi-arrow-left",
                //             image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                //             link: "/confOrariChoice",
                //             id: "btn_back",
                //             disabled: false,
                //             title: "Indietro",
                //             width: 35
                //         }



                //     );


                // }



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apirisorse.deleteRisorsa(
                            window.id_par_sel,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteRisorsa", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                that.hideShowButtons(false);

                                window.table.ajax.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
#fldValueOtpDelete {
    text-align: center;
    font-size: 20px
}

.cl-cliente-ver-ts {
    font-size: 8px;
    width: 100px;
}

.cl-test {
    transform: translate(-994px, 16px);
}

.cl-table {
    display: table;
}

.cl-row {
    display: table-row;
}

.cl-cell {
    display: table-cell
}

.cl-cell-filtri {
    display: table-cell;
    width: 200px
}

.cl-cell-azi {
    display: block;
    transform: translate(-291px, 18px);
}

.cl-cell-title {

    display: block;
    min-width: 93px;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_verifica_timesheet #myTable {
    margin-right: 3px;
}

.cont_verifica_timesheet table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_verifica_timesheet table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 9px !important;
}

.cont_verifica_timesheet th {
    padding-left: 5px !important;
}

.cont_verifica_timesheet ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_verifica_timesheet .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}


.cont_verifica_timesheet #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;

}

.cont_verifica_timesheet div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

/* .cont_verifica_timesheet #myTable_wrapper {
    padding-top: 0px;
    min-width: initial;
    max-width: 1000px;
    width: 1000px;
} */

.cont_verifica_timesheet #myTable_wrapper {
    padding-top: 0px;
    min-width: 900px;
    max-width: 900px;
    width: 900px;
}

.cont_verifica_timesheet .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw;
}

.cont_verifica_timesheet .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_verifica_timesheet .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}


.cont_verifica_timesheet .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}

.closeStatusSheet {
    position: absolute;
    right: 0;
}

.selstato {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

/* .cont_verifica_timesheet #myTable {
    margin-right: 3px;
    width: 985px !important;
} */

.cont_verifica_timesheet #myTable {
    margin-right: 3px;
    width: 885px !important;
}


.cont_verifica_timesheet .selazienda {
    max-width: 24px;
    min-height: 24px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 24px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_verifica_timesheet .selaggiorna {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.itemAzienda {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.cont_verifica_timesheet .clDataDa {
    font-weight: bold;
    transform: translate(6px, -12px);
}

.cont_verifica_timesheet .clValueDataDa {
    font-weight: bold;
    transform: translate(11px, -11px);
}

.cont_verifica_timesheet .seldata {
    max-width: 22px;
    min-height: 22px;
    margin-left: 11px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: --2px;
}


.cont_verifica_timesheet .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_verifica_timesheet .checkall {
    width: 13px;
    height: 13px;
}

.cont_verifica_timesheet .val_status {
    width: 10px !important;
}

.cont_verifica_timesheet .selanno {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_verifica_timesheet .selmese {
    max-width: 24px !important;
    min-height: 27px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 27px !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_verifica_timesheet .selstato2 {
    max-width: 24px !important;
    min-height: 24px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 24px !important;
    text-indent: -119px !important;
    background-size: 24px !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}


.cont_verifica_timesheet .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}



.cl-ordinario {
    width: 100% !important;
    text-align: center !important
}

.cl-reperibilita {
    width: 100% !important;
    text-align: center !important
}

.cl-intervento {
    width: 100% !important;
    text-align: center !important
}


.cl-ordinario-green-ver-ts {
    width: 47px !important;
    text-align: center !important;
    border: 2px solid forestgreen;
    min-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    margin-top: -3px;
    min-width: 47px !important;
    max-width: 47px !important;
}



.cl-straordinario {
    width: 100% !important;
    text-align: center !important
}


.cl-straordinario-blue-ver-ts {
    width: 47px !important;
    text-align: center !important;
    border: 2px solid dodgerblue;
    min-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    margin-top: -3px;
    min-width: 47px !important;
    max-width: 47px !important;
}



.cl-activity-ver-ts {
    width: 60px !important;
    text-align: center !important;
    /* margin-left: 14px; */
}

.cl-activity-red-ver-ts {
    width: 47px !important;
    text-align: center !important;
    border: 2px solid red;
    min-height: 20px;
    vertical-align: middle;
    padding-top: 3px;
    margin-top: -3px;
    /* margin-left: 8px; */
    min-width: 47px !important;
    max-width: 47px !important;
}


.cl-total {
    width: 100% !important;
    text-align: center !important
}

.p_total_hour,
.p_total_hour_worked,
.p_total_hour_office,
.p_total_hour_smartworking,
.p_reperibilita,
.p_intervento {
    width: 100% !important;
    text-align: center !important
}

.cl-progetti {
    text-align: center;
}

.cont_verifica_timesheet .azienda_selezionata {
    font-weight: bold;
    transform: translate(-980px, 16px);
    font-size: 9px;
}

@media screen and (max-width: 960px) {

    .cl_note {
        display: inline;
    }

    .cont_verifica_timesheet .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_verifica_timesheet #myTable {
        /* margin-top:54px; */
    }

    .cont_verifica_timesheet #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_verifica_timesheet #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;

    }

    .cont_verifica_timesheet #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_verifica_timesheet .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_verifica_timesheet #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: auto !important;
    }

    .cont_verifica_timesheet .cl_num {
        margin-left: -3px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

    .cont_verifica_timesheet .cl_checkall {
        padding-left: 2px;
        padding-top: 2px;
    }


    .cl-ordinario {
        width: 100% !important;
        text-align: center !important;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-ordinario-green-ver-ts {
        width: 47px !important;
        text-align: center !important;
        border: 2px solid forestgreen;
        min-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
        margin-top: -3px;
        min-width: 47px !important;
        max-width: 47px !important;
    }

    .cl-straordinario {
        width: 100% !important;
        text-align: center !important;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-straordinario-blue-ver-ts {
        width: 47px !important;
        text-align: center !important;
        border: 2px solid dodgerblue;
        min-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
        margin-top: -3px;
        min-width: 47px !important;
        max-width: 47px !important;
    }

    .cl-activity {
        width: 100% !important;
        text-align: center !important;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }

    .cl-activity-red {
        width: 100% !important;
        text-align: center !important;
        border: 2px solid red;
        min-height: 20px;
        vertical-align: middle;
        padding-top: 3px;
        margin-top: -3px;
        display: inline;
        padding-left: 20px;
        padding-right: 20px
    }


    .cl-total {
        width: 100% !important;
        text-align: center !important;
        display: inline
    }

    .p_total_hour,
    .p_total_hour_worked,
    .p_total_hour_office,
    .p_total_hour_smartworking {
        width: 100% !important;
        text-align: center !important;
        display: inline
    }


    .cl-progetti {
        text-align: center;
        display: inline;
    }

    .cl-test {
        transform: translate(-273px, 16px);
    }

    .cont_verifica_timesheet .azienda_selezionata {
        font-weight: bold;
        transform: translate(-273px, 16px);
        font-size: 9px;
    }


}
</style>
