import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISettings {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/emt/" + nome_metodo;


  }

  getUrlDomainNew(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async saveSettings(settings, user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
   
    for ( var key in settings ) {
       formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("EmtSaveSettings") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getSettings()
  {


    return axios.get(this.getUrlDomain("EmtGetSettings") ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  // async getSettingsHolidaysPermits()
  // {


  //   return axios.get(this.getUrlDomainNew("EmtGetSettingsHolidaysPermits") ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  // }


  async getSettingsHolidaysPermits(id_azienda)
  {


    let formData = new FormData();

    formData.append("id_azienda",  id_azienda);
   

    return axios.post(this.getUrlDomainNew("EmtGetSettingsHolidaysPermits") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  async saveSettingsHolidaysPermits(settings, user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
   
    for ( var key in settings ) {
       formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomainNew("EmtSaveSettingsHolidaysPermits") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async saveSettingsHolidaysPermitsLevel(settings, user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
   
    for ( var key in settings ) {
       formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomainNew("EmtSaveSettingsLevelHolidaysPermits") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getSettingsLevelHolidaysPermits()
  {


    return axios.get(this.getUrlDomainNew("EmtGetSettingsLevelHolidaysPermits") ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APISettings({
  url: "https://services.ebadge.it/public/api/"
})
