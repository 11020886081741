import axios from 'axios'


class APITipiCespiti {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async saveTipoCespiti(tipo_cespiti, user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);

    for (var key in tipo_cespiti) {
      formData.append(key, tipo_cespiti[key]);
    }

    return axios.post(this.getUrlDomain("EmtSaveTipiCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async editTipoCespiti(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);


    return axios.post(this.getUrlDomain("EmtInitEditTipiCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async deleteTipiCespiti(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtDeleteTipiCespitiApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APITipiCespiti({
  url: "https://services.ebadge.it/public/api/"
})
