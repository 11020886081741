<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>



        <div class="row cont_company_agila">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Proprietario</th>
                                        <th class='desktop'>Inserita Il</th>
                                        <th class='all'>Ref. Agila</th>
                                        <th class='all'>Ragione Sociale</th>
                                        <th class='desktop'>Forma Giuridica</th>
                                        <th class='desktop'>Indirizzo</th>
                                        <th class='desktop'>CAP</th>
                                        <th class='desktop'>Città</th>
                                        <th class='desktop'>Provincia</th>
                                        <th class='desktop'>Telefono</th>
                                        <th class='desktop'>Partita Iva</th>
                                        <th class='desktop'>Settore Attività</th>
                                        <th class='desktop'>Stato</th>
                                        <th class='desktop'>Visura</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="16">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/ricerca_emt.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="" id="fldSearch">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupsearch2" style="display:none">

            <template>
                <modal name="popupSearch2" :clickToClose="false" :width="262" :height="379">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Importa Excel

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <span class="files">
                                <label style="margin-left:11px!important;font-size:11px;font-weight: bold;">File</label>
                                <input type="file" id="input-file-logo" name="logo" accept=".xls" class="dropify"
                                    data-max-width="6610" data-max-height="6200" />
                            </span>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-import" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelImp" />

                                <v-img alt="" class="btn-confirm-import" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmImp" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                                        max-width="24" max-height="24" style="margin-right:13px"
                                        transition="scale-transition" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                        id="fldTextSearchAzi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetFormaGiu">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetFormaGiu = !sheetFormaGiu">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Forma Giuridica
                    </div>
                    <v-list-item v-for="prj in forma_giuridica" :key="prj.id" @click="filterFormaGiu(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.nome
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetSetAct">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetSetAct = !sheetSetAct">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Settori Attività
                    </div>
                    <v-list-item v-for="prj in settori_att" :key="prj.id" @click="filterSecAct(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.descrizione
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetCom">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetCom = !sheetCom">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Commercialisti
                    </div>
                    <v-list-item v-for="prj in commercialisti" :key="prj.id" @click="filterCom(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.ragione_sociale
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="contrefs">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetRefs" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Referenti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetRefs = !sheetRefs">

                                    <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                                        max-width="24" max-height="24" style="margin-right:13px"
                                        transition="scale-transition" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchRefs" id="fldTextSearchRef">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredRefs" :key="item.login"
                                        @click="manageClickRefs(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.full_name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetProp">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetProp = !sheetProp">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Proprietari
                    </div>
                    <v-list-item v-for="prj in proprietari" :key="prj.id" @click="filterProp(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.nome
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="contprat">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetPrat" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Pratiche
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetPrat = !sheetPrat">

                                    <v-img alt="Proprietario Logo" class="  " contain src="@/assets/btn_cancel3_emt.png"
                                        max-width="24" max-height="24" style="margin-right:13px"
                                        transition="scale-transition" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchPrat">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredPrat" :key="item.id"
                                        @click="manageClickPrat(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.nome }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetTipoProg">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetTipoProg = !sheetTipoProg">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Tipo Progetto
                    </div>
                    <v-list-item v-for="prj in tipo_prog" :key="prj.id" @click="filterTipoPog(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.nome
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetDimAzi">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetDimAzi = !sheetDimAzi">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Dimensione Azienda
                    </div>
                    <v-list-item v-for="prj in filtroDimensioneAzienda" :key="prj.id" @click="filterDimAzi(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.descrizione
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetProvince">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetProvince = !sheetProvince">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Province
                    </div>
                    <v-list-item v-for="prj in province" :key="prj.id" @click="filterProvincia(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.nome
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetStato">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStato = !sheetStato">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Stati
                    </div>
                    <v-list-item v-for="prj in filtroStati" :key="prj.id" @click="filterStato(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                    prj.nome
                }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="contpopupotpdelete" style="display:none">

            <template>
                <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Codice OTP inviatoLe via mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelOtpDelete" />

                                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_emt.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmOtpDelete" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="text-left">
            <v-bottom-sheet v-model="sheetAziendaArtigiana">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetAziendaArtigiana = !sheetAziendaArtigiana">

                        <v-img alt="Proprietario Logo" style="margin-right:23px" class="  " contain
                            src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="py-3">
                        Azienda Artigiana
                    </div>
                    <v-list-item v-for="prj in filtroAziendaArtigiana" :key="prj.id"
                        @click="filterAziendaArtigiana(prj.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            prj.nome
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>




    </div>
</template>

<script>
import apiaziende from "../utils/aziende/apiaziende";
import apiotp from "../utils/otp/apiotp";
import apitimesheet from "../utils/timesheet/apitimesheet";

import {
    bus
} from "../main";
import router from ".././router";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        window.myVal = "";

        window.perpage = 100;
        bus.$off("bottomIconClickEvent");


        window.curSrcText = window.$cookies.get("filter_search_text_azi_agila");
        window.curCompany = window.$cookies.get("filter_company_azi_agila");
        window.curFormaGiuId = window.$cookies.get("filter_form_giurid_azi_agila");
        window.curSecActId = window.$cookies.get("filter_sec_act_azi_agila");
        window.curComId = window.$cookies.get("filter_comm_azi_agila");
        window.curRefId = window.$cookies.get("filter_refer_azi_agila");
        window.curPropId = window.$cookies.get("filter_prop_azi_agila");
        window.curPratId = window.$cookies.get("filter_prat_azi_agila");
        window.curTipoProgId = window.$cookies.get("filter_tipo_prog_azi_agila");
        window.curDimAzi = window.$cookies.get("filter_dim_azi_agila");
        window.curProvincia = window.$cookies.get("filter_prov_azi_agila");
        window.curStato = window.$cookies.get("filter_stato_azi_agila");
        window.curAziendaArtigiana = window.$cookies.get("filter_azi_art_azi_agila");

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {


            $('body').on('click', '.cancelfilters', function () {

                console.log(("EVENTO CLICK SELANNO"));


                window.curSrcText = "";
                window.curCompany = "";
                window.curFormaGiuId = "";
                window.curSecActId = "";
                window.curComId = "";
                window.curRefId = "";
                window.curPropId = "";
                window.curPratId = "";
                window.curTipoProgId = "";
                window.curDimAzi = "";
                window.curProvincia = "";
                window.curStato = "";
                window.curAziendaArtigiana = "";

                window.$cookies.set("filter_search_text_azi_agila", "", "9y");
                window.$cookies.set("filter_company_azi_agila", "", "9y");
                window.$cookies.set("filter_form_giurid_azi_agila", "", "9y");
                window.$cookies.set("filter_sec_act_azi_agila", "", "9y");
                window.$cookies.set("filter_comm_azi_agila", "", "9y");
                window.$cookies.set("filter_refer_azi_agila", "", "9y");
                window.$cookies.set("filter_prop_azi_agila", "", "9y");
                window.$cookies.set("filter_prat_azi_agila", "", "9y");
                window.$cookies.set("filter_tipo_prog_azi_agila", "", "9y");
                window.$cookies.set("filter_dim_azi_agila", "", "9y");
                window.$cookies.set("filter_prov_azi_agila", "", "9y");
                window.$cookies.set("filter_stato_azi_agila", "", "9y");
                window.$cookies.set("filter_azi_art_azi_agila", "", "9y");

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });






            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('click', '.selrefs', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetRefs = true;

                setTimeout(() => {

                    $("#fldTextSearchRef").focus();

                }, 500);

            });




            $('body').on('click', '.selazienda', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);

            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {

            this.initFilterValues();

        }, 100);


        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var ids = "";

            switch (data) {

                case "btn_delete":

                    this.getOtpDelete();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editCompanyAgila/0"
                    });
                    break;

                case "btn_edit":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editCompanyAgila/" + ids
                    });

                    break;

                case "btn_back":
                    router.push({
                        path: "/filterRisorse"
                    });
                    break;

                case "btn_export_xls":

                    this.exportXls();

                    break;

                case "btn_import_xls":

                    $(".contpopupsearch2").show();
                    this.$modal.show('popupSearch2');


                    setTimeout(function () {

                        var oggDrop = $('.dropify').dropify({
                            messages: {
                                default: 'Drag',
                                replace: /* s */ '',
                                remove: 'Rimuovi',
                                error: 'File troppo grande'
                            }
                        });

                        console.log(oggDrop);

                        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                        $(".dropify-render").append("<img>");


                    }, 200);

                    break;


                case "btn_view":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/vediCompanyAgila/" + ids
                    });



                    break;

                case "btn_down_vis":

                    var url_doc = "";

                    $(".val_status").each(function (index) {

                        console.log(index);


                        if ($(this).prop("checked")) {

                            url_doc = $(this).attr("url-visura");
                        }

                    });


                    console.log("URL DOC: ", url_doc);

                    var file_path = url_doc;
                    var a = document.createElement('A');
                    a.href = file_path;
                    a.target = "_blank";
                    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);



                    break;


                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);

    },
    data: () => ({



        sheetAziendaArtigiana: false,

        valueOtpDelete: "",



        sheetStato: false,

        sheetProvince: false,

        province: [],

        sheetDimAzi: false,

        filtroStati: [{
            id: "",
            nome: "Tutti"
        }, {
            id: "A",
            nome: "Attiva"
        }, {
            id: "N",
            nome: "Non Attiva"
        }, {
            id: "F",
            nome: "Fallita"
        }],




        filtroAziendaArtigiana: [{
            id: "",
            nome: "Tutti"
        }, {
            id: "S",
            nome: "Si"
        }, {
            id: "N",
            nome: "No"
        }],







        filtroDimensioneAzienda: [{
            id: "",
            descrizione: "Tutti"
        }, {
            id: "Micro",
            descrizione: "Micro"
        }, {
            id: "Piccola",
            descrizione: "Piccola"
        }, {
            id: "Media",
            descrizione: "Media"
        }, {
            id: "Grande",
            descrizione: "Grande"
        }],

        sheetTipoProg: false,

        searchPrat: "",

        sheetProp: false,
        sheetPrat: false,

        sheetRefs: false,
        searchRefs: "",

        sheetCom: false,
        sheetSetAct: false,
        sheetFormaGiu: false,

        aziende: [],
        settori_att: [],
        commercialisti: [],
        referenti: [],
        proprietari: [],
        pratiche: [],

        tipo_prog: [],



        forma_giuridica: [
            { id: "", nome: "Tutti" },
            { id: "snc", nome: "SNC" },
            { id: "srl", nome: "SRL" },
            { id: "spa", nome: "SPA" },
            { id: "sas", nome: "SAS" },
            { id: "sapa", nome: "SAPA" },
            { id: "dind", nome: "Ditta Individuale" },
            { id: "SS", nome: "SS" },
            { id: "SRLS", nome: "SRLS" },
            { id: "SAPA/SAA", nome: "SAPA/SAA" },
            { id: "SocCoop", nome: "SocCoop" },
            { id: "Soc. Coop. a R.L. – S.C.A.R.L.", nome: "Soc. Coop. a R.L. – S.C.A.R.L." }
        ],

        sheetAziende: false,
        search: "",

        textSearch: "",

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {



        filteredPrat() {
            return _.orderBy(this.pratiche.filter(item => {
                if (!this.searchPrat) return this.pratiche;
                return (item.nome.toLowerCase().includes(this.searchPrat.toLowerCase()));
            }), 'headline')


        },





        filteredRefs() {
            return _.orderBy(this.referenti.filter(item => {
                if (!this.searchRefs) return this.referenti;
                return (item.full_name.toLowerCase().includes(this.searchRefs.toLowerCase()));
            }), 'headline')


        },



        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')


        },

    },



    methods: {



        getOtpDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            window.myVal = "";

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiotp.generateOTPAndSendMail(
                v_token,
                'emt',
                'elimina_azienda',
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from generateOTPAndSendMail", res);

                window.myVal = res.data.Result;

                that.valueOtpDelete = "";

                setTimeout(() => {

                    $(".contpopupotpdelete").show();

                    this.$modal.show('popupOtpDelete');

                    setTimeout(() => {

                        $("#fldValueOtpDelete").focus();

                    }, 300);


                }, 200);





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );






        },


        btnCancelOtpDelete: function () {

            this.$modal.hide('popupOtpDelete');


        },

        btnConfirmOtpDelete: function () {


            if (this.valueOtpDelete != "") {

                console.log("OTP INP: ", this.valueOtpDelete);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

                if (window.myVal == btoa(this.valueOtpDelete)) {

                    this.$modal.hide('popupOtpDelete');

                    this.deleteRow();

                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },





        viewCancelFiltri: function () {

            var cancelFiltri = 0;


            if (window.curSrcText != "") {
                if (window.curSrcText != null) {
                    cancelFiltri = 1;
                }
            }


            if (window.curCompany != "") {
                if (window.curCompany != null) {
                    cancelFiltri = 1;
                }
            }


            if (window.curFormaGiuId != "") {
                if (window.curFormaGiuId != null) {
                    cancelFiltri = 1;
                }
            }


            if (window.curSecActId != "") {
                if (window.curSecActId != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curComId != "") {
                if (window.curComId != null) {
                    cancelFiltri = 1;
                }
            }


            if (window.curRefId != "") {
                if (window.curRefId != null) {
                    cancelFiltri = 1;
                }
            }


            if (window.curPropId != "") {
                if (window.curPropId != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curPratId != "") {
                if (window.curPratId != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curTipoProgId != "") {
                if (window.curTipoProgId != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curDimAzi != "") {
                if (window.curDimAzi != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curProvincia != "") {
                if (window.curProvincia != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curStato != "") {
                if (window.curStato != null) {
                    cancelFiltri = 1;
                }
            }

            if (window.curAziendaArtigiana != "") {
                if (window.curAziendaArtigiana != null) {
                    cancelFiltri = 1;
                }
            }

            console.log("CANCEL FILTRI: ", cancelFiltri);

            if (cancelFiltri == 0) {

                $((".cancelfilters")).hide();

            }

            if (cancelFiltri == 1) {

                $((".cancelfilters")).show();

            }


        },



        hideShowButtonsSelRow: function () {

            var tot = 0;
            var totVisura = 0;

            var pulsantis = [];

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    var has_visura = $(this).attr("url-visura");

                    if (has_visura.length > 0) {
                        totVisura = 1;
                    }

                    tot = tot + 1;

                }

            });


            if (tot == 0) {

                pulsantis.push({
                    text: "Add",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                    link: "/settings",
                    id: "btn_add",
                    disabled: false,
                    title: "Aggiungi",
                    width: 30
                });

            }


            if (tot == 1) {

                pulsantis.push({
                    text: "Edit",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                    link: "/info",
                    id: "btn_edit",
                    disabled: false,
                    title: "Modifica",
                    width: 30
                });

                pulsantis.push({
                    text: "Edit",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/view-emt.png",
                    link: "/info",
                    id: "btn_view",
                    disabled: false,
                    title: "Vedi",
                    width: 45
                });

                pulsantis.push({
                    text: "Delete",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                    link: "/info",
                    id: "btn_delete",
                    disabled: false,
                    title: "Elimina",
                    width: 30
                });



                if (totVisura > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/download_emt.png",
                        link: "/info",
                        id: "btn_down_vis",
                        disabled: false,
                        title: "Scarica Visura",
                        width: 25
                    });

                }


            }

            if (tot > 1) {

                pulsantis.push({
                    text: "Delete",
                    icon: "mdi-home-circle",
                    image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                    link: "/info",
                    id: "btn_delete",
                    disabled: false,
                    title: "Elimina",
                    width: 30
                });

            }


            pulsantis.push({
                text: "Add",
                icon: "mdi-home-circle",
                image: "https://app.emtool.eu/public/_lib/img/export-emt.png",
                link: "/settings",
                id: "btn_export_xls",
                disabled: false,
                title: "Esporta Excel",
                width: 30
            });

            pulsantis.push({
                text: "Add",
                icon: "mdi-home-circle",
                image: "https://app.emtool.eu/public/_lib/img/import-emt.png",
                link: "/settings",
                id: "btn_import_xls",
                disabled: false,
                title: "Importa Excel",
                width: 30
            });

            this.$root.$children[0].bnavbuttons = pulsantis;

        },


        filterSecAct: function (secatcid) {


            console.log("PRJ TYPE SEL: ", secatcid);

            this.sheetSetAct = false;

            window.curSecActId = secatcid;

            window.$cookies.set("filter_sec_act_azi_agila", secatcid, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        filterTipoPog: function (tipoprogid) {


            console.log("PRJ TYPE SEL: ", tipoprogid);

            this.sheetTipoProg = false;

            window.curTipoProgId = tipoprogid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        filterDimAzi: function (dimensione) {


            console.log("DIM AZI SEL: ", dimensione);

            this.sheetDimAzi = false;

            window.curDimAzi = dimensione;

            window.$cookies.set("filter_dim_azi_agila", dimensione, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        filterProvincia: function (provincia) {


            console.log("PROV SEL: ", provincia);

            this.sheetProvince = false;

            window.curProvincia = provincia;

            window.$cookies.set("filter_prov_azi_agila", provincia, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        filterStato: function (stato) {


            console.log("STATO SEL: ", stato);

            this.sheetStato = false;

            window.curStato = stato;

            window.$cookies.set("filter_stato_azi_agila", stato, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        filterAziendaArtigiana: function (azienda_artigiana) {


            console.log("AZI ART SEL: ", azienda_artigiana);

            this.sheetAziendaArtigiana = false;

            window.curAziendaArtigiana = azienda_artigiana;

            window.$cookies.set("filter_azi_art_azi_agila", azienda_artigiana, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },








        filterCom: function (comdid) {


            console.log("PRJ TYPE SEL: ", comdid);

            this.sheetCom = false;

            window.curComId = comdid;

            window.$cookies.set("filter_comm_azi_agila", comdid, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        filterProp: function (propid) {


            console.log("PRJ TYPE SEL: ", propid);

            this.sheetProp = false;

            window.curPropId = propid;

            window.$cookies.set("filter_prop_azi_agila", propid, "9y");

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        filterPrat: function (pratId) {


            console.log("PRJ TYPE SEL: ", pratId);

            this.sheetPrat = false;

            window.curPratId = pratId;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        filterFormaGiu: function (formaGiuId) {


            console.log("PRJ TYPE SEL: ", formaGiuId);

            this.sheetFormaGiu = false;

            window.curFormaGiuId = formaGiuId;

            window.$cookies.set("filter_form_giurid_azi_agila", formaGiuId, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },



        manageClickRefs: async function (refagila) {

            console.log("AZI SEL: ", refagila);

            this.sheetRefs = false;

            window.curRefId = refagila.login;

            window.$cookies.set("filter_refer_azi_agila", refagila.login, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },



        manageClickPrat: async function (pratica) {

            console.log("AZI SEL: ", pratica);

            this.sheetPrat = false;

            window.curPratId = pratica.id;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },





        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curCompany = azienda.companyid;

            window.$cookies.set("filter_company_azi_agila", azienda.companyid, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },


        btnCancelImp: function () {

            this.$modal.hide('popupSearch2');

        },



        btnConfirmImp: function () {

            var errore = "";

            if ($("#input-file-logo")[0].files.length == 0) {
                errore = "Specificare un file";
            }

            if (errore == "") {

                console.log("ok");

                this.importXls();


            }


            if (errore != "") {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },


        openImportPopup: function () {

            console.log("openImportPopup");


            this.$modal.show('popupImportXls');


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },



        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            console.log("SETUP GRID");


            var filtri = `<img src='/emt/img/aziende-emt.png' style='border:0!important;' class='selazienda' title='Ragione Sociale' /><img src='/emt/img/forma-giuridica-emt.png' style='border:0!important;' class='selformagiuridica' title='Forma Giuridica' /><img src='/emt/img/settori-attività-emt.png' style='border:0!important;' class='selfiltsect' title='Settori Attività' /><img src='/emt/img/commercialisti-emt.png' style='border:0!important;' class='selefiltcom' title='Commercialisti' /><img src='/emt/img/referenti_emt.png' style='border:0!important;' class='selrefs' title='Referenti Agila' /><img src='/emt/img/owner-emt.png' style='border:0!important;' class='selprop' title='Proprietario' /><img src='/emt/img/company_size_emt.png' style='border:0!important;' class='seldimazienda' title='Dimensione Azienda' /><img src='/emt/img/emt_province.png' style='border:0!important;' class='selprovince' title='Province' /><img src='/emt/img/filtro-stato-emt.png' style='border:0!important;' class='selstato' title='Stato' /><img src='/emt/img/azienda-artigiana-emt.png' style='border:0!important;' class='selaziendaartigiana' title='Azienda Artigiana' /><img src='/emt/img/delete_filters_emt.png' style='border:0!important;' class='cancelfilters' title='Cancella Filtri Applicati' />`;

            var pointerVue = this;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");


                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'proprietario',
                    orderable: true,
                    name: 'proprietario',
                    width: '4%'
                },

                {
                    data: 'inserita_il',
                    orderable: true,
                    name: 'inserita_il',
                    width: '4%'
                },

                {
                    data: 'full_name_usr',
                    orderable: true,
                    name: 'full_name_usr',
                    width: '4%'
                },

                {
                    data: 'fld_name',
                    orderable: true,
                    name: 'fld_name',
                    width: '4%'
                },

                {
                    data: 'forma_societaria',
                    orderable: true,
                    name: 'forma_societaria',
                    width: '4%'
                },

                {
                    data: 'address_operative',
                    orderable: true,
                    name: 'address_operative',
                    width: '4%'
                },

                {
                    data: 'cap_operative',
                    orderable: true,
                    name: 'cap_operative',
                    width: '4%'
                },

                {
                    data: 'citta_operative',
                    orderable: true,
                    name: 'citta_operative',
                    width: '4%'
                },

                {
                    data: 'provincia_operative',
                    orderable: true,
                    name: 'provincia_operative',
                    width: '4%'
                },

                {
                    data: 'phone_number',
                    orderable: true,
                    name: 'phone_number',
                    width: '4%'
                },

                {
                    data: 'partita_iva',
                    orderable: true,
                    name: 'partita_iva',
                    width: '4%'
                },

                {
                    data: 'desc_settore',
                    orderable: true,
                    name: 'desc_settore',
                    width: '4%'
                },

                {
                    data: 'desc_stato',
                    orderable: true,
                    name: 'desc_stato',
                    width: '4%'
                },

                {
                    data: 'file_visura',
                    orderable: true,
                    name: 'file_visura',
                    width: '1%'
                },

                ];


                $(".spin").show();



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("EmtAgilaGetAziendeApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;

                            valori.curCompany = window.curCompany;
                            valori.curFormaGiuId = window.curFormaGiuId;
                            valori.curSrcText = window.curSrcText;
                            valori.curSecActId = window.curSecActId;
                            valori.curComId = window.curComId;
                            valori.curRefId = window.curRefId;
                            valori.curPropId = window.curPropId;
                            valori.curPratId = window.curPratId;
                            valori.curTipoProgId = window.curTipoProgId;
                            valori.curDimAzi = window.curDimAzi;
                            valori.curProvincia = window.curProvincia;

                            valori.curStato = window.curStato;

                            valori.curAziendaArtigiana = window.curAziendaArtigiana;



                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);

                        pointerVue.viewCancelFiltri();


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);


                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();


                            setTimeout(() => {

                                $(".spin").hide();

                            }, 800);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("EmtAgilaGetAziendeApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    //$(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Aziende</div>
                    ` + filtri + `

                </div>
                <div><img src='/emt/img/cerca-emt.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);




                $('body').on('click', '.selptype', function () {

                    console.log(("EVENTO CLICK SELFORMAGIURIDICA"));

                    pointerVue.sheetTipoProg = true;

                });



                $('body').on('click', '.selprat', function () {

                    console.log(("EVENTO CLICK SELFORMAGIURIDICA"));

                    pointerVue.sheetPrat = true;

                });






                $('body').on('click', '.selprop', function () {

                    console.log(("EVENTO CLICK SELFORMAGIURIDICA"));

                    pointerVue.sheetProp = true;

                });



                $('body').on('click', '.seldimazienda', function () {

                    console.log(("EVENTO CLICK SELDIMAZIENDA"));

                    pointerVue.sheetDimAzi = true;

                });

                $('body').on('click', '.selprovince', function () {

                    console.log(("EVENTO CLICK SELDIMAZIENDA"));

                    pointerVue.sheetProvince = true;

                });




                $('body').on('click', '.selstato', function () {

                    console.log(("EVENTO CLICK SELSTATO"));

                    pointerVue.sheetStato = true;

                });


                $('body').on('click', '.selaziendaartigiana', function () {

                    console.log(("EVENTO CLICK SELSTATO"));

                    pointerVue.sheetAziendaArtigiana = true;

                });




                $('body').on('click', '.selefiltcom', function () {

                    console.log(("EVENTO CLICK SELFORMAGIURIDICA"));

                    pointerVue.sheetCom = true;

                });



                $('body').on('click', '.selfiltsect', function () {

                    console.log(("EVENTO CLICK SELFORMAGIURIDICA"));

                    pointerVue.sheetSetAct = true;

                });


                $('body').on('click', '.selformagiuridica', function () {

                    console.log(("EVENTO CLICK SELFORMAGIURIDICA"));

                    pointerVue.sheetFormaGiu = true;

                });


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                    setTimeout(() => {

                        $("#fldSearch").focus();

                    }, 200);

                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    $(".spin").show();
                    window.curStatus = this.value;



                    window.table.ajax.reload();

                });


                $('body').on('click', '.cont_company_agila tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);



                    // setTimeout(() => {

                    //     var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //     var id_sel = $(this).find(".id_row").attr("data-id");

                    //     $("tr").removeClass("active-row");

                    //     var set_cls_active = 0;

                    //     if (last_id_sel == "undefined") {
                    //         set_cls_active = 1;
                    //     } else {
                    //         if (last_id_sel != id_sel) {
                    //             set_cls_active = 1;
                    //         }
                    //     }

                    //     if (set_cls_active == 1) {

                    //         $(this).addClass("active-row");

                    //         window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //         console.log("ID: ", window.id_par_sel);

                    //         pointerVue.hideShowButtons(true);

                    //     } else {

                    //         pointerVue.hideShowButtons(false);

                    //     }

                    // }, 100);

                });

                // }

                //setup ionic calendar top
                // setTimeout(function () {



                //     $(".selectstatusall").on("ionChange", function (opt) {
                //         console.log(opt);
                //         $(".spin").show();
                //         window.curStatus = this.value;



                //         window.table.ajax.reload();

                //     });



                //     window.currDay = date;
                //     window.currDayTo = date;

                // }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/export-emt.png",
                            link: "/settings",
                            id: "btn_export_xls",
                            disabled: false,
                            title: "Esporta Excel",
                            width: 30
                        },

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/import-emt.png",
                            link: "/settings",
                            id: "btn_import_xls",
                            disabled: false,
                            title: "Importa Excel",
                            width: 30
                        },


                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica",
                            width: 30
                        },


                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/view-emt.png",
                            link: "/info",
                            id: "btn_view",
                            disabled: false,
                            title: "Vedi",
                            width: 45
                        },



                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        },

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/export-emt.png",
                            link: "/settings",
                            id: "btn_export_xls",
                            disabled: false,
                            title: "Esporta Excel",
                            width: 30
                        },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-emt.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_edit_emt.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        exportXls: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;


            if (window.curSrcText == null) {
                window.curSrcText = "";
            }

            if (window.curCompany == null) {
                window.curCompany = "";
            }

            if (window.curFormaGiuId == null) {
                window.curFormaGiuId = "";
            }

            if (window.curSecActId == null) {
                window.curSecActId = "";
            }

            if (window.curComId == null) {
                window.curComId = "";
            }

            if (window.curRefId == null) {
                window.curRefId = "";
            }

            if (window.curPropId == null) {
                window.curPropId = "";
            }

            if (window.curPratId == null) {
                window.curPratId = "";
            }

            if (window.curTipoProgId == null) {
                window.curTipoProgId = "";
            }

            if (window.curDimAzi == null) {
                window.curDimAzi = "";
            }

            if (window.curProvincia == null) {
                window.curProvincia = "";
            }

            if (window.curStato == null) {
                window.curStato = "";
            }

            if (window.curAziendaArtigiana == null) {
                window.curAziendaArtigiana = "";
            }

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiaziende.exportXlsAgila(
                v_token,
                window.curSrcText,
                window.curCompany,
                window.curFormaGiuId,
                window.curSecActId,
                window.curComId,
                window.curRefId,
                window.curPropId,
                window.curPratId,
                window.curTipoProgId,
                window.curDimAzi,
                window.curProvincia,
                window.curStato,
                window.curAziendaArtigiana
            ).then(async (res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from exportXlsAgila", res);

                var fullpath = res.data.FullPath;
                var file_path = res.data.ValUrl;
                var a = document.createElement('A');
                a.href = file_path;
                a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);


                var response3 = await apitimesheet.deleteFile(

                    fullpath

                ).then(async (res3) => {

                    console.log("res from deleteFile", res3);

                    console.log(response3);

                    this.showSpinner = false;







                }).catch(err3 => {

                    console.log(response2);
                    console.log(err3);

                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err3.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },



        importXls: async function () {

            window.$cookies.set("aziende_import_xls_from", "Agila", "9y");

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$modal.hide('popupSearch2');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiaziende.importXlsAgila(
                v_token,
                $('#input-file-logo')[0].files[0]
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from importXlsAgila", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Procedura eseguita correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        router.push({
                            path: "/importResult"
                        });

                    }, 200);


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },


        initFilterValues: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiaziende.initFilterValues(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initFilterValues", res);

                that.aziende = res.data.company;

                that.settori_att = res.data.filtro_settori;

                that.commercialisti = res.data.filtro_com;

                that.referenti = res.data.filtro_refs;

                that.proprietari = res.data.filtro_props;

                that.pratiche = res.data.filtro_prats;

                that.tipo_prog = res.data.filtro_ptypes;

                that.province = res.data.filter_province;





            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },







        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiaziende.deleteAzienda(
                            ids,
                            v_token
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteAzienda", res);

                            if (res.data.Result == "OK") {

                                that.$swal({
                                    icon: "success",
                                    text: "Dati eliminati correttamente",
                                    showConfirmButton: false,
                                    timer: 2000
                                });

                                setTimeout(() => {

                                    that.dialogMsg = false;
                                    that.setupButtons();

                                    that.hideShowButtons(false);

                                    window.table.ajax.reload();

                                }, 200);



                            }
                            else {


                                that.$swal({
                                    icon: "error",
                                    text: res.data.Errore,
                                    showConfirmButton: false,
                                    timer: 2000
                                });


                            }


                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
#fldValueOtpDelete {
    text-align: center;
    font-size: 20px
}


.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}


ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.cont_company_agila {
    overflow-x: auto !important;
}

.cont_company_agila #myTable {
    margin-right: 3px;
    width: 1785px !important;
}

.cont_company_agila table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_company_agila table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 9px !important;
}

.cont_company_agila th {
    padding-left: 5px !important;
}

.cont_company_agila ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_company_agila .table-responsive {
    padding: 0px;
    min-width: 90vw;
    max-width: 100vw;
}

.cont_company_agila #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #f5cf2e;
}

.cont_company_agila div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_company_agila #myTable_wrapper {
    padding-top: 0px;
    font-size: 11px;
    color: black;
    font-weight: bold;
    max-width: 1800px;
    width: 1800px;
}

.cont_company_agila .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 0vw
}

.cont_company_agila .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.cont_company_agila .selectstatusall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    background-image: url(/emt/img/Filtro.png);
    text-indent: -119px;
    background-size: 24px !important;
    background-repeat: no-repeat;
    transform: translateY(2px);
    margin-top: -5px !important;

}

.cont_company_agila .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_company_agila .val_status {
    width: 10px !important;
}


.cont_company_agila .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
    color: black !important;
}


.cont_company_agila .dropify-wrapper {
    height: 236px !important;
    width: 300px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.cont_company_agila .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 2px;
}

.cont_company_agila .selformagiuridica {
    max-width: 26px;
    min-height: 26px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.cont_company_agila .selfiltsect {
    max-width: 29px;
    min-height: 29px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_company_agila .selefiltcom {
    max-width: 26px;
    min-height: 26px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_company_agila .selrefs {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_company_agila .selprop {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.cont_company_agila .seldimazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_company_agila .selprovince {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.cont_company_agila .selstato {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 27px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}



.cont_company_agila .selaziendaartigiana {
    max-width: 24px;
    min-height: 24px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 24px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}





.cont_company_agila .selprat {
    max-width: 26px;
    min-height: 26px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_company_agila .selptype {
    max-width: 26px;
    min-height: 26px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 23px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.cont_company_agila .cancelfilters {
    max-width: 22px;
    min-height: 22px;
    margin-left: 42px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 22px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 4px;
}





@media screen and (max-width: 960px) {

    .cont_company_agila .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_company_agila #myTable {
        /* margin-top:54px; */
    }

    .cont_company_agila #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_company_agila #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_company_agila #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_company_agila .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }

    .cont_company_agila #myTable_wrapper {
        min-width: initial;
        min-width: 95vw !important;
        max-width: 95vw !important;
        width: 95vw !important;
        overflow-x: hidden !important;
    }

    .cont_company_agila .cl_num {
        margin-left: 17px !important;
    }

    .cl_n_matricola {
        display: inline-flex;
    }

    .cl_desc_project {
        display: inline-flex;
    }

    .cl_costo_giornaliero {
        display: inline-flex;
    }

    .cl_diaria {
        display: inline-flex;
    }

    .cl_ticket {
        display: inline-flex;
    }

    .cl_piano_orario {
        display: inline-flex;
    }

    .cl_ebadge {
        display: inline-flex;
    }

    .cl_totale_ferie {
        display: inline-flex;
    }

}
</style>
