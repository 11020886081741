import { render, staticRenderFns } from "./changepasswordprofile.vue?vue&type=template&id=3e701561&scoped=true&"
import script from "./changepasswordprofile.vue?vue&type=script&lang=js&"
export * from "./changepasswordprofile.vue?vue&type=script&lang=js&"
import style0 from "./changepasswordprofile.vue?vue&type=style&index=0&id=3e701561&scoped=true&lang=css&"
import style1 from "./changepasswordprofile.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e701561",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VContainer,VForm,VRow,VTextField})
