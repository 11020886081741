var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center d-flex align-center justify-center",staticStyle:{"max-width":"300px"},attrs:{"fluid":"","fill-height":""}},[(_vm.isSU == 0 && _vm.viewTsClienti == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/initVerificaTimesheetCliente"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/timesheet3.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Timesheet")])]}}],null,false,812515543)},[_c('span',[_vm._v("Timesheet")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isPwdChangedOnce > 0 && _vm.viewEditTimesheet == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnTs",attrs:{"icon":"","id":"no-background-hover"},on:{"click":function($event){return _vm.checkConfTsDip()}}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/timesheet3.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Timesheet")])]}}],null,false,1528750003)},[_c('span',[_vm._v("Timesheet")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isCustSupp > 0)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":"no-background-hover","to":"/initVerificaTimesheetFornitori"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/timesheet3.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Timesheet")])]}}],null,false,2433523948)},[_c('span',[_vm._v("Timesheet")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isPwdChangedOnce > 0 && _vm.viewEditDisease == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/initDisease"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/malattia4.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Malattia")])]}}],null,false,977503487)},[_c('span',[_vm._v("Malattia")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isPwdChangedOnce > 0 && _vm.viewEditHolidaysPermits == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/initHoliday"}},on),[_c('v-img',{staticClass:" ",attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/ferie2.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Ferie")])]}}],null,false,2841352683)},[_c('span',[_vm._v("Ferie")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isPwdChangedOnce > 0 && _vm.viewVerTs == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/filterVerificaTimesheet"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/verifica_ts.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Verifica Timesheet")])]}}],null,false,3244164146)},[_c('span',[_vm._v("Verifica Timesheet")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isPwdChangedOnce > 0 && _vm.viewRisorse == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/filterRisorse"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/risorse2.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Risorse")])]}}],null,false,3202575092)},[_c('span',[_vm._v("Risorse")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/initPratiche"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/pratiche-emt.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Pratiche")])]}}],null,false,3043541502)},[_c('span',[_vm._v("Pratiche")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/controlli/MA==l/MA==l/MA==l/MA==l"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/controlli-emt.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Controlli")])]}}],null,false,3122349558)},[_c('span',[_vm._v("Controlli")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/referenti"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/referenti_emt.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Referenti")])]}}],null,false,2749795382)},[_c('span',[_vm._v("Referenti")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/initCompanyAgila"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/aziende.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Aziende")])]}}],null,false,2611465914)},[_c('span',[_vm._v("Aziende")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/modalitaFatturazione/MA==l"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/mod-fatturazione-emt.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Modalità Fatturazione")])]}}],null,false,648134567)},[_c('span',[_vm._v("Modalità Fatturazione")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isPwdChangedOnce > 0 && _vm.viewGestioneUtenti == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/filterusers"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"60","contain":"","src":require("@/assets/users_menu3.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Utenti")])]}}],null,false,2502010759)},[_c('span',[_vm._v("Utenti")])])],1):_vm._e(),(_vm.viewTsClienti == 0 && _vm.isAgila == 1)?_c('v-col',{staticClass:"mb-15",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":false,"icon":"","id":"no-background-hover","to":"/scadenze/MA==l"}},on),[_c('v-img',{staticClass:" ",staticStyle:{"margin-top":"16px"},attrs:{"alt":"Emt logo","width":"48","contain":"","src":require("@/assets/scadenze-emt.png"),"transition":"scale-transition"}})],1),_c('div',{staticClass:"btnText"},[_vm._v("Scadenze")])]}}],null,false,299530010)},[_c('span',[_vm._v("Scadenze")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }