import axios from 'axios'

class APIMailGruppo {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;

    }

    async setMailGruppo(mail_gruppo, id, user) {


        let formData = new FormData();

        formData.append("id", id);
        formData.append("username", user);

        for (var key in mail_gruppo) {
            formData.append(key, mail_gruppo[key]);
        }

        return axios.post(this.getUrlDomain("InvoiceSetMailGruppoEmt"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getMailGruppo(id, user, prodotto) {


        let formData = new FormData();

        formData.append("id", id);
        formData.append("username", user);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("InvoiceGetMailGruppoEmt"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async deleteMailGruppo(id, user) {


        let formData = new FormData();

        formData.append("id", id);
        formData.append("v_user", user);


        return axios.post(this.getUrlDomain("deleteMailGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async deleteMultiMailGruppo(id, user) {


        let formData = new FormData();

        formData.append("id", id);
        formData.append("username", user);


        return axios.post(this.getUrlDomain("InvoiceDelMailGruppoEmt"), formData, { 'Content-Type': 'multipart/form-data' });

    }





}

export default new APIMailGruppo({
    url: "http://emtool.local/public/api/alertduedate/"
})
