<template>
    <div>
        <input v-model="code" placeholder="Inserisci codice TOTP">
        <button @click="verifyCode">Verifica codice</button>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    data() {
        return {
            code: ''
        };
    },
    methods: {
        async verifyCode() {
            try {
                const response = await axios.post('http://emtool.local/verify-2fa', { code: this.code, v_user: "admin2_c" });
                alert(response.message);
            } catch (error) {
                alert(error.response.message);
            }
        }
    }
};
</script>