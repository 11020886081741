<template>
    <v-container class="w-container-edit-cespiti-admin v-overflow editCespitiAdmin"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
                background-color: white;
                color: black;
                font-weight: bold;
                padding: 4px;
                font-size: 10px;
                min-width:100vw;
                border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>


        <v-form v-model="valid" ref="form" lazy-validation>



            <v-row>

                <v-col cols="12" md="12" style="margin-top:10px!important">

                    <label style="font-size:12px;margin-left:8px;">RAL</label>

                    <div id="d_fido_ant_popup"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="resources_costi_aziendali.ral" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0',
                                        modifyValueOnWheel: false,
                                    }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12" style="margin-top:10px!important">

                    <label style="font-size:12px;margin-left:8px;">Costo Aziendale</label>

                    <div id="d_fido_ant_popup"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="resources_costi_aziendali.costo_az" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0',
                                        modifyValueOnWheel: false,
                                    }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" style="margin-top:10px!important">

                    <label style="font-size:12px;margin-left:8px;">Ticket</label>

                    <div id="d_fido_ant_popup"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="resources_costi_aziendali.ticket" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0',
                                        modifyValueOnWheel: false,
                                    }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" style="margin-top:10px!important">

                    <label style="font-size:12px;margin-left:8px;">Diaria</label>

                    <div id="d_fido_ant_popup"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="resources_costi_aziendali.diaria" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0',
                                        modifyValueOnWheel: false,
                                    }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12" style="margin-top:10px!important">

                    <label style="font-size:12px;margin-left:8px;">Sgravi</label>

                    <div id="d_fido_ant_popup"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;"></label>

                                    <vue-autonumeric v-model="resources_costi_aziendali.sgravi" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '-999999999',
                                        modifyValueOnWheel: false,
                                        allowNegative: true
                                    }" ref="fldFidoAnt" id="fldFidoAnt"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>


        </v-form>








    </v-container>
</template>

<script>
import apicostiaziendali from "../utils/costiaziendali/apicostiaziendali";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

import Vue from "vue";
import VModal from 'vue-js-modal';


export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        Vue.use(VModal, {
            dialog: true
        });

        /*eslint-disable no-undef*/

        var pointerVue = this;


        this.setupButtons();

        if (this.$route.params.id == 0) {

            this.titolo = "Aggiungi Costi Aziendali";


        }

        if (this.$route.params.id > 0) {

            this.titolo = "Copia Costi Aziendali";

            setTimeout(() => {


                this.syncCostiAziendali();


            }, 100);

        }



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/costiAziendali"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {



            $('body').on('click', '.btn-confirm-fido-ant', function (event) {
                console.log(event);

                pointerVue.banca.fido_anticipi = pointerVue.fidoAnt;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_ant");

                pointerVue.$modal.hide('popupFidoAnt');

            });


            $('body').on('click', '.btn-cancel-fido-ant', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoAnt');

            });

            $('body').on('click', '#d_fido_ant', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoAnt');

            });


            $('body').on('click', '#cont_idresource', function (event) {
                console.log(event);

                pointerVue.sheetRisorse = true;

            });



            $('body').on('click', '#cont_idmailgroup', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });




            $('body').on('click', '#d_importo_user', function (event) {
                console.log(event);

                pointerVue.$modal.show('popupImporto');

            });


            $('body').on('click', '.btn-confirm-massimale', function (event) {
                console.log(event);

                pointerVue.utente.max_easyer = pointerVue.valoreMassimale;

                pointerVue.$root.$children[0].setFieldActive("#d_importo_user");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-massimale', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });






            $('body').on('click', '.btn-confirm-fido-cassa', function (event) {
                console.log(event);

                pointerVue.banca.fido_cassa = pointerVue.fidoCassa;

                pointerVue.$root.$children[0].setFieldActive("#d_fido_cassa");

                pointerVue.$modal.hide('popupFidoCassa');

            });


            $('body').on('click', '.btn-cancel-fido-cassa', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupFidoCassa');

            });

            $('body').on('click', '#d_fido_cassa', function (event) {
                console.log(event);


                pointerVue.$modal.show('popupFidoCassa');

            });


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);


            $(".idassettype").on("ionChange", function (opt) {
                console.log(opt);

                if ((this.value == 4) || (this.value == 1) || (this.value == 2) || (this.value == 3)) {

                    pointerVue.visNoleggio = true;

                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = true;
                    pointerVue.visOP = true;
                    pointerVue.visSw = true;


                    pointerVue.visMemNB = true;
                    pointerVue.visDiscoNB = true;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }


                if ((this.value == 5) || (this.value == 6)) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = true;
                    pointerVue.visTelNum = true;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }

                if (this.value == 7) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = false;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = true;
                    pointerVue.visVeicolo = true;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }

                if (this.value == 9) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = false;

                }


                if (this.value == 10) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = true;

                    pointerVue.visPollici = false;

                }


                if (this.value == 11) {

                    pointerVue.visNoleggio = true;


                    pointerVue.visDescrizione = true;
                    pointerVue.visNMatricola = false;
                    pointerVue.visOP = false;
                    pointerVue.visSw = false;

                    pointerVue.visMemNB = false;
                    pointerVue.visDiscoNB = false;


                    pointerVue.visNSim = false;
                    pointerVue.visTelNum = false;

                    pointerVue.visTarga = false;
                    pointerVue.visVeicolo = false;

                    pointerVue.visTipoDisco = false;

                    pointerVue.visPollici = true;



                }




            });




        });

    },

    components: {
        VueAutonumeric,
    },


    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredRisorse() {

            return _.orderBy(this.risorse.filter(item => {
                if (!this.search) return this.risorse;
                return (item.resource.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


    },

    data: () => ({


        resources_costi_aziendali: {
            ral: null,
            costo_az: null,
            ticket: null,
            diaria: null,
            sgravi: null
        },




        visNoleggio: false,

        risNomeSede: "",

        attachRemove: false,

        sheetRisorse: false,

        fidoAnt: null,
        fidoCassa: null,

        visDescrizione: false,
        visNMatricola: false,
        visOP: false,
        visSw: false,
        visTipoDisco: false,

        visPollici: false,

        visNSim: false,
        visTelNum: false,

        visTarga: false,
        visVeicolo: false,

        visMemNB: false,
        visDiscoNB: false,


        sheetGruppi: false,

        searchGruppi: "",

        tipo_cespiti: [],

        risorse: [],

        banca: {},

        cespiti: { noleggio: "N" },

        showPicker2: false,

        valoreMassimale: null,

        changePsw: false,

        titolo: "",
        currDipendenteObj: {},

        gruppi_checked: [],

        isCurrentUserSU: "N",

        aziende: [],
        prefissi: [],

        dipendenti: [],
        currAzienda: "",

        gruppi: [],

        selNuclei: null,

        tab: null,

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        utente: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],

        aziendaRules: [
            (v) => !!v || "Azienda richiesta",
        ],

        usernameRules: [
            (v) => !!v || "Username richiesto",
        ],

        select: null,

        // items: [
        //     'Informazioni Generali', 'Gruppo', 'Autenticazione', 'QR Code'
        // ],

        items: [
            'Informazioni', 'Gruppo', 'Nota Spese'
        ],

        checkbox: false,

        tipo_qrcode: [{
            "id": "USR_PWD",
            "value": "Utente E Password"
        },
        {
            "id": "FCODE_PWD",
            "value": "Utente E Codice Fiscale"
        }
        ],

        tipi_dischi: [{
            "id": "RPM",
            "value": "RPM"
        },
        {
            "id": "SSD",
            "value": "SSD"
        }
        ]

    }),

    methods: {


        manageClickRisorse: async function (risorsa) {

            console.log("RIS SEL: ", risorsa);

            this.sheetRisorse = false;

            this.cespiti.idresource = risorsa.resourceid;

            //  this.syncOfficeRes(risorsa.resourceid);

        },




        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.banca.companyid = azienda.companyid;

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.banca.idmailgroup = gruppo.idmailgroup;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    // class: "inverted",
                    title: "Salva",
                    width: 30

                },
                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35

                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;


            var errore = "";

            if (that.resources_costi_aziendali.ral == null) {
                errore = "RAL valore richiesto";
            }

            if (errore == "") {
                if (that.resources_costi_aziendali.ral == "") {
                    errore = "RAL valore richiesto";
                }
            }



            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },

        saveData: async function () {



            var that = this;

            that.cespiti.idasset_type = $("#idassettype").val();
            that.cespiti.tipo_disco = $("#tipo_disco").val();

            console.log("CESPITI: ", that.cespiti);


            var response = null;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apicostiaziendali.saveCostiAziendali(
                that.resources_costi_aziendali,
                0
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveCostiAziendali", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/costiAziendali"
                        });

                    }, 200);

                }
                else {

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );








        },



        syncCostiAziendali: async function () {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicostiaziendali.editCostiAziendali(
                that.$route.params.id,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from editCostiAziendali", res);

                that.resources_costi_aziendali = res.data.Result;

                that.resources_costi_aziendali.costo_az = null;

                that.resources_costi_aziendali.ticket = null;

                that.resources_costi_aziendali.diaria = null;

                that.resources_costi_aziendali.sgravi = null;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncBanca: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibanche.getBanca(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getBanca", res);

                this.aziende = res.data.aziende;

                this.gruppi = res.data.invoice_mail_group;

                this.banca = res.data.banca;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



    }

})
</script>

<style>
.cl_importo {
    padding-left: 0px !important;
}


.itemAzienda {
    margin-left: 15px;
}

.itemRisorsa {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetRisorse {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemGruppo {
    margin-left: 15px;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}





.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-edit-cespiti-admin {
    width: 500px;
    padding: 0px;
    overflow-x: hidden;
}

/* .w-container-users {
    width: 700px;
    padding: 0px;
} */

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.editCespitiAdmin .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.editCespitiAdmin .v-input {
    font-size: 1.2em !important;
}

.editCespitiAdmin .v-select {
    font-size: 1.2em !important;
}

.editCespitiAdmin .v-label {
    font-size: 1em !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    font-size: 12px;
    padding: 0px;
}

@media screen and (max-width: 768px) {

    .w-container-edit-cespiti-admin {
        width: 100vw;
        padding: 0px;
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .w-container-edit-cespiti-admin .dropify-wrapper {
        width: 353px !important;

    }

}

.theme--light.v-tabs>.v-tabs-bar,
.theme--light.v-tabs-items {
    background-color: transparent;
}
</style>
