import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APILogMail {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async deleteLogMail(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);


    return axios.post(this.getUrlDomain("EmtMailLogDelete"), formData, { 'Content-Type': 'multipart/form-data' });

  }




}

export default new APILogMail({
  url: "https://services.ebadge.it/public/api/"
})
