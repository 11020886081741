<template>
    <v-container class="w-container v-overflow edit_conf_prod" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">


                <div class="mainTitle" style="
                                                            background-color: white;
                                                            color: black;
                                                            font-weight: bold;
                                                            padding: 4px;
                                                            font-size: 10px;
                                                            min-width:100vw;
                                                            border-color:#f5cf2e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_productid" style="cursor:pointer">
                        <ion-item id="itemProduct"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Prodotto </ion-label>

                            <ion-select id="productid" name="productid" class='productid' cancel-Text="Chiudi" done-Text=""
                                :value="conf_prodotto.productid" interface="action-sheet">

                                <ion-select-option v-for="item in prodotti" :key="item.productid" :value='item.productid'>
                                    {{ item.description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">
                    <div id="cont_tipo" style="cursor:pointer">
                        <ion-item id="itemTipo"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Tipo </ion-label>

                            <ion-select id="tipo" name="tipo" class='tipo' cancel-Text="Chiudi" done-Text=""
                                :value="conf_prodotto.tipo" interface="action-sheet">

                                <ion-select-option v-for="item in tipi" :key="item.value" :value='item.value'> {{
                                    item.description }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="viewUtenti">

                <v-col cols="12" md="12">
                    <div id="cont_id_user" style="cursor:pointer">
                        <ion-item id="itemUser"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Utente </ion-label>

                            <ion-select id="id_user" name="id_user" class='id_user' cancel-Text="Chiudi" done-Text=""
                                :value="conf_prodotto.id_user" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in utenti" :key="item.id" :value='item.id'> {{ item.fullname
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>


            <v-row v-if="viewGruppi">

                <v-col cols="12" md="12">
                    <div id="cont_id_group" style="cursor:pointer">
                        <ion-item id="itemGroup"
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Gruppo </ion-label>

                            <ion-select id="id_group" name="id_group" class='id_group' cancel-Text="Chiudi" done-Text=""
                                :value="conf_prodotto.id_group" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in gruppi" :key="item.id" :value='item.id'> {{ item.name
                                }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row v-if="viewGruppi || viewUtenti" style="padding-bottom:0px!important;margin-bottom:0px!important">

                <v-col cols="12" md="12" style="padding-bottom:0px!important;margin-bottom:0px!important">
                    <v-checkbox label="Vista In Home" class="cl-checkbox" v-model="viewInHome" true-value="S"
                        false-value="N" id="viewInHome"></v-checkbox>
                </v-col>

            </v-row>


            <v-row v-if="viewGruppi || viewUtenti" style="padding-bottom:0px!important;margin-bottom:0px!important">
                <v-col cols="12" md="12" style="padding-bottom:0px!important;margin-bottom:0px!important">
                    <v-checkbox label="Versione Trial" class="cl-checkbox" v-model="conf_prodotto.is_trial" true-value="S"
                        false-value="N" id="trailVersion" @click="chkChange()"></v-checkbox>
                </v-col>
            </v-row>

            <v-row v-if="showPeriod" style="padding-bottom:0px!important;margin-bottom:0px!important">
                <v-col cols="12" md="12" style="padding-bottom:0px!important;margin-bottom:0px!important">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="conf_prodotto.start_trial" label="Data Inizio" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="conf_prodotto.start_trial" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>




                </v-col>
            </v-row>

            <v-row v-if="showPeriod" style="padding-bottom:0px!important;margin-bottom:0px!important">
                <v-col cols="12" md="12" style="padding-bottom:0px!important;margin-bottom:0px!important">

                    <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="conf_prodotto.end_trial" label="Data Fine" persistent-hint readonly
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="conf_prodotto.end_trial" no-title @input="showPicker2 = false"
                            locale="it"></v-date-picker>
                    </v-menu>




                </v-col>
            </v-row>



        </v-form>

        <div class="contutenti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetUtenti" class="vutentisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetUtenti" fixed style="position:fixed;">
                                Utenti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetUtenti = !sheetUtenti">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchUtenti">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredUtenti" :key="item.id"
                                        @click="manageClickUtenti(item)">

                                        <v-img alt="" src="@/assets/profilo3.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemUtente'>{{ item.fullname }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contgruppi">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetGruppi" class="vgruppisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetGruppi" fixed style="position:fixed;">
                                Gruppi
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetGruppi = !sheetGruppi">

                                    <v-img alt="" src="@/assets/btn_cancel3_emt.png" max-width="24" max-height="24"
                                        style="margin-right:10px" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchGruppi">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredGruppi" :key="item.id"
                                        @click="manageClickGruppi(item)">

                                        <v-img alt="" src="@/assets/risorse2.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemGruppo'>{{ item.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>



    </v-container>
</template>

<script>
import apipianoorario from "../utils/pianoorario/apipianoorario";
import apiconfigurationproduct from "../utils/configurationproduct/apiconfigurationproduct";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        this.cur_id = this.$route.params.id;

        this.prodotti = window.ddlProducts;


        console.log("UTENTI: ", window.ddlUsers);
        console.log("GRUPPI: ", window.ddlGroups);

        // this.utenti = window.ddlUsers;

        this.gruppi = window.ddlGroups;

        this.setupButtons();

        var v_id = this.decodeString(this.$route.params.id);



        setTimeout(() => {

            this.syncUtenti();

        }, 100);



        setTimeout(() => {

            if (v_id > 0) {

                this.titolo = "Modifica Configurazione Prodotto";

                this.syncPianoOrario();

            }



        }, 200);







        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    //this.validate();

                    //this.saveData();

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/configurationProducts"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {


            $(".productid").on("ionChange", function (opt) {
                console.log(opt);

                console.log(this.value);
                // pointerVue.updateTotal();

                pointerVue.conf_prodotto.productid = this.value;

                this.setupButtons();


            });


            $(".tipo").on("ionChange", function (opt) {
                console.log(opt);

                console.log(this.value);
                // pointerVue.updateTotal();

                pointerVue.conf_prodotto.tipo = this.value;

                if (this.value == "U") {

                    pointerVue.viewGruppi = false;
                    pointerVue.viewUtenti = true;

                }

                if (this.value == "G") {

                    pointerVue.viewGruppi = true;
                    pointerVue.viewUtenti = false;


                }

                this.setupButtons();

            });











            $('body').on('click', '#cont_id_user', function (event) {
                console.log(event);

                pointerVue.sheetUtenti = true;

            });

            $('body').on('click', '#cont_id_group', function (event) {
                console.log(event);

                pointerVue.sheetGruppi = true;

            });


            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }

            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }

            });

            $('body').on('click', '.img_copy', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreDaPagare;

            });

            $('body').on('click', '.img_copy_rim', function (event) {
                console.log(event);

                pointerVue.valorePagato = pointerVue.valoreRimanente;

            });

            $('body').on('click', '#d_importo', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.$modal.show('popupImporto');

                }

            });

            $('body').on('click', '.btn-confirm-importo', function (event) {
                console.log(event);

                pointerVue.pagamento.importo = pointerVue.valoreDaPagare;

                pointerVue.$root.$children[0].setFieldActive("#d_importo");

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '.btn-cancel-importo', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImporto');

            });

            $('body').on('click', '#d_pagato', function (event) {
                console.log(event);

                if (pointerVue.$route.params.id == 0) {

                    pointerVue.$modal.show('popupImportoPagato');

                }

                if (pointerVue.$route.params.id > 0) {

                    pointerVue.$modal.show('popupImportoPagatoRimanente');

                }

            });

            $('body').on('click', '.btn-confirm-importo-pagato', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-cancel-importo-pagato', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagato');

            });

            $('body').on('click', '.btn-confirm-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.pagamento.importo_pagato = pointerVue.valorePagato;

                pointerVue.$root.$children[0].setFieldActive("#d_pagato");

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            $('body').on('click', '.btn-cancel-importo-pagato-rimanente', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupImportoPagatoRimanente');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

            $(".lunedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".martedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".mercoledi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".giovedi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".venerdi").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".sabato").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

            $(".domenica").on("ionChange", function (opt) {
                console.log(opt);

                pointerVue.updateTotal();

            });

        });

    },

    computed: {

        filteredGruppi() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                return (item.name.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },



        filteredUtenti() {

            return _.orderBy(this.utenti.filter(item => {
                if (!this.searchUtenti) return this.utenti;
                return (item.fullname.toLowerCase().includes(this.searchUtenti.toLowerCase()));
            }), 'headline')
        },



        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        showPeriod: false,


        viewGruppi: false,
        viewUtenti: false,

        viewInHome: "N",
        manageUsers: "N",

        sheetUtenti: false,
        searchUtenti: "",

        sheetGruppi: false,
        searchGruppi: "",


        prodotti: [],

        gruppi: [],

        utenti: [],

        conf_prodotto: { productid: "", tipo: "", id_user: "", id_group: "", is_trial: "N", start_trial: "", end_trial: "" },

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        valTotale: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,

        pianoorario: {},

        dipendenti: [],

        ore: [],

        currAzienda: "",
        titolo: "Aggiungi Configurazione Prodotto",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        // tipologie: [],

        tipi: [{
            "value": "U",
            "description": "Utente"
        }, {
            "value": "G",
            "description": "Gruppo"
        }],



        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: ""
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: [],

    }),

    methods: {

        chkChange: function () {



            console.log("VAL CHK: ", this.conf_prodotto.is_trial);

            if (this.conf_prodotto.is_trial == "S") {

                this.conf_prodotto.start_trial = null;
                this.conf_prodotto.end_trial = null;

                this.showPeriod = true;

            }
            else {

                this.conf_prodotto.start_trial = null;
                this.conf_prodotto.end_trial = null;


                this.showPeriod = false;

            }




        },

        decodeString: function (value) {

            var v = value.substring(0, value.length - 1);

            console.log("VAL: ", v);

            return atob(v);

        },

        enableSaveButton: function () {

            var result = 1;

            console.log("CONF PROD: ", this.conf_prodotto);

            if (this.conf_prodotto.productid == "") {
                result = 0;
            }

            if (result == 1) {
                if (this.conf_prodotto.tipo == "") {
                    result = 0;
                }
            }

            if (result == 1) {

                if (this.conf_prodotto.tipo == "U") {

                    if (this.conf_prodotto.id_user == "") {
                        result = 0;
                    }

                }


                if (this.conf_prodotto.tipo == "G") {

                    if (this.conf_prodotto.id_group == "") {
                        result = 0;
                    }

                }


            }



            return result;

        },


        updateTotal() {

            console.log("LUNEDI: ", $(".lunedi").val());

            var v_lunedi = 0;
            if ($(".lunedi").val() != "") {
                v_lunedi = $(".lunedi").val();
            }

            var v_martedi = 0;
            if ($(".martedi").val() != "") {
                v_martedi = $(".martedi").val();
            }

            var v_mercoledi = 0;
            if ($(".mercoledi").val() != "") {
                v_mercoledi = $(".mercoledi").val();
            }

            var v_giovedi = 0;
            if ($(".giovedi").val() != "") {
                v_giovedi = $(".giovedi").val();
            }

            var v_venerdi = 0;
            if ($(".venerdi").val() != "") {
                v_venerdi = $(".venerdi").val();
            }

            var v_sabato = 0;
            if ($(".sabato").val() != "") {
                v_sabato = $(".sabato").val();
            }

            var v_domenica = 0;
            if ($(".domenica").val() != "") {
                v_domenica = $(".domenica").val();
            }

            var v_tot = parseFloat(v_lunedi) + parseFloat(v_martedi) + parseFloat(v_mercoledi) + parseFloat(v_giovedi) + parseFloat(v_venerdi) + parseFloat(v_sabato) + parseFloat(v_domenica);

            console.log("TOT: ", v_tot);

            this.valTotale = v_tot.toFixed(2);

        },

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.pianoorario.companyid = azienda.companyid;

        },


        manageClickUtenti: async function (utente) {

            console.log("UT SEL: ", utente);
            console.log("ID UT SEL: ", utente.id);

            this.sheetUtenti = false;

            this.conf_prodotto.id_user = utente.id;
            //this.conf_prodotto.id_user = 83;

            this.setupButtons();

        },

        manageClickGruppi: async function (gruppo) {

            console.log("GRP SEL: ", gruppo);

            this.sheetGruppi = false;

            this.conf_prodotto.id_group = gruppo.id;

            this.setupButtons();

        },



        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                var enableSave = this.enableSaveButton();

                if (enableSave == 1) {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30
                    });

                }

                pulsantis.push({
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },


        setupButtonsOld: async function () {


            // enableSaveButton


            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                        title: "Salva",
                        width: 30

                    },


                    {
                        text: "Indietro",
                        icon: "mdi-arrow-left",
                        image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        width: 35
                    },


                ];

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);

        },

        validate() {


            var that = this;

            var errore = "";

            if (that.conf_prodotto.is_trial == "S") {
                if (that.conf_prodotto.start_trial == "") {
                    errore = "Specificare la Data Inizio";
                }
            }

            if (errore == "") {
                if (that.conf_prodotto.is_trial == "S") {
                    if (that.conf_prodotto.start_trial == null) {
                        errore = "Specificare la Data Inizio";
                    }
                }
            }


            if (errore == "") {
                if (that.conf_prodotto.is_trial == "S") {
                    if (that.conf_prodotto.end_trial == "") {
                        errore = "Specificare la Data Fine";
                    }
                }
            }

            if (errore == "") {
                if (that.conf_prodotto.is_trial == "S") {
                    if (that.conf_prodotto.end_trial == null) {
                        errore = "Specificare la Data Fine";
                    }
                }
            }


            if (errore == "") {
                if (that.conf_prodotto.is_trial == "S") {


                    var str_dt_start = that.conf_prodotto.start_trial.toString() + " 00:00:00";

                    var str_dt_end = that.conf_prodotto.end_trial.toString() + " 00:00:00";


                    var dt_start = new Date(str_dt_start).getTime();
                    var dt_end = new Date(str_dt_end).getTime();

                    if (dt_start > dt_end) {

                        errore = "Data Fine deve essere maggiore di Data Inizio";


                    }


                }
            }



            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },

        validateOld() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();

            console.log(res);

            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                //console.log("CUR FILE: ", that.currentFile);

                var errore = "";

                if (errore == "") {

                    if ($("#companyid").val() == "") {

                        errore = "Specificare l'azienda";

                    }

                }

                if (errore == "") {

                    if ($("#codice").val() == "") {

                        errore = "Specificare il codice";

                    }

                }

                if (errore == "") {

                    if ($("#descrizione").val() == "") {

                        errore = "Specificare la descrizione";

                    }

                }

                if (errore == "") {

                    if ($("#totale").val() == "") {

                        errore = "Specificare le ore";

                    }

                }

                if (errore == "") {

                    if (parseFloat($("#totale").val()) == 0) {

                        errore = "Specificare le ore";

                    }

                }

                if (errore == "") {

                    that.pianoorario.companyid = $("#companyid").val();

                    that.pianoorario.lunedi = 0;
                    if ($("#lunedi").val() != "") {
                        that.pianoorario.lunedi = $("#lunedi").val();
                    }

                    that.pianoorario.martedi = 0;
                    if ($("#martedi").val() != "") {
                        that.pianoorario.martedi = $("#martedi").val();
                    }

                    that.pianoorario.mercoledi = 0;
                    if ($("#mercoledi").val() != "") {
                        that.pianoorario.mercoledi = $("#mercoledi").val();
                    }

                    that.pianoorario.giovedi = 0;
                    if ($("#giovedi").val() != "") {
                        that.pianoorario.giovedi = $("#giovedi").val();
                    }

                    that.pianoorario.venerdi = 0;
                    if ($("#venerdi").val() != "") {
                        that.pianoorario.venerdi = $("#venerdi").val();
                    }

                    that.pianoorario.sabato = 0;
                    if ($("#sabato").val() != "") {
                        that.pianoorario.sabato = $("#sabato").val();
                    }

                    that.pianoorario.domenica = 0;
                    if ($("#domenica").val() != "") {
                        that.pianoorario.domenica = $("#domenica").val();
                    }

                    that.pianoorario.total = 0;
                    if ($("#totale").val() != "") {
                        that.pianoorario.total = $("#totale").val();
                    }

                    console.log("PIANO_ORARIO: ", that.pianoorario);

                    //that.pianoorario.id_tipologia = $("#tipologia").val();

                    that.saveData();

                } else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }

            }

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        initInsertPianoOrario: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianoorario.initInsert(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initInsert", res);

                this.aziende = res.data.company;

                this.ore = res.data.ore;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        syncUtenti: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.getUsersForConf(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUsersForConf", res);


                that.utenti = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },









        syncPianoOrario: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var id_rec = that.$route.params.id;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.getConf(
                id_rec,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConf", res);


                var temp_prodotto = res.data.conf_prod;

                temp_prodotto.productid = res.data.conf_prod.product_name;

                if (res.data.conf_prod.id_group != null) {
                    temp_prodotto.tipo = "G"
                }

                if (res.data.conf_prod.id_user != null) {
                    temp_prodotto.tipo = "U"
                }

                this.conf_prodotto = temp_prodotto;

                if (temp_prodotto.permissions != null) {

                    var elems = temp_prodotto.permissions.split("|");

                    console.log("ELEMES: ", elems);

                    for (var x = 0; x <= elems.length - 1; x++) {



                        if (elems[x] == "manage_users") {

                            this.manageUsers = "S";

                        }

                        if (elems[x] == "view_in_home") {

                            this.viewInHome = "S";

                        }


                    }


                }


                if (temp_prodotto.is_trial == "S") {

                    this.showPeriod = true;
                }


                this.setupButtons();







            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            var id_rec = that.$route.params.id;

            //var id_rec = this.decodeString(this.$route.params.id);


            //console.log("CONF PROD: ", that.conf_prodotto);
            console.log("VIEW IN HOME: ", that.viewInHome);
            console.log("MANAGE USERS: ", that.manageUsers);

            var strPermissions = "";

            if (that.viewInHome == "S") {
                strPermissions = "view_in_home";
            }



            that.conf_prodotto.permissions = strPermissions;

            console.log("CONF PROD: ", that.conf_prodotto);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfigurationproduct.insConf(
                that.conf_prodotto,
                v_token,
                id_rec
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from insConf", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/configurationProducts"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );





        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamento(
                that.$route.params.id, v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamento", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;

                    if (res.data.Result.is_child_pag == "N") // pagamento master
                    {

                        this.pagamento.importo_pagato = null;

                        var today = new Date();

                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);

                        console.log("DATE: ", sDate);

                        this.pagamento.data_pagamento = sDate; // default data corrente

                    }

                    if (res.data.Result.is_child_pag == "S") // pagamento child
                    {

                        this.disField = true;

                    }

                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");

                    }

                    this.valoreDaPagare = res.data.Result.importo;

                    this.valoreRimanente = res.data.Result.importo_rimanente;

                    this.disFieldRim = true;

                    var allegato_salvato = res.data.Result.allegato_salvato;

                    if (allegato_salvato) {

                        var elements = allegato_salvato.split(".");

                        console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        if (elements[1] == "pdf") {

                            txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        } else {

                            txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        }

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 100);

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.align_close_sheet {
    top: 23px !important
}

@media screen and (max-width: 768px) {

    .align_close_sheet {
        top: 18px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetUtenti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetGruppi {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}


.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemUtente {
    margin-left: 15px;
}

.itemGruppo {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}

.img_copy_rim {
    cursor: pointer;
}

.edit_conf_prod .v-label {
    font-size: 1em !important
}


.edit_conf_prod .v-input {
    font-size: 10px !important;
    padding: 10px !important;
}

.edit_conf_prod ion-select {
    font-size: 10px !important;
}

.edit_conf_prod .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px);
}
</style>
