import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIReminderTimesheet {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async saveReminderTimesheet(user, reminder, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  id);

    for ( var key in reminder ) {
      formData.append(key, reminder[key]);
   }
    
   
    return axios.post(this.getUrlDomain("EmtSaveConfRemTsApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getReminder(id, user, prodotto)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("v_id",  id);
    formData.append("prodotto",  prodotto);

   
    return axios.post(this.getUrlDomain("EmtGetConfRemTsApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteReminder(id)
  {

    let formData = new FormData();

    formData.append("v_id",  id);

    return axios.post(this.getUrlDomain("EmtDelConfRemTsApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updateUsersAssoc(id, id_row_notify)
  {

    let formData = new FormData();

    formData.append("v_id_users",  id);
    formData.append("id_row_notify",  id_row_notify);

    return axios.post(this.getUrlDomain("EmtAssocUsersConfRemTsApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APIReminderTimesheet({
  url: "https://services.ebadge.it/public/api/"
})
