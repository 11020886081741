<template>
    <div>
      <canvas ref="qrcode"></canvas>
    </div>
  </template>
  
  <script>
  import QRCode from 'qrcode';
  import axios from 'axios'

  export default {
    data() {
      return {
        qrCodeUrl: ''
      };
    },
    async mounted() {
      // Richiede al backend Laravel di abilitare la 2FA e ottenere il codice QR
      const response = await axios.post('http://emtool.local/enable-2fa?v_user=admin2_c');
      this.qrCodeUrl = response.data.qrCodeUrl;
  
      // Genera il codice QR
      QRCode.toCanvas(this.$refs.qrcode, this.qrCodeUrl, function (error) {
        if (error) console.error(error);
      });
    }
  };
  </script>
  