<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">



        <v-col v-if="isSU == 0 && viewTsClienti == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/initVerificaTimesheetCliente">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/timesheet3.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Timesheet</div>
                </template>
                <span>Timesheet</span>
            </v-tooltip>

        </v-col>


        <v-col v-if="viewTsClienti == 0 && isPwdChangedOnce > 0 && viewEditTimesheet == 1" cols="6" class="mb-15">

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" class="btnTs" @click="checkConfTsDip()">

                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/timesheet3.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Timesheet</div>
                </template>
                <span>Timesheet</span>
            </v-tooltip>



        </v-col>





        <v-col v-if="viewTsClienti == 0 && isCustSupp > 0" cols="6" class="mb-15">

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon id="no-background-hover" to="/initVerificaTimesheetFornitori">

                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/timesheet3.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Timesheet</div>
                </template>
                <span>Timesheet</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isPwdChangedOnce > 0 && viewEditDisease == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/initDisease">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/malattia4.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Malattia</div>
                </template>
                <span>Malattia</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isPwdChangedOnce > 0 && viewEditHolidaysPermits == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/initHoliday">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/ferie2.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Ferie</div>
                </template>
                <span>Ferie</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isPwdChangedOnce > 0 && viewVerTs == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/filterVerificaTimesheet">
                        <v-img alt="Emt logo" width="60" style="margin-top:16px" class=" " contain
                            src="@/assets/verifica_ts.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Verifica Timesheet</div>
                </template>
                <span>Verifica Timesheet</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isPwdChangedOnce > 0 && viewRisorse == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/filterRisorse">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/risorse2.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Risorse</div>
                </template>
                <span>Risorse</span>
            </v-tooltip>

        </v-col>



        <v-col v-if="viewTsClienti == 0 && isAgila == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/initPratiche">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/pratiche-emt.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Pratiche</div>
                </template>
                <span>Pratiche</span>
            </v-tooltip>

        </v-col>



        <v-col v-if="viewTsClienti == 0 && isAgila == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover"
                        to="/controlli/MA==l/MA==l/MA==l/MA==l">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/controlli-emt.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Controlli</div>
                </template>
                <span>Controlli</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isAgila == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/referenti">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/referenti_emt.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Referenti</div>
                </template>
                <span>Referenti</span>
            </v-tooltip>

        </v-col>


        <v-col v-if="viewTsClienti == 0 && isAgila == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/initCompanyAgila">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/aziende.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Aziende</div>
                </template>
                <span>Aziende</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isAgila == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/modalitaFatturazione/MA==l">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/mod-fatturazione-emt.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Modalità Fatturazione</div>
                </template>
                <span>Modalità Fatturazione</span>
            </v-tooltip>

        </v-col>

        <v-col v-if="viewTsClienti == 0 && isPwdChangedOnce > 0 && viewGestioneUtenti == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/filterusers">
                        <v-img alt="Emt logo" width="60" style="margin-top:16px" class=" " contain
                            src="@/assets/users_menu3.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Utenti</div>
                </template>
                <span>Utenti</span>
            </v-tooltip>

        </v-col>



        <v-col v-if="viewTsClienti == 0 && isAgila == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/scadenze/MA==l">
                        <v-img alt="Emt logo" width="48" style="margin-top:16px" class=" " contain
                            src="@/assets/scadenze-emt.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Scadenze</div>
                </template>
                <span>Scadenze</span>
            </v-tooltip>

        </v-col>






    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",

    mounted: function () {

        /*eslint-disable no-undef*/


        this.$root.$children[0].checkLogout();

        this.checkChangePwd();

        var v_models = window.$cookies.get("models");
        var v_system = window.$cookies.get("system");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");

        if (v_system != "null") {
            this.hasResAssociated = 1;
        }

        console.log("MODELS: ", v_models);

        var elements = v_models.split(",");

        console.log("MODELS ELE: ", elements);

        for (var x = 0; x <= elements.length - 1; x++) {
            if (elements[x] == "6") {
                this.isCMBAdmin = 1;
            }
        }


        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        this.isCustSupp = window.$cookies.get("is_cust_supp");
        this.isAgila = window.$cookies.get("is_agila");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }








        this.loadLogo();

        this.getLastLogin();



        if (this.isSU == 0) {

            this.viewGestioneUtenti = this.getEnableConf("manage_users");
            this.viewEditTimesheet = this.getEnableConf("edit_timesheet");
            this.viewEditDisease = this.getEnableConf("edit_disease");
            this.viewEditHolidaysPermits = this.getEnableConf("edit_holidays_permits");
            this.viewTsFornitore = this.getEnableConf("timesheet_fornitori");
            this.viewVerTs = this.getEnableConf("verifica_timesheet");
            this.viewClientiFornitori = this.getEnableConf("clienti_fornitori");
            this.viewRisorse = this.getEnableConf("manage_risorse");
            this.viewVerTsEbadge = this.getEnableConf("verifica_ebadge");
            this.viewTsClienti = this.getEnableConf("timesheet_clienti");

            console.log("GESTIONE UTENTI: ", this.viewGestioneUtenti);
            console.log("EDIT TIMESHEET: ", this.viewEditTimesheet);
            console.log("EDIT DISEASE: ", this.viewEditDisease);
            console.log("EDIT HOLIDAYS PERMITS: ", this.viewEditHolidaysPermits);

        }
        else {

            this.viewGestioneUtenti = 1;
            this.viewEditTimesheet = 1;
            this.viewEditDisease = 1;
            this.viewEditHolidaysPermits = 1;
            //this.viewTsFornitore = 1;
            this.viewVerTs = 1;
            this.viewClientiFornitori = 1;
            this.viewRisorse = 1;
            this.viewVerTsEbadge = 1;
            // this.viewTsClienti = 1;


        }



    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },

    methods: {



        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "emt"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        checkChangePwd: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apiusers.checkChangePwd(
                atob(v_token),
                window.$cookies.get("spec_psw")
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkChangePwd", res);

                if (res.data.IsChanged == 0) {

                    that.$root.$children[0].logout();


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        checkConfTsDip: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");
            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkConfTsDip(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkConfTsDip", res);


                if (res.data.Result == "OK") {


                    if (res.data.n_proj > 1) {


                        this.$router.push('/timesheetGridUnProj');


                        // if ((res.data.IsEnableRep == "N") && (res.data.IsEnableInterv == "N")) {

                        //     this.$router.push('/timesheetGrid');

                        // }
                        // else {

                        //     if ((res.data.IsEnableRep == "S") && (res.data.IsEnableInterv == "N")) {

                        //         this.$router.push('/timesheetGridWithRep');

                        //     }

                        //     if ((res.data.IsEnableRep == "S") && (res.data.IsEnableInterv == "S")) {

                        //         this.$router.push('/timesheetGridWithRepInterv');

                        //     }

                        // }
                    }

                    if (res.data.n_proj <= 1) {


                        if ((res.data.IsEnableRep == "N") && (res.data.IsEnableInterv == "N")) {

                            if ((a == "N") && (b == "N")) {

                                this.$router.push('/timesheetGridUnProj');

                            }
                            else {

                                this.$router.push('/timesheetGridUnProjSU');

                            }

                        }
                        else {

                            if ((res.data.IsEnableRep == "S") && (res.data.IsEnableInterv == "N")) {

                                this.$router.push('/timesheetGridUnProjWithRep');

                            }

                            if ((res.data.IsEnableRep == "S") && (res.data.IsEnableInterv == "S")) {

                                this.$router.push('/timesheetGridUnProjWithRepInterv');

                            }

                        }


                    }





                }
                else {

                    that.$swal({
                        icon: "warning",
                        text: "Piano orario non configurato chiedere all'amministrazione",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        checkConfTsDipOld: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkConfTsDip(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkConfTsDip", res);


                if (res.data.Result == "OK") {

                    if (res.data.n_proj > 1) {

                        if (res.data.IsEnableRep == "N") {

                            this.$router.push("/timesheetGrid");

                        }
                        else {

                            this.$router.push("/timesheetGridWithRep");


                        }

                    }


                    if (res.data.n_proj <= 1) {

                        if (res.data.IsEnableRep == "N") {

                            this.$router.push("/timesheetGridUnProj");

                        }
                        else {

                            this.$router.push("/timesheetGridUnProjWithRep");


                        }


                    }





                }
                else {

                    that.$swal({
                        icon: "warning",
                        text: "Piano orario non configurato chiedere all'amministrazione",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },

        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "emtool")
                    .then((res) => {

                        console.log("res LoadLogoCompanyNew: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;




                    });
            } catch (error) {
                console.log("err LoadLogoCompanyNew", error);
            }

            console.log(response);



        },



    },


    data: () => ({

        isPwdChangedOnce: 0,

        viewGestioneUtenti: 0,
        viewEditTimesheet: 0,
        viewEditDisease: 0,
        viewEditHolidaysPermits: 0,
        viewTsFornitore: 0,
        viewVerTs: 0,
        viewClientiFornitori: 0,
        viewRisorse: 0,
        viewVerTsEbadge: 0,
        viewTsClienti: 0,

        isCMBAdmin: 0,
        hasResAssociated: 0,

        isAgila: 0,
        isAdmin: 0,
        isSU: 0,
        isCustSupp: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
