<template>
    <v-container id="conf_holidays_permits_monthly" class="w-container-conf-hol" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                            background-color: white!important;
                            color: black;
                            font-weight: bold;
                            padding: 4px;
                            font-size: 10px;                
                            min-width:100vw;
                            border-color:#f5cf2e">{{ titolo }}


                </div>




            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">


                    <v-text-field v-model="impostazioni.fld_name" label="Azienda" readonly>
                    </v-text-field>


                </v-col>

            </v-row>



            <v-row>

                <v-col cols="12" md="12">


                    <div id="d_mese_ore_ferie"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Ore Ferie</label>

                                    <vue-autonumeric v-model="impostazioni.mese_ore_ferie"
                                        :options="numericOption"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>



                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">


                    <div id="d_mese_ore_permessi_2_anno"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Ore Permessi Fino Al 2o Anno
                                        di Anzianità</label>

                                    <vue-autonumeric v-model="impostazioni.mese_ore_perm_fino_2_anno"
                                        :options="numericOption"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">


                    <div id="d_mese_ore_permessi_2_4_anno"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Ore Permessi 2o - 4o Anno di
                                        Anzianità</label>

                                    <vue-autonumeric v-model="impostazioni.mese_ore_perm_tra_2_e_4_anno"
                                        :options="numericOption"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>



                </v-col>

            </v-row>



            <v-row>

                <v-col cols="12" md="12">


                    <div id="d_mese_ore_permessi_oltre_4_anno"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute">Ore Permessi Dal 4a Anno di
                                        Anzianità</label>

                                    <vue-autonumeric v-model="impostazioni.mese_ore_perm_sup_4_anno"
                                        :options="numericOption"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>



                </v-col>

            </v-row>


        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/aziende-emt.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:150px;height:150px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

    </v-container>
</template>

<script>

import apisettings from "../utils/settings/apisettings";
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        this.$root.$children[0].checkLogout();

        console.log("PARAMS: ", this.$route.params.id);

        window.idAziendaSel = window.$cookies.get("sel_filter_azienda_conf_hol_perm");

        /*eslint-disable no-undef*/

        this.setupButtons();

        setTimeout(() => {



            this.syncConfOreHolidaysPermits();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save_conf_hol_perm":

                    this.validate();
                    break;

                case "btn_back":

                    router.push({
                        path: "/filterConfHolidaysPermitsMonthly"
                    });

                    break;

                default:
                    break;
            }
        });


        $(document).ready(function () {


            $('body').on('click', '.selaziendacondholperm', function () {

                console.log(("EVENTO CLICK SELAZIENDA"));

                this.sheetAziende = true;

            });




        });




    },

    components: {
        VueAutonumeric,
    },




    data: () => ({



        aziende: [],


        numericOption: {
            digitGroupSeparator: '.',
            decimalCharacter: ',',
            decimalCharacterAlternative: '.',
            currencySymbol: '',
            currencySymbolPlacement: 's',
            roundingMethod: 'U',
            minimumValue: '0',
            decimalPlaces: 3,
            modifyValueOnWheel: false,
        },


        fieldDis: false,

        showRow: true,
        showRow2: true,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Ferie / Perm .Mat.",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: {
            mese_ore_ferie: "",
            mese_ore_perm_fino_2_anno: "",
            mese_ore_perm_tra_2_e_4_anno: "",
            mese_ore_perm_sup_4_anno: "",
            fld_name: "",
            id_azienda: 0,
        },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {


        manageClickAziende: async function (azienda) {

            var that = this;

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.idAziendaSel = azienda.companyid;

            setTimeout(() => {


                that.syncConfOreHolidaysPermits();

            }, 100);


        },

        syncAziende: function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            setTimeout(() => {

                that.$root.$children[0].showProgress = false;

                that.aziende = JSON.parse(window.$cookies.get("companyUser"));

            }, 300);


        },


        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save_conf_hol_perm",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
                    title: "Salva",
                    width: 30

                },

                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-emt.png",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                },


                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;

            console.log("SETTINGS: ", that.impostazioni);

            if (that.impostazioni.mese_ore_ferie == "") {
                errore = "Specificare un valore per ore ferie";
            }

            if (errore == "") {

                if (that.impostazioni.mese_ore_ferie == "null") {
                    errore = "Specificare un valore per ore ferie";
                }

            }


            if (errore == "") {

                if (that.impostazioni.mese_ore_perm_fino_2_anno == "") {
                    errore = "Ore Permessi Fino Al 2o Anno di Anzianità";
                }

            }

            if (errore == "") {

                if (that.impostazioni.mese_ore_perm_fino_2_anno == "null") {
                    errore = "Ore Permessi Fino Al 2o Anno di Anzianità";
                }

            }


            if (errore == "") {

                if (that.impostazioni.mese_ore_perm_tra_2_e_4_anno == "") {
                    errore = "Ore Permessi 2o - 4o Anno di Anzianità";
                }

            }


            if (errore == "") {

                if (that.impostazioni.mese_ore_perm_tra_2_e_4_anno == "null") {
                    errore = "Ore Permessi 2o - 4o Anno di Anzianità";
                }

            }

            if (errore == "") {

                if (that.impostazioni.mese_ore_perm_sup_4_anno == "") {
                    errore = "  Ore Permessi Dal 4a Anno di Anzianità";
                }

            }

            if (errore == "") {

                if (that.impostazioni.mese_ore_perm_sup_4_anno == "null") {
                    errore = "  Ore Permessi Dal 4a Anno di Anzianità";
                }

            }


            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }






        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.impostazioni.id_azienda = window.idAziendaSel;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisettings.saveSettingsHolidaysPermits(
                that.impostazioni,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveSettingsHolidaysPermits", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncConfOreHolidaysPermits: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisettings.getSettingsHolidaysPermits(
                window.idAziendaSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSettingsHolidaysPermits", res);

                try {

                    that.impostazioni = res.data.ValConf;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.selaziendacondholperm {
    max-width: 23px;
    min-height: 23px;
    margin-left: 6px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



#conf_holidays_permits_monthly .v-label {
    font-size: 1.2em !important;
}

.w-container-conf-hol {
    width: 500px;
    padding: 0px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-conf-hol {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
