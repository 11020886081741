<template>
    <div class="mainWrap">


    </div>
</template>

<style lang="css" scoped>
@import "./../assets/loginstart/style.css";
</style>

<script>
//import fslider from "../../../vuecommon/background.vue"



//import $ from 'jquery';

import router from ".././router";

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {

    updated() { },

    mounted: function () {

        this.$root.$children[0].checkLogout();


        router.push({
            path: "/editVerificaTimesheet"
        });

    },

    name: "Login",
    data() {
        return {
            dialogRegister: true,

            showEmt: true,
            showNext3: false,

            notifications: false,
            sound: true,
            widgets: false,

            showSpinner: false,
            input: {
                username: "",
                password: "",
            },
        };
    },
    methods: {

        showPassword() {

            var currPass = this.$refs.currPass;
            if (currPass.type === "password") {
                currPass.type = "text"
                currPass.btnText = "Hide Password"
            } else {
                currPass.type = "password"
                currPass.btnText = "Show Password"
            }
        },



        getCookie() {
            this.$cookies.set("cookie-consent-performance", "yes");

            //  this.$swal('You are logged in!!!');

            //   console.log(Vue.cookie.get('token'));

            // it gets the cookie called `username`
            /* const username = this.$cookies.get("username");
            console.log(username); */
        },


        login() {
            this.showSpinner = true;
            this.getCookie();
            /*     if (this.input.username != "" && this.input.password != "") {
              if (
                this.input.username == this.$parent.mockAccount.username &&
                this.input.password == this.$parent.mockAccount.password
              ) {
                this.$emit("authenticated", true);
                this.$router.replace({ name: "secure" });
              } else {
                console.log("The username and / or password is incorrect");
              }
            } else {
              console.log("A username and password must be present");
            } */
        },
    },
};
</script>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
    display: none;
}

#login {
    width: auto;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: auto;
    /*   margin-top: 200px; */
    padding: 20px;
}

#nav,
.v-sheet {
    display: none;
}

img.showpwd {
    position: absolute;
    /* right: 0px; */
    top: 14px;
    right: 0px;
    max-width: 22px;
    cursor: pointer;

}
</style>
