<template>
  <div id="_frontComp"  >
    <v-carousel
      class="mainCarousel"
      height="100vh"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item class="itemCar" v-for="(slide, i) in items" :key="i">
        <!--   <div :id="'image' + i">
             
          </div>  -->
        <div
          class="divImageOk"
          :id="'image' + i"
          :style="{ 'background-image': 'url(' + slide.src + ')' }"
        ></div>
      </v-carousel-item>
    </v-carousel>

    <v-bottom-navigation
      class="bottomReturn"
      background-color="white"
      dark
      shift
    >
      <router-link id="loginButton" class="linkBottom" tag="a" :to="'/'">
        LOGIN</router-link
      >
    </v-bottom-navigation>
  </div>
</template>

<script>
//import apidoor from "../utils/door/apidoor";
//import background from "./background.vue";
import apidoor from "./door/apidoor";
export default {
  props: ["table", "app"],
  mounted: function () {
    var that = this;
    console.log("Vue obj", this.$router);

/*     this.$router.addRoute({
      path: "/background",
      name: "background",
      component: background,
    }); */

    //alert (  this.table);
    /*eslint-disable no-undef*/
    $("body").off();

   
    that.initSlide();
    setTimeout(function () {}, 10);
      that.initComp();
  },
  methods: {
    initComp: function () {
  //    document.addEventListener("DOMContentLoaded", function (event) {
        document.getElementById("_frontComp").style.display = "none";

       // console.log(event);
        var forgot = document.getElementById("forgotPass");
        var newcontent = document.createElement("a");
        newcontent.id = "discover";

        newcontent.innerHTML = "Discover more";
        newcontent.addEventListener("click", function () {
          document.getElementById("_frontComp").style.display = "block";
          document.getElementById("logins").style.display = "none";
        });

        document
          .getElementById("loginButton")
          .addEventListener("click", function () {
            
            try {
              document.getElementById("_frontComp").style.display = "none";
              document.getElementById("logins").style.display = "block";
              
            } catch (error) {
              console.log(error);
            }




          });

        forgot.parentNode.insertBefore(newcontent, forgot.nextSibling);
        //forgot.appendChild(newcontent);
    //  });
    },
    getUrlDomainSimple: function (nome_metodo) {
      let result = "";
      /*eslint-disable no-undef*/
      if ($(location).attr("hostname") == "localhost") {
        result = $(location).attr("protocol") + "//emtool.local/";
      }

      if ($(location).attr("hostname") != "localhost") {
        result =
          $(location).attr("protocol") +
          "//" +
          $(location).attr("hostname") +
          "/";
      }

      return result + "" + nome_metodo;
    },

    initSlide: async function () {
      //Start

      var appdir = this.app;
      var apptable = this.table;
      var prePath = this.getUrlDomainSimple("public/" + appdir + "/slides/");
      var username = window.$cookies.get("username");
      var v_token = window.$cookies.get("token");
      var that = this;
      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apidoor
        .getSlides(username, v_token, apptable)
        .then((res) => {
          that.$root.$children[0].showProgress = false;
          console.log("res from initslides get", res.data);
          var arr = res.data.data;

          var newItems = [];

          try {
            arr.forEach(function (path) {
              console.log("ciao", path);

              newItems.push({ src: prePath + path });
            });

            that.items = newItems;
          } catch (error) {
            console.log(error);
          }


        //  that.initComp();


        })
        .catch((err) => {
          that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000,
          });
          console.log("ERrori", "Non è stato possibile effettuare la chiamata");
          console.log("response", response);
        });
      //ENd
    },
  },
  data() {
    return {
      items: [],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
};
</script>

<style>
.bottomReturn {
  transform: none;
  background: white;
  display: flex;

  height: 52px;
  position: fixed;
  align-items: center;
  z-index: 4;
}
.mainCarousel .v-carousel__controls {
  bottom: 44px;
}

.mainCarousel .theme--dark.v-btn.v-btn--icon {
  color: #000000;
}

.linkBottom {
  font-weight: 600;
  text-decoration: none;

  bottom: 16px;
  text-align: right;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5) !important;
  align-items: center;
}

.fullScreen {
  min-height: 100vh;
}

div#image0,
div#image1,
div#image2,
div#image3 {

  min-width: 100vw;

  max-width: 100px;

  background-size: contain !important;
  background-repeat: no-repeat;

  max-width: 100px;
  background-position: center;
}
div#image0 {
  background-image: url(/easyonoff/img/mobile_easy.jpg);
}
div#image1 {
  background-image: url(/easyonoff/img/mobile_easy_2.jpg);
}

#discover {
  color: grey;
  display: flex;
  justify-content: center;

  font-size: 12px;
  transform: translateY(-10px);
  display:block;
}

#_frontComp .mainCarousel {
  z-index: 3;
}


  .divImageOk{
    min-height: 100vh;
  }
@media screen and ( min-width:581px ) {
  .divImageOk{
    min-height: 94vh;
  }
  
} 


</style>